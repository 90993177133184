import {NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {AppComponent} from '@/app.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {NgxTranslateRoutesModule} from 'ngx-translate-routes';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FlexLayoutModule} from '@angular/flex-layout';
import {AppStoreModule} from '@/store/store.module';
import {CoreModule} from '@core/core.module';
import {AppRouterModule} from '@/router/router.module';
import {RouteGuard} from '@core/services/guard/route.guard';
import {MessagesModule} from '@/component/messages/messages.module';
import {environment} from '@environment/environment';
import {NgxGoogleAnalyticsModule} from 'ngx-google-analytics';
import {UserNameOrMailModule} from '@component/user-name-or-mail/user-name-or-mail.module';
import {CodeLanguageModule} from '@component/code-language/code-language.module';
import {CypressModule} from '@component/cypress/cypress.module';
import {ServerHttpInterceptor} from '@core/interceptor/server.http-interceptor';
import {MatSnackBar} from '@angular/material/snack-bar';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

export class AnalyticsResolver {
    static get code(): string {
        return this.#privateFunc();
    }

    static #privateFunc() {
        const url = window.location.hostname;

        for (let i = 0; i < environment.analytics.length; i++) {
            if (url.includes(environment.analytics[i].domain)) {
                return environment.analytics[i].code;
            }
        }

        console.warn(`No analytics code found for Domain (${url})`);

        return '';
    }
}

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        CoreModule,
        AppRouterModule,
        HttpClientModule,
        NgxGoogleAnalyticsModule.forRoot(AnalyticsResolver.code),
        TranslateModule.forRoot({
            defaultLanguage: 'nl_NL',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [
                    HttpClient,
                ],
            },
        }),
        MessagesModule,
        UserNameOrMailModule,
        NgxTranslateRoutesModule.forRoot(),
        BrowserAnimationsModule,
        FlexLayoutModule,
        AppStoreModule,
        CodeLanguageModule,
        CypressModule,
    ],
    exports: [
        TranslateModule,
        CypressModule,
    ],
    bootstrap: [
        AppComponent,
    ],
    providers: [
        RouteGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ServerHttpInterceptor,
            multi: true,
            deps: [MatSnackBar],
        },
    ],
})
export class AppModule {
}
