import {Injectable} from '@angular/core';
import {ApiService} from '@core/services/api/api.service';
import {Observable} from 'rxjs';
import {ApiV2EndpointsEnum} from '@core/enums/api-v2-endpoints.enum';
import {SelfExamineCreatePropertiesInterface} from '@store/self-examine/self-examine.properties';
import {SelfExamineInterface} from '@shared/interfaces/self-examine.interface';
import {sprintf} from '@core/core.functions';

@Injectable({
    providedIn: 'root',
})
export class SelfExamineService {
    public constructor(private api: ApiService) {
    }

    public create(selfExamineCreate: SelfExamineCreatePropertiesInterface): Observable<SelfExamineInterface> {
        const route: string = sprintf(ApiV2EndpointsEnum.SelfExamineCreate, selfExamineCreate.bookUuid, selfExamineCreate.groupId);

        return this.api.post<SelfExamineInterface>(route, {users: selfExamineCreate.users});
    }
}
