import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AuthenticationHttpInterceptor} from '@core/security/interceptors/authentication.http-interceptor';
import {IsTeacherDirective} from '@core/security/directive/is-teacher.directive';
import {IsStudentDirective} from '@core/security/directive/is-student.directive';
import {IsGrantedPipe} from '@core/security/pipe/is-granted.pipe';
import {IsGrantedDirective} from '@core/security/directive/is-granted.directive';
import {MatSnackBar} from '@angular/material/snack-bar';

@NgModule({
    declarations: [
        IsTeacherDirective,
        IsStudentDirective,
        IsGrantedDirective,
        IsGrantedPipe,
    ],
    imports: [
        CommonModule,
        HttpClientModule,
    ],
    exports: [
        IsTeacherDirective,
        IsStudentDirective,
        IsGrantedDirective,
        IsGrantedPipe,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useFactory: () => {
                return new AuthenticationHttpInterceptor([
                    '/api/v2/casper',
                ]);
            },
            multi: true,
            deps: [MatSnackBar],
        },
    ],
})
export class SecurityModule {
}
