import {Pipe, PipeTransform} from '@angular/core';
import {AppTranslateService} from '@core/services/translate/translate.service';

@Pipe({
    name: 'translateForOrganization',
    pure: false,
})
export class TranslateForOrganizationPipe implements PipeTransform {
    #translateService: AppTranslateService;

    public constructor(translateService: AppTranslateService) {
        this.#translateService = translateService;
    }

    public transform(value: string, interpolateParams?: Object): string {
        return this.#translateService.instantForPublisher(value, interpolateParams);
    }
}
