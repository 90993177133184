import {createAction, props} from '@ngrx/store';
import {SearchActionsEnum} from '@store/search/search.enum';
import {SearchResultsInterface} from '@core/search/component/search/search.component';
import {SearchResultsPropertiesInterface} from '@store/search/search.properties';

export class SearchActions {
    public static readonly clearAll = createAction(SearchActionsEnum.ClearAll);

    public static readonly fetchResultsSuccess = createAction(SearchActionsEnum.FetchResultsSuccess, props<SearchResultsInterface>());

    public static readonly setSearchResults = createAction(SearchActionsEnum.SetSearchResults, props<SearchResultsPropertiesInterface>());
}
