import {NgModule} from '@angular/core';
import {SearchBarComponent} from '@core/search/component/bar/search-bar.component';
import {SearchComponent} from '@core/search/component/search/search.component';
import {ExtendedModule, FlexModule} from '@angular/flex-layout';
import {CommonModule} from '@angular/common';
import {NgxPaginationModule} from 'ngx-pagination';
import {ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '@shared/shared.module';
import {SearchResultComponent} from '@core/search/component/result/search-result.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {RouterModule} from '@angular/router';
import {PaginationModule} from '@component/pagination/pagination.module';
import {ViewContainerRefModule} from '@component/view-container-ref/view-container-ref.module';

@NgModule({
    imports: [
        FlexModule,
        CommonModule,
        NgxPaginationModule,
        ReactiveFormsModule,
        ExtendedModule,
        SharedModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        SharedModule,
        RouterModule,
        PaginationModule,
        ViewContainerRefModule,
    ],
    declarations: [
        SearchResultComponent,
        SearchBarComponent,
        SearchComponent,
    ],
    exports: [
        SearchBarComponent,
        SearchComponent,
    ],
})
export class SearchModule {
}
