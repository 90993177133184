import {Action, createReducer, on} from '@ngrx/store';
import {ChapterBookDetailsInterface} from '@shared/interfaces/chapter.interface';
import {SelectionActions} from '@store/selection/selection.actions';
import {DocumentInterface} from '@shared/interfaces/document.interface';
import {UserInterface} from '@shared/interfaces/user.interface';
import {GroupInterface, GroupTeacherInterface} from '@shared/interfaces/group.interface';

export interface SelectionState {
    chapter?: ChapterBookDetailsInterface;
    document?: DocumentInterface;
    group?: GroupInterface | GroupTeacherInterface;
    user?: UserInterface;
}

export const initialState: SelectionState = {};

export const reducer = createReducer(
    initialState,
    on(SelectionActions.clearAll, () => (initialState)),
    on(SelectionActions.setChapter, (state, selection) => ({...state, chapter: selection.item})),
    on(SelectionActions.clearChapter, (state) => ({...state, chapter: undefined})),
    on(SelectionActions.setDocument, (state, selection) => ({...state, document: selection.item})),
    on(SelectionActions.clearDocument, (state) => ({...state, document: undefined})),
    on(SelectionActions.setGroup, (state, selection) => ({...state, group: selection.item})),
    on(SelectionActions.clearGroup, (state) => ({...state, group: undefined})),
    on(SelectionActions.setUser, (state, selection) => ({...state, user: selection.item})),
    on(SelectionActions.clearUser, (state) => ({...state, user: undefined})),
);

export const selectionReducer = (state: SelectionState | undefined, action: Action): SelectionState => {
    return reducer(state, action);
};
