import {Action, createReducer, on} from '@ngrx/store';
import {ProgressActions} from '@store/progress/progress.actions';
import {ProgressGroupInterface, ProgressItemNumberedInterface, ProgressUserInterface, ProgressUserProgressionInterface} from '@shared/interfaces/progress.interface';

export interface ProgressState {
    page?: number;
    user?: ProgressUserInterface;
    group?: ProgressGroupInterface;
    assignment?: ProgressItemNumberedInterface;
    progression?: ProgressUserProgressionInterface;
}

export const initialState: ProgressState = {};

export const reducer = createReducer(
    initialState,
    on(ProgressActions.clearAll, () => (initialState)),
    on(ProgressActions.fetchProgressionSuccess, (state, progression) => ({...state, progression})),
    on(ProgressActions.fetchForUserSuccess, (state, user) => ({...state, user})),
    on(ProgressActions.fetchForGroupSuccess, (state, group) => ({...state, group})),
    on(ProgressActions.setAssignment, (state, assignment) => ({...state, assignment})),
    on(ProgressActions.clearAssignment, (state) => ({...state, assignment: undefined})),
    on(ProgressActions.setPage, (state, properties) => ({...state, page: properties.page})),
    on(ProgressActions.saveReviewSuccess, (state, progressReview) => ({...state, group: progressReview.progress})),
    on(ProgressActions.removeAnswersSuccess, (state, progressReview) => ({...state, group: progressReview.progress})),
    on(ProgressActions.saveUpdatedReviewSuccess, (state, progressReview) => ({...state, group: progressReview.progress})),
);

export const progressReducer = (state: ProgressState | undefined, action: Action): ProgressState => {
    return reducer(state, action);
};
