import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {MatSpinnerColorEnum} from '@shared/progress-circle/progress-circle.component';

@Component({
    selector: 'app-progress-text[percentage]',
    templateUrl: './progress-text.component.html',
    styleUrls: ['./progress-text.component.scss'],
})
export class ProgressTextComponent implements OnChanges {
    @Input()
    readonly percentage?: number;

    @Input()
    readonly fontSize: number = 1.2;

    @Input()
    readonly isDarkMode: boolean = false;

    @Input()
    readonly pointer: boolean = false;

    @Input()
    readonly value?: string;

    @Input()
    color!: string;

    ngOnChanges(changes: SimpleChanges) {
        if (changes.percentage) {
            this.#handleColorClass();
        }
    }

    #handleColorClass(): void {
        const percentage: number | undefined = this.percentage;
        const defaultColor: string = MatSpinnerColorEnum.Dark;

        if (undefined === percentage || percentage === 0) {
            this.color = defaultColor;

            return;
        }

        this.color = percentage <= 50 ? MatSpinnerColorEnum.Red : this.percentage === null ? defaultColor : this.color;
    }
}
