import {Store} from '@ngrx/store';
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {GroupsService} from '@core/services/groups/groups.service';
import {Observable, of} from 'rxjs';
import {GroupActionsEnum} from '@store/group/group.enum';
import {GroupPropertiesInterface} from '@store/group/group.properties';
import {GroupInterface} from '@shared/interfaces/group.interface';
import {GroupActions} from '@store/group/group.actions';
import {UserAuthenticatedInterface} from '@shared/interfaces/user.interface';
import {BookDetailInterface} from '@shared/interfaces/book.interface';

@Injectable()
export class GroupEffects {
    public constructor(
        private store: Store,
        private actions$: Actions,
        private groupsService: GroupsService,
    ) {
    }

    public fetchOrganizationGroups$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(GroupActionsEnum.FetchOrganizationGroups),
            mergeMap((props) => this.loadOrganizationGroupsFromApi(props)),
        )
    });

    public fetchGroups$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(GroupActionsEnum.FetchGroups),
            mergeMap((properties: { bookUuid?: string, active?: boolean }) => this.loadGroupsFromApi(properties.bookUuid, properties.active)),
        )
    });

    public createGroupAction$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(GroupActionsEnum.CreateGroups),
            switchMap((properties: { groupName: string, bookUuid?: string, groupProperties: GroupPropertiesInterface }) => {
                return this.groupsService
                    .createGroups(properties.groupName, properties.bookUuid)
                    .pipe(
                        map(group => GroupActions.createGroupSuccess(group)),
                        catchError(error => of(GroupActions.createGroupFailed({error, ...properties.groupProperties}))),
                    );
            }),
        );
    });

    public joinGroupsAction$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(GroupActionsEnum.JoinGroups),
            switchMap((properties: { codes: string[], book?: BookDetailInterface, user: UserAuthenticatedInterface, groupProperties: GroupPropertiesInterface })=> {
                return this.groupsService
                    .joinGroups(properties.codes, properties.book?.uuid)
                    .pipe(
                        map(groups => GroupActions.joinGroupsSuccess({groups: groups, user: properties.user})),
                        catchError(error => of(GroupActions.joinGroupsFailed({error, ...properties.groupProperties}))),
                    );
            }),
        );
    });

    public renewGroupCode$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(GroupActionsEnum.RenewGroupCode),
            switchMap((properties: GroupPropertiesInterface) => {
                return this.groupsService
                    .regenerateGroupCode(properties.group.id)
                    .pipe(
                        map(group => GroupActions.renewGroupCodeSuccess(group)),
                        catchError(error => of(GroupActions.renewGroupCodeFailed({error, ...properties}))),
                    );
            }),
        );
    });

    public updateGroupStatus$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(GroupActionsEnum.UpdateGroupStatus),
            switchMap((properties: { groupId: number, active: boolean, groupProperties: GroupPropertiesInterface}) => {
                return this.groupsService
                    .updateGroupStatus(properties.groupId, properties.active)
                    .pipe(
                        map(group => GroupActions.updateGroupStatusSuccess(group)),
                    );
            }),
        );
    });

    public updateGroupNameAction$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(GroupActionsEnum.UpdateGroupName),
            switchMap((properties: { groupId: number, groupName: string, groupProperties: GroupPropertiesInterface }) => {
                return this.groupsService
                    .updateGroupName(properties.groupId, properties.groupName)
                    .pipe(
                        map(group => GroupActions.updateGroupNameSuccess(group)),
                        catchError(error => of(GroupActions.updateGroupNameFailed({error, ...properties.groupProperties}))),
                    );
            }),
        );
    });

    public removeGroupUserAction$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(GroupActionsEnum.RemoveGroupUser),
            switchMap((properties: { groupId: number, userId: number, groupProperties: GroupPropertiesInterface }) => {
                return this.groupsService.removeGroupUser(properties.groupId, properties.userId).pipe(
                    map(group => GroupActions.removeGroupUserSuccess({groupId:properties.groupId, userId:properties.userId})),
                    catchError(error => of(GroupActions.removeGroupUserFailed({error, ...properties.groupProperties}))),
                );
            }),
        );
    });

    private loadOrganizationGroupsFromApi(props: { bookUuid?: string}): Observable<{ type: GroupActionsEnum, organizationGroup: GroupInterface[] }> {
        return this.groupsService.retrieveOrganizationGroups(props.bookUuid).pipe(
            map(organizationGroup => {
                return GroupActions.fetchOrganizationGroupsSuccess({organizationGroup})
            }),
        );
    }

    private loadGroupsFromApi(bookUuid?: string, active?: boolean): Observable<{ type: GroupActionsEnum, groups: GroupInterface[] }> {
        return this.groupsService.retrieveGroups(bookUuid, active).pipe(
            map(groups => GroupActions.fetchGroupsSuccess({groups})),
        );
    }
}
