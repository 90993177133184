import {inject, Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, mergeMap} from 'rxjs/operators';
import {ExamService as LegacyExamService} from '@shared/modules/content/services/exam/exam.service';
import {ExamActionsEnum} from '@store/exam/exam.enum';
import {ExamActions, ExamPropertiesInterface} from '@store/exam/exam.actions';
import {AppExamService} from '@component/exam/exam.service';

@Injectable()
export class ExamEffects {
    #actions: Actions = inject(Actions);

    #remindoExamService: LegacyExamService = inject(LegacyExamService);

    #examService: AppExamService = inject(AppExamService);

    fetchStartRemindo$ = createEffect(() => this.#actions.pipe(
        ofType(ExamActionsEnum.FetchStartRemindo),
        mergeMap((props: ExamPropertiesInterface) => this.#remindoExamService.startRemindoExam(props.bookUuid, props.chapterUuid, props.examUuid)
            .pipe(map(exam => ExamActions.fetchStartRemindoSuccess({exam}))),
        ),
    ));
}
