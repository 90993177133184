export enum ApiV1EndpointsEnum {

    User = '/api/nl/user/profile.json',

    UserLogin = '/api/nl/user/login.json',

    UserLoginHash = '/api/nl/extern/user/login.json',

    UserEdit = '/api/nl/user/edit.json',

    UserAcceptTerms = '/api/nl/user/update_terms_condition.json',

    UserUpdateOrganization = '/api/nl/user/update_organization.json',

    EducationBooks = '/api/nl/education/books.json',

    EducationMyBooks = '/api/nl/education/mybooks/:schoolYearId.json',

    EducationBooksFavouriteToggle = '/api/nl/education/books/favorite/:book.json',

    Document = '/api/nl/library/get.json',

    ResourceExternal = '/api/nl/library/get.json?dpsid=:dpsid',

    TeacherSourcesPerSchoolYear = '/api/nl/library/teacher-sources/:schoolYearId.json',

    TeacherSources = '/api/nl/library/bookfolders/:bookId.json',

    TeacherSourcesChapter = '/api/nl/library/bookfolders/:bookId/:folderDpsid.json',

    BookToc = '/api/nl/library/book/gettoc/:bookId.json',

    Book = '/api/nl/library/book/:bookId.json',

    Task = '/api/nl/task/get/:taskId.json',

    Tasks = '/api/nl/task/all/:weekId.json',

    TasksProgress = '/api/nl/task/progress.json',

    TaskWeeks = '/api/nl/task/weeks.json',

    TaskAdd = '/api/nl/task/add.json',

    TaskResit = '/api/nl/task/resit/:taskId.json',

    TaskUpdate = '/api/nl/task/edit/:taskId.json',

    TaskDelete = '/api/nl/task/delete/:taskId.json',

    Groups = '/api/nl/group/all.json',

    Group = '/api/nl/group/get/:group.json',

    GroupsFilter = '/api/nl/group/filter.json',

    GroupsOrganisation = '/api/nl/group/organisation/schoolyear/:schoolYear.json',

    GroupsCreate = '/api/nl/group/create.json',

    GroupsAddTeacher = '/api/nl/group/:groupId/:userId.json',

    GroupsRenewCode = '/api/nl/group/regeneratecode/:groupId.json',

    GroupsJoin = '/api/nl/group/join.json',

    GroupsUpdate = '/api/nl/group/update.json',

    GroupsRemoveStudent = '/api/nl/group/unjoin/:userId/:groupId.json',

    SaveAnswer = '/api/nl/document/answer/save.json',

    SaveAttachments = '/api/nl/document/attachments/save.json',

    Assignments = '/api/nl/assignment/book/:bookId/:chapterId.json',

    AnswerClean = '/api/nl/document/answer/clear.json',

    AssignmentHandin = '/api/nl/homework/handin/assignment/:assignmentId.json',

    AssignmentHandinSelfScore = '/api/nl/task/handinselfstudy/:taskId.json',

    AssignmentGetTasks = '/api/nl/assignment/:assignmentId/tasks.json',

    FileAdd = '/api/nl/file/add.json',

    UrlAdd = '/api/nl/url/add.json',

    LicensesActivateMultiple = '/api/nl/activation/activatemultiple.json',

    FeedbackSubmit = '/api/nl/homework/feedback/:homeworkId.json',

    Homework = '/api/nl/homework/get/:homeworkId.json',

    HomeworkHandIn = '/api/nl/homework/update/:homeworkId.json',

    HomeworkHandInOwnTask = '/api/nl/homework/handin/task/:taskId.json',

    HomeworkHandInOverride = '/api/nl/homework/handin/exo/task/:taskId.json',

    ProgressOverview = '/api/nl/progress/overview.json',

    ProgressTeacher = '/api/nl/progress/teacher.json',

    ProgressStudent = '/api/nl/progress/student.json',

    ProgressDownloadGroupPdf = '/api/nl/progress/pdf/group/:groupId/book/:bookId',

    ProgressDownloadGroupExcel = '/api/nl/progress/excel/group/:groupId/book/:bookId',

    ProgressDownloadStudentPdf = '/api/nl/progress/pdf/group/:groupId/student/:studentId/book/:bookId',

    ProgressDownloadStudentExcel = '/api/nl/progress/excel/group/:groupId/student/:studentId/book/:bookId',

    TeachersOrganisation = '/api/nl/organization/teachers.json',

    OrganizationsAll = '/api/nl/organization/all.json',

    InternshipAdd = '/api/nl/internship/add.json',

    InternshipRemove = '/api/nl/internship/delete/:internShipId.json',

    InternshipInviteExternalReviewer = '/api/nl/internshipinvite/add/:taskId.json',

    InternshipComplete = '/api/nl/internshipinvite/complete.json',

    InternshipAttachFile = '/api/nl/usermodulestatus/:moduleStatusId/file/add/:fileId.json',

    InternshipDeleteFile = '/api/nl/usermodulestatus/:moduleStatusId/file/remove/:fileId.json',

    Exam = '/api/nl/exam/get/book/:examId/:planId/:bookId.json',

    ExamContentMetadata = '/api/book/:bookId/exam/content/:examDpsId/metadata',

    ExamContentStart = '/api/book/:bookId/exam/content/:examDpsId/plan/:planId/start',

    ExamContentAssignment = '/api/book/:bookId/exam/content/:examDpsId/plan/:planId/assignment/:examAssignmentDpsId/attempt/:attempt',

    ExamContentResult = '/api/book/:bookId/exam/content/:examDpsId/plan/:planId/finish',

    ExamCreate = '/api/nl/exam/create/plan.json',

    ExamMonths = '/api/nl/exam/months.json',

    ExamMonth = '/api/nl/exam/months/:monthId.json',

    ExamPlan = '/api/nl/exam/plan/:planId.json',

    ExamResult = '/api/nl/exam/result/:resultId.json',

    ExamResultUrl = '/api/nl/exam/resulturl/:resultId.json',

    ExamGradeQuestion = '/api/nl/exam/updateresult/:resultId.json',

    ExamRemove = '/api/nl/exam/remove/plan/:planId.json',

    MessageAll = '/api/nl/message/get/all.json',

    MessageMarkAsRead = '/api/nl/message/markasread/:messageId.json',

    MessageMarkAllAsRead = '/api/nl/message/markasread/all.json',

    MessageNotificationAll = '/api/nl/notification/get/messages.json',

    MessageNotificationMarkAsRead = '/api/nl/notification/markasread/:notificationId.json',

    MessageNotificationMarkAllAsRead = '/api/nl/notification/markasread/all.json',

    SummaryGet = '/api/nl/summary/get/:uuid.json',

    SummaryAdd = '/api/nl/summary/add.json',

    SummaryEdit = '/api/nl/summary/edit/:summaryId.json',

    SummaryRemove = '/api/nl/summary/delete/:summaryId.json',

    NoteAll = '/api/nl/note/get/all.json',

    NoteAdd = '/api/nl/note/add.json',

    NoteEdit = '/api/nl/note/edit/:noteId.json',

    NoteRemove = '/api/nl/note/delete/:noteId.json',

    FeedRssGet = '/api/feed/rss/:studyMaterialMethodId',

    SourceGet = '/api/nl/library/uploadedresource/:sourceId.json',

    SourceAdd = '/api/nl/library/create/uploadedsource.json',

    SourceRemove = '/api/nl/library/delete/uploadedsource/:sourceId.json',

    SourceEdit = '/api/nl/library/update/uploadedsource/:sourceId.json',

    DeeplinkEan = '/api/nl/deeplink/ean/:ean.json',

    DeeplinkDpsid = '/api/nl/deeplink/dpsid/:dpsid.json',

    DeeplinkBookId = '/api/nl/deeplink/book-id/:bookId.json',

    Search = '/api/nl/search.json',

    DrillsterUpdateScore = '/api/nl/drillster/update_score/:bookId/:drillsterId.json',

    HelpPage = '/api/nl/helppage/get/:pageId.json',

    SkipHelpers = '/api/nl/user/skip_helpers.json',

    LicenseRequest = '/api/nl/licenserequest/create.json',
}
