import {Store} from '@ngrx/store';
import {GeneralActions, GeneralSelectors} from '@store/general';
import {filter} from 'rxjs/operators';
import {UserLocaleInterface, UserSettingInterface} from '@shared/interfaces/user.interface';
import {BehaviorSubject, Observable, OperatorFunction, Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {inject, Injectable} from '@angular/core';

export enum LocaleEnum {
    Dutch = 'nl_NL',
    English = 'en_EN',
}

@Injectable({
    providedIn: 'root',
})
export class LanguageService {
    readonly #store: Store;

    readonly #translateService: TranslateService = inject(TranslateService);

    readonly localeBehaviourSubject: BehaviorSubject<LocaleEnum> = new BehaviorSubject<LocaleEnum>(LocaleEnum.Dutch);

    constructor(store: Store) {
        this.#store = store;

        // Subscribe to language setting returned by backend
        store.select(GeneralSelectors.selectCurrentUserLocaleSettings)
            .pipe(filter((setting: UserSettingInterface): boolean => undefined !== setting) as OperatorFunction<UserSettingInterface | undefined, UserSettingInterface<UserLocaleInterface>>)
            .subscribe((setting: UserSettingInterface<UserLocaleInterface>) => this.#handleSelectedLanguage(setting.data));
    }

    get currentLanguage(): Observable<LocaleEnum> {
        return this.localeBehaviourSubject.asObservable();
    }

    subscribe(fn: (locale: LocaleEnum) => void): Subscription {
        return this.localeBehaviourSubject.subscribe((locale: LocaleEnum) => fn(locale));
    }

    select(locale: LocaleEnum): void {
        this.#store.dispatch(GeneralActions.patchUserSettings({setting: {type: 'locale', data: {locale}}}));
        this.#updateLocal(locale);
    }

    #handleSelectedLanguage(userLocale: UserLocaleInterface): void {
        const locale: LocaleEnum = userLocale.locale;

        if (locale === this.#translateService.currentLang) {
            return;
        }

        this.#updateLocal(locale);
    }

    #updateLocal(locale: LocaleEnum): void {
        this.localeBehaviourSubject.next(locale);
        this.#translateService.use(locale);
    }
}
