import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {
    updateAcceptTermsActionFailed,
    updateAcceptTermsActionSuccess,
    updateLoadedCurrentUserAction,
    updateOrganizationActionFailed,
    updateOrganizationActionSuccess,
    upgradeToTeacherActionFailed,
    upgradeToTeacherActionSuccess,
} from '@store/security/security.actions';
import {UserService} from '@core/security/services/user.service';
import {SecurityActions} from '@store/security/security.enum';
import {UserInterface} from '@shared/interfaces/user.interface';
import {OrganizationActionsEnum} from '@store/organization';
import {HasOrganizationInterface} from '@core/security/contract/user.contract';

@Injectable()
export class SecurityEffects {
    #userService: UserService;

    public constructor(private actions$: Actions, userService: UserService) {
        this.#userService = userService;
    }

    processLicenseResultSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(OrganizationActionsEnum.displayDialogByHasTeacherLicenseSuccess),
        switchMap((properties: { result: HasOrganizationInterface }) => this.#userService.upgradeToTeacher(properties.result).pipe(
            map(upgradeResult => upgradeToTeacherActionSuccess({upgradeResult})),
            catchError(error => of(upgradeToTeacherActionFailed({error}))),
        )),
    ));

    fetchCurrentUser$ = createEffect(() => this.actions$.pipe(
        ofType(SecurityActions.fetchCurrentUser),
        mergeMap(() => this.#userService.getCurrentUser().pipe(map(currentUser => updateLoadedCurrentUserAction({currentUser})))),
    ));

    fetchAcceptTerms$ = createEffect(() => this.actions$.pipe(
        ofType(SecurityActions.fetchAcceptTermsAction),
        switchMap((props: { user: UserInterface }) => {
            return this.#userService.updateTerms().pipe(
                map(() => updateAcceptTermsActionSuccess({currentUser: props.user})),
                catchError(error => of(updateAcceptTermsActionFailed({error}))),
            );
        }),
    ));

    updateOrganizationAction$ = createEffect(() => this.actions$.pipe(
        ofType(SecurityActions.updateOrganizationAction),
        switchMap((props: { user: UserInterface, organizationId: string }) => this.#userService.updateOrganization(props.organizationId).pipe(
            map(() => updateOrganizationActionSuccess({currentUser: props.user})),
            catchError(error => of(updateOrganizationActionFailed({error}))),
        )),
    ));
}
