import {NgModule} from '@angular/core';
import {UserFullNamePipe} from '@component/user-name-or-mail/user-name-or-mail.pipe';

@NgModule({
    declarations: [
        UserFullNamePipe,
    ],
    exports: [
        UserFullNamePipe,
    ],
})
export class UserNameOrMailModule {
}
