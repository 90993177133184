export enum AppExamActionsEnum {
    Metadata = '[exam] Metadata',
    MetadataCancel = '[exam] MetadataCancel',
    MetadataSuccess = '[exam] MetadataSuccess',
    MetadataFailed = '[exam] MetadataFailed',

    Start = '[exam] Start',
    StartCancel = '[exam] StartCancel',
    StartSuccess = '[exam] StartSuccess',
    StartFailed = '[exam] StartFailed',

    Step = '[exam] Step',
    StepCancel = '[exam] StepCancel',
    StepSuccess = '[exam] StepSuccess',
    StepFailed = '[exam] StepFailed',

    Finish = '[exam] Finish',
    FinishCancel = '[exam] FinishCancel',
    FinishSuccess = '[exam] FinishSuccess',
    FinishFailed = '[exam] FinishFailed',

    Clear = '[exam] Clear',

    Assign = '[exam] assign',
    AssignSuccess = '[exam] assignSuccess',
    AssignFailed = '[exam] assignFailed',

    FetchAssigned = '[exam] fetchAssigned',
    FetchAssignedSuccess = '[exam] fetchAssignedSuccess',
    FetchAssignedFailed = '[exam] fetchAssignedFailed',

    ClearBookExams = '[exam] clearExams',
    FetchBookExams = '[exam] fetchExams',
    FetchBookExamsSuccess = '[exam] fetchExamsSuccess',
    FetchBookExamsFailed = '[exam] fetchExamsFailed',
}
