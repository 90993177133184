import {Injectable} from '@angular/core';

@Injectable()
export class ColorHelper {
    public getHexAsRGB(color: string): number[]
    {
        const hexColorValue = (color.charAt(0) === '#') ? color.substring(1, 7) : color;
        const r = parseInt(hexColorValue.length == 3 ? hexColorValue.slice(0, 1).repeat(2) : hexColorValue.slice(0, 2), 16);
        const g = parseInt(hexColorValue.length == 3 ? hexColorValue.slice(1, 2).repeat(2) : hexColorValue.slice(2, 4), 16);
        const b = parseInt(hexColorValue.length == 3 ? hexColorValue.slice(2, 3).repeat(2) : hexColorValue.slice(4, 6), 16);

        return [r, g, b];
    }

    public getHexAsRGBA(color: string, alpha: number): string {
        const [r, g, b] = this.getHexAsRGB(color);

        if (0.5) {
            return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
        }

        return 'rgb(' + r + ', ' + g + ', ' + b + ')';
    }

    public pickColorByColorIntensity(color: string, colorThreshold: number = 0.47, lightColor: string = '#FFFFFF', darkColor: string = '#002439') {
        const [mappedR, mappedG, mappedB] = this.getHexAsRGB(color).map(rgbColor => {
            const rgbColorValue: number = rgbColor / 255;

            if (rgbColorValue <= 0.03928) {
                return rgbColorValue / 12.92;
            }

            return Math.pow((rgbColorValue + 0.055) / 1.055, 2.4);
        });

        const colorValue = (0.2126 * mappedR) + (0.7152 * mappedG) + (0.0722 * mappedB);

        return colorValue > colorThreshold ? darkColor : lightColor;
    }

    public getAdjustedColor(hexColor: string, amount: number): string {
        const colorSum: number = this.getHexAsRGB(hexColor).reduce((partialSum, colorValue) => partialSum + colorValue, 0);
        let amountValue: number = amount;

        // We can't darken black and we can't lighten white, so we'll swap the amount
        if (amount < 0 && 0 === colorSum || amount > 0 && (255 * 3) === colorSum) {
            amountValue = amount * -1;
        }

        return '#' + hexColor.replace(/^#/, '').replace(/../g, hexColor => ('0' + Math.min(255, Math.max(0, parseInt(hexColor, 16) + amountValue)).toString(16)).substr(-2));
    }
}
