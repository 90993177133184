import {Subscription} from 'rxjs';
import {Input, OnDestroy, OnInit} from '@angular/core';
import {MemoizedSelector, Store} from '@ngrx/store';
import {SelectionPropertiesInterface} from '@store/selection/selection.properties';
import {TypedAction} from '@ngrx/store/src/models';

/**
 * @deprecated This should be replaced with DataSource support for the AbstractSelect or any Select component
 */
export interface SelectItemsFilterInterface<ITEM_TYPE = any> {
    getFilteredItems(items: ITEM_TYPE[]): ITEM_TYPE[];
}

export abstract class AbstractSelect<ITEM_TYPE> implements OnInit, OnDestroy {
    protected subscriptions: Subscription[] = [];

    public items?: ITEM_TYPE[] = [];

    public selectedItem?: ITEM_TYPE;

    @Input()
    public itemsFilter?: SelectItemsFilterInterface<ITEM_TYPE>;

    protected abstract getItemsSelector(): MemoizedSelector<object, ITEM_TYPE[] | undefined>;

    protected abstract getItemSelector(): MemoizedSelector<object, ITEM_TYPE | undefined>;

    protected abstract filterItems(items: ITEM_TYPE[]): ITEM_TYPE[];

    protected constructor(private store: Store) {
    }

    public ngOnInit(): void {
        this.subscriptions.push(
            this.store.select(this.getItemSelector()).subscribe(item => this.selectedItem = item),
            this.store.select(this.getItemsSelector()).subscribe(items => this.handleSelectItems(items)),
        );
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    public dispatch(action: SelectionPropertiesInterface<ITEM_TYPE> & TypedAction<string>): void {
        this.store.dispatch(action);
    }

    private handleSelectItems(items?: ITEM_TYPE[]): void {
        const itemsFilter: SelectItemsFilterInterface<ITEM_TYPE> | undefined = this.itemsFilter;

        let filteredItems = items ? this.filterItems(items) : [];

        this.items = itemsFilter ? itemsFilter.getFilteredItems(filteredItems) : filteredItems;
    }
}
