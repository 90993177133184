import {ActionReducerMap} from '@ngrx/store';
import {StoreFeatureSelectorEnum} from '@store/store.enum';
import {libraryReducer, LibraryState} from '@store/library';
import {securityReducer, SecurityState} from '@store/security';
import {breadcrumbReducer, BreadcrumbState} from '@store/breadcrumb';
import {licenseReducer, LicenseState} from '@store/license';
import {assignmentReducer, AssignmentState} from '@store/assignment/assignment.reducer';
import {groupReducer, GroupState} from '@store/group/group.reducer';
import {progressReducer, ProgressState} from '@store/progress/progress.reducer';
import {selectionReducer, SelectionState} from '@store/selection';
import {searchReducer, SearchState} from '@store/search/search.reducer';
import {generalReducer, GeneralState} from '@store/general';
import {ExamReducer, ExamState} from '@store/exam';
import {SelfExamineReducer, SelfExamineState} from '@store/self-examine/self-examine.reducer';
import {OrganizationReducer, OrganizationState} from '@store/organization/organization.reducer';
import {MessageReducer, MessageState} from '@store/message/message.reducer';
import {TermReducer, TermState} from '@store/term/term.reducer';
import {AppExamReducer, AppExamState} from '@component/exam/redux/exam.reducer';

export interface AppState {
    [StoreFeatureSelectorEnum.Assignment]: AssignmentState,
    [StoreFeatureSelectorEnum.Breadcrumb]: BreadcrumbState,
    [StoreFeatureSelectorEnum.Group]: GroupState,
    [StoreFeatureSelectorEnum.Library]: LibraryState,
    [StoreFeatureSelectorEnum.License]: LicenseState,
    [StoreFeatureSelectorEnum.Security]: SecurityState,
    [StoreFeatureSelectorEnum.Progress]: ProgressState,
    [StoreFeatureSelectorEnum.Selection]: SelectionState,
    [StoreFeatureSelectorEnum.Search]: SearchState,
    [StoreFeatureSelectorEnum.General]: GeneralState,
    [StoreFeatureSelectorEnum.Exam]: ExamState,
    [StoreFeatureSelectorEnum.ContentExam]: AppExamState,
    [StoreFeatureSelectorEnum.SelfExamine]: SelfExamineState,
    [StoreFeatureSelectorEnum.Organization]: OrganizationState,
    [StoreFeatureSelectorEnum.Message]: MessageState,
    [StoreFeatureSelectorEnum.Term]: TermState,
}

export const rootReducers: ActionReducerMap<AppState> = {
    [StoreFeatureSelectorEnum.Assignment]: assignmentReducer,
    [StoreFeatureSelectorEnum.Breadcrumb]: breadcrumbReducer,
    [StoreFeatureSelectorEnum.Group]: groupReducer,
    [StoreFeatureSelectorEnum.Library]: libraryReducer,
    [StoreFeatureSelectorEnum.License]: licenseReducer,
    [StoreFeatureSelectorEnum.Security]: securityReducer,
    [StoreFeatureSelectorEnum.Progress]: progressReducer,
    [StoreFeatureSelectorEnum.Selection]: selectionReducer,
    [StoreFeatureSelectorEnum.Search]: searchReducer,
    [StoreFeatureSelectorEnum.General]: generalReducer,
    [StoreFeatureSelectorEnum.Exam]: ExamReducer,
    [StoreFeatureSelectorEnum.ContentExam]: AppExamReducer,
    [StoreFeatureSelectorEnum.SelfExamine]: SelfExamineReducer,
    [StoreFeatureSelectorEnum.Organization]: OrganizationReducer,
    [StoreFeatureSelectorEnum.Message]: MessageReducer,
    [StoreFeatureSelectorEnum.Term]: TermReducer,
}
