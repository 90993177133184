import {createAction, props} from '@ngrx/store';
import {OrganizationActionsEnum} from '@store/organization/organization.enum';
import {HttpErrorResponseInterface} from '@core/interfaces/http-error-response.interface';
import {OrganizationInterface} from '@shared/interfaces/organization.interface';
import {HasOrganizationInterface} from '@core/security/contract/user.contract';

export class OrganizationActions {
    static readonly fetchOrganizations = createAction(OrganizationActionsEnum.fetchOrganizations);

    static readonly fetchOrganizationsSuccess = createAction(OrganizationActionsEnum.fetchOrganizationsSuccess, props<{ organizations: OrganizationInterface[] }>());

    static readonly fetchOrganizationsFailed = createAction(OrganizationActionsEnum.fetchOrganizationsFailed, props<{ error: HttpErrorResponseInterface }>());

    static readonly displayDialogByHasTeacherLicenseSuccess = createAction(OrganizationActionsEnum.displayDialogByHasTeacherLicenseSuccess, props<{ result: HasOrganizationInterface }>());

    static readonly displayDialogByHasTeacherLicenseFailed = createAction(OrganizationActionsEnum.displayDialogByHasTeacherLicenseFailed, props<{ error: HttpErrorResponseInterface }>());

    static readonly requestOrganization = createAction(OrganizationActionsEnum.requestOrganization, props<{ name: string }>());

    static readonly requestOrganizationSuccess = createAction(OrganizationActionsEnum.requestOrganizationSuccess, props<{ organization: OrganizationInterface }>());

    static readonly requestOrganizationFailed = createAction(OrganizationActionsEnum.requestOrganizationFailed, props<{ error: HttpErrorResponseInterface }>());
}
