import {Component, ElementRef, HostBinding, HostListener, OnDestroy} from '@angular/core';
import {Animations} from './navigation.animations';
import {NavigationEnd, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {OperatorFunction, Subscription} from 'rxjs';
import {LibraryActions, LibraryContextInterface, LibrarySelectors} from '@store/library';
import {BookDetailInterface} from '@shared/interfaces/book.interface';
import {NavItem, NavItemEnum, NavItemFactory} from '@core/navigation/factory/nav-item.factory';
import {filter} from 'rxjs/operators';
import {OauthService} from '@core/security/services/oauth.service';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {RoleEnum} from '@core/security/enum/role.enum';
import {GeneralActions, GeneralSelectors} from '@store/general';
import {UserInterface, UserSettingInterface} from '@shared/interfaces/user.interface';
import {SecuritySelectors} from '@store/security';
import {MessageSelectors} from '@store/message';
import {GeneralBookAndUserModuleVisitedInterface} from '@store/general/general.contract';
import {ApiExamListInterface} from '@component/exam/exam.service';
import {AppExamSelectors} from '@component/exam/redux/exam.selectors';
import {AuthorizationService} from '@core/security/services/authorization.service';
import * as moment from 'moment';
import {ExamCountsInterface} from '@component/exam/exam.component';

export enum NavigationRoleEnum {
    RoleNavigationItemBullet = 'ROLE_NAVIGATION_ITEM_BULLET',
    RoleNavigationItemChildren = 'ROLE_NAVIGATION_ITEM_CHILDREN',
}

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    animations: [Animations.expandMenu],
})
export class NavigationComponent implements OnDestroy {
    readonly #subscriptions: Subscription[] = [];

    #user?: UserInterface;

    readonly roleEnum = RoleEnum;

    readonly NavItemEnum = NavItemEnum;

    @HostBinding('class.double')
    showTableOfContents: boolean = false;

    navigationTopExpanded: boolean = false;

    navigationSecondExpanded: boolean = false;

    navItems: NavItem[] = [];

    bookMethodColor?: string;

    activatedRouteName?: string;

    bookUuid?: string;

    isMobile: boolean = false;

    unreadMessageCount?: number;

    examCounts?: ExamCountsInterface;

    setting?: UserSettingInterface<string[]>;

    #authorizationService: AuthorizationService;

    isTeacher: boolean = false;

    constructor(
        private router: Router,
        private elementRef: ElementRef,
        private navItemFactory: NavItemFactory,
        private store: Store,
        private oauthService: OauthService,
        breakpointObserver: BreakpointObserver,
        authorizationService: AuthorizationService,
    ) {
        this.#authorizationService = authorizationService;
        this.isTeacher = this.#authorizationService.isGranted(RoleEnum.RoleTeacher);

        this.#subscriptions.push(
            breakpointObserver.observe(Breakpoints.XSmall).subscribe(state => this.isMobile = state.matches),
            this.router.events.pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd)).subscribe(() => this.#handleRouterChange()),
            this.store.select(SecuritySelectors.selectCurrentUser).subscribe(user => this.handleSelectCurrentUser(user)),
            this.store.select(GeneralSelectors.selectNavigationSecondExpanded).subscribe(expanded => this.#handleNavigationExpanded(expanded)),
            this.store.select(LibrarySelectors.selectContext).subscribe(context => this.handleLibrarySelectContext(context)),
            this.store.select(MessageSelectors.selectUnreadCount).subscribe(unreadCount => this.unreadMessageCount = unreadCount),
            store.select(GeneralSelectors.selectBookAndCurrentUserModuleVisitedSetting)
                .pipe(filter(data => undefined !== data) as OperatorFunction<GeneralBookAndUserModuleVisitedInterface | undefined, GeneralBookAndUserModuleVisitedInterface>)
                .subscribe(data => this.#handleBookAndUserVisitedModulesSetting(data.book, data.setting)),
            this.store.select(SecuritySelectors.selectExamCounts).subscribe((examCounts?: ExamCountsInterface) => this.examCounts = examCounts),
        );

    }

    public ngOnDestroy(): void {
        this.#subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    public logout(): void {
        this.oauthService.logout();
    }

    public navigateToDocument(route: string): void {
        this.router.navigate([route]);
    }

    public toggleTopMenu(): void {
        this.navigationTopExpanded = !this.navigationTopExpanded;
    }

    protected handleLibrarySelectContext(context: LibraryContextInterface): void {
        this.navItems = this.navItemFactory.forContextState(context);
        this.showTableOfContents = undefined !== context.documentDpsId && false !== context.showToc;

        this.updateProfileNavItem(); // Update profile nav item
    }

    protected handleSelectCurrentUser(user?: UserInterface): void {
        this.#user = user;
        this.updateProfileNavItem(); // Update profile nav item
    }

    private updateProfileNavItem(): void {
        const avatar: string | undefined = this.#user?.avatar ?? undefined;
        const navItem: NavItem | undefined = this.navItems.find(item => NavItemEnum.Profile === item.name);

        // It's possible that there's no navigation yet
        if (undefined === navItem) {
            return;
        }

        this.navItems.splice(this.navItems.indexOf(navItem), 1, {...navItem, image: avatar});
    }

    @HostListener('document:click', ['$event'])
    private onClick(event: Event) {
        const eventTarget: HTMLElement = event.target as HTMLElement;
        const hasBookUuidSetting = undefined !== this.bookUuid && undefined !== this.setting && this.setting.data.includes(this.bookUuid);

        /**
         * @see https://xiponlineapplications.atlassian.net/browse/BD-1609
         * Do not close the menu when not clicking on expanded menu
         */
        if (this.elementRef.nativeElement.contains(eventTarget)
            || eventTarget.classList.contains('cdk-overlay-backdrop')
            || eventTarget.classList.contains('icon--list')
            || !hasBookUuidSetting
        ) {
            return;
        }

        this.navigationTopExpanded = false;
        this.store.dispatch(GeneralActions.toggleNavigationSecondExpanded({value: false}));
    }

    #handleNavigationExpanded(expanded: boolean): void {
        this.navigationSecondExpanded = expanded;

        /**
         * @see https://xiponlineapplications.atlassian.net/browse/BD-1609
         * Menu should remain open until the user closed it using the toggle.
         */
        if (undefined !== this.bookUuid && (undefined === this.setting || !this.setting.data.includes(this.bookUuid))) {
            const data = [...this.setting?.data ?? [], this.bookUuid];

            this.store.dispatch(GeneralActions.patchUserSettings({setting: {type: 'module-visited', data: {modules: data}}}))
        }
    }

    #handleBookAndUserVisitedModulesSetting(book?: BookDetailInterface, setting?: UserSettingInterface<string[]>): void {
        const value = this.navigationTopExpanded = undefined === setting || (undefined !== book && !setting.data.includes(book.uuid));

        this.setting = setting;
        this.bookUuid = book?.uuid;
        this.bookMethodColor = book?.color;
        this.store.dispatch(GeneralActions.toggleNavigationSecondExpanded({value}));
    }

    #handleRouterChange(): void {
        const routeParts = this.router.url.split('/');

        this.activatedRouteName = `navigation.${routeParts[1]}`;

        /**
         * Unset book when we change route, this should not be handled here though.
         */
        if (2 == routeParts.length && ['books', 'messages'].includes(routeParts[1])) {
            this.store.dispatch(LibraryActions.unsetBook());
        }
    }
}
