import {Directive, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {AuthorizationService} from '@core/security/services/authorization.service';
import {RoleEnum} from '@core/security/enum/role.enum';

@Directive()
export abstract class AbstractIsGrantedDirective<T = unknown> implements OnInit {
    protected abstract getRoles(): RoleEnum[];

    private elseTemplateRef?: TemplateRef<T>;

    protected constructor(
        protected authorizationService: AuthorizationService,
        protected viewContainer: ViewContainerRef,
        protected templateRef: TemplateRef<T>,
    ) {
    }

    set isGrantedElse(templateRef: TemplateRef<T> | null) {
        if (!this.#isTemplateRef(templateRef)) {
            throw new Error(`appIsGrantedElse expected templateRef be instanceof TemplateRef<T>`);
        }

        this.elseTemplateRef = templateRef;
    }

    ngOnInit(): void {
        const elseTemplateRef: TemplateRef<T> | undefined = this.elseTemplateRef;
        const roles: RoleEnum[] = this.getRoles();

        if (0 === roles.length || this.authorizationService.isEveryGranted(roles)) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else if (undefined !== elseTemplateRef) {
            this.viewContainer.createEmbeddedView(elseTemplateRef);
        } else {
            this.#clearViewContainer();
        }
    }

    #clearViewContainer(): void {
        this.viewContainer.clear();
    }

    #isTemplateRef(templateRef: TemplateRef<T> | null): templateRef is TemplateRef<T> {
        return !!(!templateRef || templateRef.createEmbeddedView);
    }
}
