import {NgModule} from '@angular/core';
import {FooterComponent} from '@core/footer/footer.component';
import {LayoutComponent} from '@core/layout/layout.component';
import {NotFoundComponent} from '@core/not-found/not-found.component';
import {RouterModule} from '@angular/router';
import {ExtendedModule, FlexModule} from '@angular/flex-layout';
import {SecurityModule} from '@core/security/security.module';
import {NavigationModule} from '@core/navigation/navigation.module';
import {SharedModule} from '@shared/shared.module';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from '@core/header/header.component';
import {SearchModule} from '@core/search/search.module';
import {HelpersModule} from '@shared/helpers/helpers.module';
import {AssignmentService} from '@core/services/assignment/assignment.service';
import {TemplateVarModule} from '@component/template-var/template-var.module';
import {CypressModule} from '@component/cypress/cypress.module';
import {ContainerHeaderModule} from '@component/container-header/container-header.module';

@NgModule({
    declarations: [
        FooterComponent,
        LayoutComponent,
        NotFoundComponent,
        HeaderComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        FlexModule,
        SecurityModule,
        NavigationModule,
        SharedModule,
        SearchModule,
        ExtendedModule,
        HelpersModule,
        ContainerHeaderModule,
        TemplateVarModule,
        CypressModule,
    ],
    exports: [
        NotFoundComponent,
        LayoutComponent,
        SecurityModule,
        CommonModule,
        HeaderComponent,
        CypressModule,
    ],
})
export class CoreModule {
    /**
     * An ugly solution, but angular provides no proper way of eager loading services
     */
    public constructor(assignmentService: AssignmentService) {
    }
}
