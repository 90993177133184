import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-progression-bar',
    templateUrl: './progression-bar.component.html',
    styleUrls: ['./progression-bar.component.scss'],
})
export class ProgressionBarComponent {
    @Input()
    public percentage: number = 1;
}
