import {Component, ElementRef, HostBinding, Input} from '@angular/core';
import {ButtonTypeEnum} from '@shared/button/button.directive';
import {MemoizedSelector, Store} from '@ngrx/store';
import {LibrarySelectors} from '@store/library';
import {ChapterBookDetailsInterface} from '@shared/interfaces/chapter.interface';
import {SelectionActions, SelectionSelectors} from '@store/selection';
import {AbstractSelect} from '@shared/abstract/select/abstract.select';

export enum ModuleSingleSelectPlaceHolder {
    All = 'moduleMenu.all',
    SelectOne = 'moduleMenu.selectOne',
}

@Component({
    selector: 'app-chapter-select',
    templateUrl: './chapter-select.component.html',
    styleUrls: ['./chapter-select.component.scss'],
})
export class ChapterSelectComponent extends AbstractSelect<ChapterBookDetailsInterface> {
    public readonly buttonType = ButtonTypeEnum;

    @HostBinding('class')
    public readonly classes: string[] = ['module-menu'];

    @Input()
    public disabled?: boolean = false;

    @Input()
    public ellipsisMax?: number;

    @Input()
    public placeHolder: ModuleSingleSelectPlaceHolder = ModuleSingleSelectPlaceHolder.All;

    public elementWidth!: string;

    public constructor(private elementRef: ElementRef, store: Store) {
        super(store);
    }

    public handleClick(chapter?: ChapterBookDetailsInterface, event?: Event): void {
        this.dispatch(SelectionActions.setChapter({item: chapter}));
    }

    public updateMenuWidth(): void {
        this.elementWidth = `${this.elementRef.nativeElement.offsetWidth / 10}rem`;
    }

    protected getItemSelector(): MemoizedSelector<object, ChapterBookDetailsInterface | undefined> {
        return SelectionSelectors.selectChapter;
    }

    protected getItemsSelector(): MemoizedSelector<object, ChapterBookDetailsInterface[] | undefined> {
        return LibrarySelectors.selectBookChapters;
    }

    protected filterItems(items: ChapterBookDetailsInterface[]): ChapterBookDetailsInterface[] {
        return items;
    }
}
