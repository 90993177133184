import {Component, OnInit, Renderer2} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {SecuritySelectors} from '@store/security';
import {Store} from '@ngrx/store';
import {UserInterface} from '@shared/interfaces/user.interface';
import {DialogTermsAndConditionsComponent} from '@feature/books/components/terms-and-conditions-dialog/terms-and-conditions-dialog.component';
import {GeneralSelectors} from '@store/general';
import {PublisherColorsInterface, PublisherInterface} from '@shared/interfaces/publisher.interface';
import {BookDetailInterface} from '@shared/interfaces/book.interface';
import {ThemeHelper} from '@shared/helpers/theming.helper';
import {ColorEnum} from '@core/enums/color.enum';
import {AuthorizationService} from '@core/security/services/authorization.service';
import {RoleEnum} from '@core/security/enum/role.enum';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
    public user?: UserInterface;

    public publisher: PublisherInterface | undefined;

    public isVo: boolean;

    public constructor(
        private dialog: MatDialog,
        private themeHelper: ThemeHelper,
        private renderer: Renderer2,
        private authorizationService: AuthorizationService,
        store: Store,
    ) {
        store.select(SecuritySelectors.selectCurrentUser).subscribe(user => this.handleCurrentUser(user));
        store.select(GeneralSelectors.selectPublisherAndBook).subscribe(selection => this.handlePublisherAndBookSelect(selection.publisher, selection.book));
        this.isVo = this.authorizationService.isGranted(RoleEnum.RoleOrganizationVo);
    }

    public ngOnInit(): void {
        if (this.user?.isFirstTime) {
            this.dialog.open(DialogTermsAndConditionsComponent, {panelClass: `custom-dialog-container`, data: {user: this.user}});
        }
    }

    /**
     * This function handles the theming based on publisher and book
     */
    private handlePublisherAndBookSelect(publisher?: PublisherInterface, book?: BookDetailInterface): void {
        if (undefined === publisher) {
            return;
        }

        const body: HTMLElement = document.body;
        const publisherColors: PublisherColorsInterface | undefined = publisher?.colors ?? undefined;
        const primaryColor: string | undefined = book?.color ?? publisherColors?.primary ?? undefined;

        this.renderer.setStyle(body, '--primary-color', primaryColor, 2);
        this.renderer.setStyle(body, '--primary-color-rgba', primaryColor ? this.themeHelper.getHexAsRGBA(primaryColor, 0.1) : undefined, 2);
        this.renderer.setStyle(body, '--primary-adjusted-color', primaryColor ? this.themeHelper.getPrimaryAdjustedColor(primaryColor) : undefined, 2);
        this.renderer.setStyle(body, '--primary-adjusted-color-alt', primaryColor ? this.themeHelper.getPrimaryAdjustedAltColor(primaryColor) : undefined, 2);
        this.renderer.setStyle(body, '--primary-text-color', undefined !== book?.color ? ColorEnum.LightColor : primaryColor, 2);
        this.renderer.setStyle(body, '--secondary-color', publisherColors?.secondary ?? undefined, 2);
        this.renderer.setStyle(body, '--tertiary-color', publisherColors?.tertiary ?? undefined, 2);

        this.publisher = publisher;
    }

    private handleCurrentUser(user?: UserInterface): void {
        if (undefined === user) {
            return;
        }

        this.user = user;
    }
}
