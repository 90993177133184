import {GroupInterface, GroupTeacherInterface, GroupTeacherSelfExamineInterface} from '@shared/interfaces/group.interface';

/**
 * TODO BD-995: Bad practise so do not copy this type of code, typing on groups needs refactoring because it's not strong typed based on backend data
 *
 * @deprecated This helper will be removed in the future
 */
export class GroupTypeHelper {
    public static isTeacherGroup(group?: GroupInterface | GroupTeacherInterface): group is GroupTeacherInterface {
        return undefined !== group && group.hasOwnProperty('students') && group.hasOwnProperty('teachers');
    }

    public static isTeacherSelfExamineGroup(group?: GroupInterface | GroupTeacherSelfExamineInterface): group is GroupTeacherSelfExamineInterface {
        return undefined !== group && group.hasOwnProperty('selfExamine');
    }
}
