import {Action, createReducer, on} from '@ngrx/store';
import {
    updateAcceptTermsAction,
    updateAcceptTermsActionFailed,
    updateAcceptTermsActionSuccess,
    updateLoadedCurrentUserAction,
    updateOrganizationAction,
    updateOrganizationActionFailed,
    updateOrganizationActionSuccess,
} from '@store/security/security.actions';
import {UserAuthenticatedInterface, UserInterface} from '@shared/interfaces/user.interface';
import {HttpErrorResponseInterface} from '@core/interfaces/http-error-response.interface';

export interface SecurityState {
    currentUser?: UserAuthenticatedInterface;
    user?: UserInterface;
    securityError?: HttpErrorResponseInterface,
}

export const initialState: SecurityState = {
    currentUser: undefined,
    user: undefined,
};

export const reducer = createReducer(
    initialState,
    on(updateLoadedCurrentUserAction, (state, {currentUser}) => ({...state, currentUser})),
    on(updateAcceptTermsAction, (state, {currentUser}) => ({...state, currentUser})),
    on(updateAcceptTermsActionSuccess, (state, {currentUser}) => ({...state, updateProfileSuccess: currentUser})),
    on(updateAcceptTermsActionFailed, (state, {error}) => ({...state, updateProfileFailed: error})),
    on(updateOrganizationAction, (state, {organizationId}) => ({...state, organizationId})),
    on(updateOrganizationActionSuccess, (state, {currentUser}) => ({...state, updateProfileSuccess: currentUser})),
    on(updateOrganizationActionFailed, (state, {error}) => ({...state, updateProfileFailed: error})),
);

export const securityReducer = (
    state: SecurityState | undefined,
    action: Action,
): SecurityState => {
    return reducer(state, action);
};
