import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, Observer} from 'rxjs';
import {inject, Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable()
export class ServerHttpInterceptor implements HttpInterceptor {
    readonly #snackbar = inject(MatSnackBar);

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return new Observable((observer: Observer<HttpEvent<any>>) => next.handle(req).subscribe(
            (response: HttpEvent<any>) => observer.next(response),
            (httpErrorResponse: HttpErrorResponse) => {
                /**
                 * @see https://xiponlineapplications.atlassian.net/browse/BD-2390
                 * This interceptor should only handle server error responses
                 */
                if (httpErrorResponse.status >= 500 && httpErrorResponse.status <= 599) {
                    let message: string = 'Oeps er gaat iets mis, we gaan voor je aan de slag!';

                    if (httpErrorResponse.status === 504) {
                        message = 'We zijn even niet bereikbaar, we lossen het voor je op!';
                    } else if (httpErrorResponse.status >= 500) {
                        const url = req.url;
                        const regexForExam = /^\/api\/v2\/exams\/[^\/]+\/content\/[^\/]+\/plan\/[^\/]+\/assignment\/[^\/]+\/attempt\/[^\/]+$/;

                        if (regexForExam.test(url)) {
                            message = 'De oefentoets kan niet gestart worden, een bericht is verzonden naar de uitgever.';
                        }
                    }

                    this.#snackbar.open(message, '', {duration: 9000, horizontalPosition: 'right', verticalPosition: 'top', panelClass: ['red-snackbar']});

                    console.error(httpErrorResponse);
                }

                observer.error(httpErrorResponse);
            },
            () => observer.complete(),
        ));
    }
}
