import {Component, Inject, OnDestroy} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ButtonTypeEnum} from '@shared/button/button.directive';
import {MessageActions} from '@store/message/message.actions';
import {ActionsSubject, Store} from '@ngrx/store';
import {PaginationInterface} from '@core/interfaces/pagination.interface';
import {ofType} from '@ngrx/effects';
import {Subscription} from 'rxjs';

export interface MessageDeleteDialogDataInterface extends PaginationInterface {
    ids: number[];
    total: number
}

@Component({
    selector: 'app-message-delete-dialog',
    templateUrl: './message-delete-dialog.component.html',
    styleUrls: ['./message-delete-dialog.component.scss'],
})
export class MessageDeleteDialogComponent implements OnDestroy {
    #subscriptions: Subscription[] = [];

    #store: Store;

    #dialogRef: MatDialogRef<MessageDeleteDialogComponent>;

    readonly buttonType = ButtonTypeEnum;

    constructor(
        @Inject(MAT_DIALOG_DATA) public dialogData: MessageDeleteDialogDataInterface,
        store: Store,
        dialogRef: MatDialogRef<MessageDeleteDialogComponent>,
        actionsSubject: ActionsSubject,
    ) {
        this.#store = store;
        this.#dialogRef = dialogRef;
        this.#subscriptions.push(
            actionsSubject.pipe(ofType(MessageActions.deleteMessageSuccess)).subscribe(() => this.#dialogRef.close(true)),
        );
    }

    ngOnDestroy(): void {
        this.#subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    cancel(): void {
        this.#dialogRef.close(false);
    }

    delete(): void {
        const dialogData = this.dialogData;

        this.#store.dispatch(MessageActions.deleteMessage({ids: dialogData.ids, offset: dialogData.offset, limit: dialogData.limit}));
    }
}
