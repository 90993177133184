import {Component, EventEmitter, Input, Output} from '@angular/core';
import {PaginationControlsDirective} from 'ngx-pagination';

export interface PaginationButton {
    value?: string;
    disabled?: boolean;
    hidden?: boolean;
    visible?: boolean;
    translateParams?: any;
}

export interface PaginationPageButton extends PaginationButton {
    index: number;
}

export enum PaginationEventType {
    Page,
    Previous,
    Next,
}

export interface PaginationMappedPageEvent {
    button: PaginationPageButton;
    type: PaginationEventType;
    pageChanged: boolean;
}

export enum PaginationMode {
    Default = 'default',
    Design = 'design',
}

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent {
    readonly modes = PaginationMode;

    @Output()
    mappedPageEvent: EventEmitter<PaginationMappedPageEvent> = new EventEmitter<PaginationMappedPageEvent>();

    @Input()
    mapping: PaginationPageButton[] = [];

    @Input()
    paginationApi!: PaginationControlsDirective;

    @Input()
    title?: string;

    @Input()
    hidePages: boolean = false;

    @Input()
    previousButton?: PaginationButton;

    @Input()
    nextButton?: PaginationButton;

    @Input()
    moduleColor: boolean = false;

    @Input()
    mode: PaginationMode = PaginationMode.Default;

    handlePreviousClick(): void {
        const paginationApi: PaginationControlsDirective = this.paginationApi;
        const currentPage: number = this.paginationApi.getCurrent();
        const isFirstPage: boolean = paginationApi.isFirstPage();

        if (!isFirstPage) {
            paginationApi.previous();
        }

        this.#emitMappedPageEvent(isFirstPage ? currentPage : currentPage - 1, PaginationEventType.Previous, !isFirstPage);
    }

    handlePageClick(page: string): void {
        const pageNumber: number = Number(page);
        const mapping: PaginationPageButton | undefined = this.findMappingByIndex(pageNumber - 1);

        if (undefined !== mapping && mapping.disabled) {
            return;
        }

        const paginationApi: PaginationControlsDirective = this.paginationApi;
        const pageChanged: boolean = pageNumber !== paginationApi.getCurrent();

        if (pageChanged) {
            this.paginationApi.setCurrent(pageNumber);
        }

        this.#emitMappedPageEvent(pageNumber, PaginationEventType.Page, pageChanged);
    }

    handleNextClick(): void {
        const paginationApi: PaginationControlsDirective = this.paginationApi;
        const currentPage: number = this.paginationApi.getCurrent();
        const isLastPage: boolean = paginationApi.isLastPage();

        if (!isLastPage) {
            paginationApi.next();
        }

        this.#emitMappedPageEvent(isLastPage ? currentPage : currentPage + 1, PaginationEventType.Next, !isLastPage);
    }

    findMappingByIndex(pageIndex: number): PaginationPageButton | undefined {
        return this.mapping.find((mapped: PaginationPageButton): boolean => mapped.index === pageIndex);
    }

    #emitMappedPageEvent(page: number, type: PaginationEventType, pageChanged: boolean): void {
        const mapping: PaginationPageButton | undefined = this.findMappingByIndex(page - 1);

        if (undefined === mapping) {
            return;
        }

        this.mappedPageEvent.next({button: mapping, type, pageChanged});
    }
}
