import {AfterViewInit, Component, ElementRef, Input, ViewChild, ViewEncapsulation} from '@angular/core';
import hljs from 'highlight.js';

@Component({
    selector: 'app-code-block',
    templateUrl: 'code-block.component.html',
    styleUrls: ['code-block.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CodeBlockComponent implements AfterViewInit {
    @ViewChild('code') readonly codeElement!: ElementRef;

    @Input() language: string = '';

    ngAfterViewInit(): void {
        const code = this.codeElement.nativeElement.textContent || '';
        const language = this.language.replace(/\s+/g, '');

        this.codeElement.nativeElement.innerHTML = hljs.highlight(code, {language}).value;
    }
}
