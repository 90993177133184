import {createAction, props} from '@ngrx/store';
import {SecurityActions} from '@store/security/security.enum';
import {UserAuthenticatedInterface, UserInterface} from '@shared/interfaces/user.interface';
import {HttpErrorResponseInterface} from '@core/interfaces/http-error-response.interface';
import {UpgradeToTeacherResultInterface} from '@core/security/services/user.service';

export const fetchCurrentUserAction = createAction(SecurityActions.fetchCurrentUser);
export const updateLoadedCurrentUserAction = createAction(SecurityActions.updateLoadedCurrentUser, props<{ currentUser: UserAuthenticatedInterface }>());

export const fetchAcceptTermsAction = createAction(SecurityActions.fetchAcceptTermsAction);
export const updateAcceptTermsAction = createAction(SecurityActions.updateAcceptTermsAction, props<{ currentUser?: UserAuthenticatedInterface }>());

export const updateAcceptTermsActionSuccess = createAction(SecurityActions.updateAcceptTermsActionSuccess, props<{ currentUser?: UserInterface }>());

export const updateAcceptTermsActionFailed = createAction(SecurityActions.updateAcceptTermsActionFailed, props<{ error: HttpErrorResponseInterface }>());

export const updateOrganizationAction = createAction(SecurityActions.updateOrganizationAction, props<{organizationId: string }>());

export const updateOrganizationActionSuccess = createAction(SecurityActions.updateOrganizationActionSuccess, props<{ currentUser?: UserInterface }>());

export const updateOrganizationActionFailed = createAction(SecurityActions.updateOrganizationActionFailed, props<{ error: HttpErrorResponseInterface }>());

export const upgradeToTeacherActionSuccess = createAction(SecurityActions.upgradeToTeacherActionSuccess, props<{ upgradeResult: UpgradeToTeacherResultInterface }>());

export const upgradeToTeacherActionFailed = createAction(SecurityActions.upgradeToTeacherActionFailed, props<{ error: HttpErrorResponseInterface }>());
