import {Action, createReducer, on} from '@ngrx/store';
import {AppExamMetadata, AppExamStep} from '@component/exam/player/player.service';
import {AppExamActions} from '@component/exam/redux/exam.actions';
import {ApiExamInterface, ApiExamListInterface} from '@component/exam/exam.service';
import {ApiExamsResponseInterface} from '@store/exam/exam-api.contract';

export interface AppExamState {
    metadata?: AppExamMetadata;
    step?: AppExamStep;
    list?: ApiExamListInterface;
    exams: ApiExamInterface[];
}

const appExamState: AppExamState = {
    exams: [],
};

const reducer = createReducer(
    appExamState,
    on(AppExamActions.metadataSuccess, (state: AppExamState, data: { metadata: AppExamMetadata }): AppExamState => ({...state, metadata: data.metadata})),
    on(AppExamActions.finishSuccess, (state: AppExamState, data: { metadata: AppExamMetadata }): AppExamState => ({...state, metadata: data.metadata})),
    on(AppExamActions.startSuccess, (state: AppExamState, data: { metadata: AppExamMetadata }): AppExamState => ({...state, metadata: data.metadata})),
    on(AppExamActions.stepSuccess, (state: AppExamState, data: { step: AppExamStep }): AppExamState => ({...state, step: data.step})),
    on(AppExamActions.clear, (state: AppExamState): AppExamState => ({...state, step: undefined, metadata: undefined})),
    on(AppExamActions.assignSuccess, (state: AppExamState, list: ApiExamListInterface): AppExamState => ({...state, step: undefined, list})),
    on(AppExamActions.clearBookExams, (state) => ({...state, exams: []})),
    on(AppExamActions.fetchBookExamsSuccess, (state, response: ApiExamsResponseInterface) => ({...state, exams: response.exams})),
    on(AppExamActions.fetchAssignedSuccess, (state, list: ApiExamListInterface) => ({...state, list})),
);

export const AppExamReducer = (state: AppExamState | undefined, action: Action): AppExamState => {
    return reducer(state, action);
};
