import {Component, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ButtonTypeEnum} from '@shared/button/button.directive';
import {SingleSelectInterface} from '@shared/interfaces/single-select.interface';
import {SingleSelectTypeEnum} from '@shared/enums/single-select-type.enum';

export enum MenuPlaceHolder {
    All = 'singleSelect.select',
}

@Component({
    selector: 'app-single-select',
    templateUrl: './single-select.component.html',
    styleUrls: ['./single-select.component.scss'],
})
export class SingleSelectComponent implements OnInit, OnChanges {
    @HostBinding('class')
    public readonly classes: string[] = ['single-select'];

    @Input()
    public placeHolder?: string = MenuPlaceHolder.All;

    @Input()
    public ellipsisMax?: number;

    @Input()
    public title?: string;

    @Input()
    public items: SingleSelectInterface[] = [];

    @Input()
    public dropdownWidth: string = '39rem';

    @Input()
    public emptyListCheck: boolean = false;

    @Input()
    public type?: string;

    @Input()
    public paragraphBackground: boolean = false;

    @Output()
    public selectEvent: EventEmitter<SingleSelectInterface> = new EventEmitter();

    public selected?: any;

    public buttonType = ButtonTypeEnum;

    public buttonTypeChoice?: ButtonTypeEnum;

    public typeEnum = SingleSelectTypeEnum;

    public color: string = 'black';

    public ngOnInit(): void {
        const allowedTypes = this.getAllowedTypes();

        if (this.type && allowedTypes.indexOf(this.type) > -1) {
            this.buttonTypeChoice = this.buttonType.LicenseLarge;
            this.color = '#6A747A';

            return;
        }

        if (this.type === this.typeEnum.LicenseOnColor) {
            this.buttonTypeChoice = this.buttonType.LicenseLarge;
            this.color = '#6A747A';

            return;
        }

        this.buttonTypeChoice = this.buttonType.WhiteLarge;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if(changes.paragraphBackground && changes.paragraphBackground.currentValue === true) {
            this.buttonTypeChoice = this.buttonType.LicenseOnColorLarge;
            this.color = '#ffffff';
        }
    }

    public handleSelectedItem(item?: SingleSelectInterface): void {
        if (undefined === item || item === this.selected) {
            return;
        }

        this.selected = item;
        this.selectEvent.emit(item);
    }

    private getAllowedTypes(): Array<string> {
        return [this.typeEnum.License, this.typeEnum.Progress, this.typeEnum.Groups,this.typeEnum.Sources, this.typeEnum.Chapter, this.typeEnum.TeacherMaterial]
    }
}
