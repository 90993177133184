import {Injectable} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import {environment} from '@environment/environment';
import {EventSourcePolyfill} from 'event-source-polyfill';
import {MercureInterface} from '@shared/interfaces/user.interface';

@Injectable({
    providedIn: 'root',
})
export class MercureService {
    readonly #store: Store;

    #eventSource?: EventSource;

    constructor(store: Store) {
        this.#store = store;
    }

    subscribeToMessage<MessageDataType>(mercure: MercureInterface, fn: (event: MessageEvent<MessageDataType>) => void): Subscription {
        return this.#subscribe<'message'>(mercure.token, mercure.topics, fn);
    }

    #subscribe<EventType extends keyof EventSourceEventMap>(token: string, topics: string[], fn: (event: EventSourceEventMap[EventType]) => void): Subscription {
        const eventSource = this.#getOrCreateDataSource(token, topics);

        return new Observable<EventSourceEventMap[EventType]>(observer => {
            eventSource.addEventListener('message', (event) => observer.next(event));
            eventSource.addEventListener('error', (event) => console.error(event));
        }).subscribe(event => fn(event));
    }

    close(): void {
        const eventSource: EventSource | undefined = this.#eventSource;

        if (undefined === eventSource) {
            console.warn(`Tried to close non-existent EventSource`);

            return;
        }

        eventSource.close();
    }

    #getOrCreateDataSource<DataType>(token: string, topics: string[]): EventSource {
        const currentUrl = new URL(window.location.href);
        const eventSourceUrl = new URL(`${currentUrl.origin}/${environment.mercureHub}`);

        topics.forEach(topic => eventSourceUrl.searchParams.append('topic', topic));

        return new EventSourcePolyfill(eventSourceUrl.toString(), {headers: {'Authorization': `Bearer ${token}`}});
    }
}
