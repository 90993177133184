import {createFeatureSelector, createSelector} from '@ngrx/store';
import {StoreFeatureSelectorEnum} from '@store/store.enum';
import {SearchState} from '@store/search/search.reducer';
import {SearchResultsMap} from '@store/search/search-results.map';

export class SearchSelectors {
    public static readonly getState = createFeatureSelector<SearchState>(
        StoreFeatureSelectorEnum.Search,
    );

    public static readonly selectResults = createSelector(
        SearchSelectors.getState,
        (state: SearchState): SearchResultsMap => state.results,
    );

    public static readonly selectResultsByDataSourceId = (datasourceId: string) => createSelector(
        SearchSelectors.selectResults,
        (searchResultsMap: SearchResultsMap) => searchResultsMap.get(datasourceId),
    );
}
