import {createFeatureSelector, createSelector} from '@ngrx/store';
import {StoreFeatureSelectorEnum} from '@store/store.enum';
import {OrganizationState} from '@store/organization/organization.reducer';

export class OrganizationSelectors {
    public static readonly getState = createFeatureSelector<OrganizationState>(
        StoreFeatureSelectorEnum.Organization,
    );

    public static readonly selectOrganizations = createSelector(
        OrganizationSelectors.getState,
        (state: OrganizationState) => state.organizations,
    );
}
