import {createAction, props} from '@ngrx/store';
import {SelfExamineInterface} from '@shared/interfaces/self-examine.interface';
import {SelfExamineCreatePropertiesInterface, SelfExamineFailedPropertiesInterface} from '@store/self-examine/self-examine.properties';
import {SelfExamineActionsEnum} from '@store/self-examine/self-examine.enum';

export class SelfExamineActions {
    public static readonly create = createAction(SelfExamineActionsEnum.Create, props<SelfExamineCreatePropertiesInterface>());

    public static readonly createSuccess = createAction(SelfExamineActionsEnum.CreateSuccess, props<SelfExamineInterface>());

    public static readonly creatFailed = createAction(SelfExamineActionsEnum.CreateFailed, props<SelfExamineFailedPropertiesInterface<SelfExamineCreatePropertiesInterface>>());
}
