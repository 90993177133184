import * as _ from 'lodash';
import {GroupInterface, GroupTeacherInterface} from '@shared/interfaces/group.interface';
import {UserAuthenticatedInterface} from '@shared/interfaces/user.interface';
import {RoleEnum} from '@core/security/enum/role.enum';
import {RolesMapping} from '@core/security/mapping/roles.mapping';
import {GroupTypeHelper} from '@store/group/group.helpers';

export class GroupMap extends Map<number, GroupInterface | GroupTeacherInterface> {
    public toArray(): GroupInterface[] | undefined {
        return Array.from(this.values()).sort(() => 1);
    }

    public removeGroup(groupId: number): GroupMap {
        this.delete(groupId);

        return _.clone(this);
    }

    public updateGroupName(groupId: number, groupName: string): GroupMap {
        const group: GroupInterface | undefined = this.getGroup(groupId);

        if (undefined === group) {
            throw new Error('Failed to remove group from state, group was not found');
        }

        this.set(groupId, {
            ...group,
            name: groupName,
        });

        return _.clone(this);
    }

    public joinGroup(groups: GroupInterface[], user: UserAuthenticatedInterface): GroupMap {
        groups.forEach((groupItem: GroupInterface | GroupTeacherInterface) => {
            const group: GroupInterface | undefined = this.getGroup(groupItem.id);

            if (undefined === group) {
                throw new Error('Failed to remove group from state, group was not found');
            }

            if (!GroupTypeHelper.isTeacherGroup(group)) {
                return;
            }

            const isTeacher = RoleEnum.RoleTeacher;
            const role = user.roles.some(userRole => {
                const inheritedRoles: RoleEnum[] | undefined = RolesMapping.get(userRole);

                isTeacher === userRole || (undefined !== inheritedRoles && inheritedRoles.includes(isTeacher));
            });

            if (role) {
                group.teachers.push(user);
                this.set(groupItem.id, {
                    ...group,
                    teachers: group.teachers,
                });
            } else {
                group.students.push(user);
                this.set(groupItem.id, {
                    ...group,
                    students: group.students,
                });
            }

        });

        return _.clone(this);
    }

    public renewGroupCode(groupId: number, groupCode: string): GroupMap {
        const group: GroupInterface | undefined = this.getGroup(groupId);

        if (undefined === group) {
            throw new Error('Failed to remove group from state, group was not found');
        }

        this.set(groupId, {
            ...group,
            code: groupCode,
        });

        return _.clone(this);
    }

    public updateGroupStatus(groupId: number, active: boolean): GroupMap {
        const group: GroupInterface | undefined = this.getGroup(groupId);

        if (undefined === group) {
            throw new Error('Failed to active group from state, group was not found');
        }

        this.set(groupId, {
            ...group,
            active: active,
        });

        return _.clone(this);
    }

    public removeGroupUser(groupId: number, userId: number): GroupMap {
        const group: GroupInterface | undefined = this.getGroup(groupId);

        if (undefined === group) {
            throw new Error('Failed to remove group from state, group was not found');
        }

        if (undefined === userId) {
            throw new Error('Failed to remove user from state, user was not found');
        }

        if (!GroupTypeHelper.isTeacherGroup(group)) {
            throw new Error('Failed to remove group from state, group is not of type GroupTeacherInterface');
        }

        const teacherIndex: number | undefined = group.teachers.findIndex(user => user.id === userId);
        const studentIndex: number | undefined = group.students.findIndex(user => user.id === userId);

        if (studentIndex !== -1) {
            let newState = [...group.students];

            newState.splice(studentIndex, 1);

            this.set(groupId, {
                ...group,
                students: newState,
            });
        }

        if (teacherIndex !== -1) {
            let newState = [...group.teachers];

            newState.splice(teacherIndex, 1);

            this.set(groupId, {
                ...group,
                teachers: newState,
            });
        }

        return _.clone(this);
    }

    public addGroup(group: GroupInterface): GroupMap {
        const groupObject = {id: group.id, name: group.name, code: group.code, active: group.active};

        if (GroupTypeHelper.isTeacherGroup(group)) {
            this.set(group.id, {...groupObject, students: group.students, teachers: group.teachers});
        } else {
            this.set(group.id, groupObject);
        }

        return _.clone(this);
    }

    public setGroups(groups: GroupInterface[]): GroupMap {
        this.clear(); // Always clear when receiving a new batch

        groups.forEach(group => this.set(group.id, group));

        return _.clone(this);
    }

    public getGroupById(groupId: number): GroupInterface | undefined {
        return this.get(groupId);
    }

    private getGroup(groupId: number): GroupInterface | undefined {
        const group: GroupInterface | undefined = this.get(groupId);

        if (undefined === group) {
            throw new Error('Failed to remove group from state, group was not found');
        }

        return group;
    }
}
