import {Injectable} from '@angular/core';
import {RoleEnum} from '@core/security/enum/role.enum';
import {AuthorizationService} from '@core/security/services/authorization.service';
import {StringService} from '@core/services/string/string.service';
import {DocumentTypeEnum} from '@shared/enums/document-type.enum';
import {LibraryContextInterface} from '@store/library';
import {AppRouteEnum} from '@/router/router.module';

export enum NavItemEnum {
    Profile = 'navigation.profile',
    Sources = 'navigation.sources',
    TeacherMaterial = 'navigation.teacher-material',
    Classes = 'navigation.classes',
    Results = 'navigation.results',
    Progress = 'navigation.progress',
    Books = 'navigation.books',
    Overview = 'navigation.overview',
    Licenses = 'navigation.licenses',
    Messages = 'navigation.messages',
    Exam = 'navigation.exam',
}

export enum NavItemScope {
    Default,
    Book,
}

interface NavItemRoute {
    path: string;
    url?: string;
    exact?: boolean; // Path matching support
}

export interface NavItem {
    name: NavItemEnum;
    icon: string;
    route: NavItemRoute;
    image?: string;
    scopes?: NavItemScope[];
    roles?: RoleEnum[];
    type?: DocumentTypeEnum;
}

@Injectable()
export class NavItemFactory {
    private navItems: NavItem[] = [
        {
            name: NavItemEnum.Licenses,
            route: {
                path: '/books',
            },
            icon: 'licenties',
            scopes: [NavItemScope.Default],
            roles: [RoleEnum.RoleOrganizationHo],
        },
        {
            name: NavItemEnum.Overview,
            route: {
                path: '/books',
            },
            icon: 'licenties',
            scopes: [NavItemScope.Default],
            roles: [RoleEnum.RoleOrganizationVo],
        },
        {
            name: NavItemEnum.Books,
            route: {
                path: '/books/:bookUuid',
            },
            icon: 'inhoud',
            scopes: [NavItemScope.Book],
        },
        {
            name: NavItemEnum.Progress,
            route: {
                path: '/progress/:bookUuid',
            },
            icon: 'voortgang',
            scopes: [NavItemScope.Book],
            roles: [RoleEnum.RoleTeacher],
        },
        {
            name: NavItemEnum.Progress,
            route: {
                path: '/progress/:bookUuid',
            },
            icon: 'voortgang',
            scopes: [NavItemScope.Book],
            roles: [RoleEnum.RoleOrganizationHo, RoleEnum.RoleStudent],
        },
        {
            name: NavItemEnum.Results,
            route: {
                path: '/progress/:bookUuid/assignments',
            },
            icon: 'results-student',
            scopes: [NavItemScope.Book],
            roles: [RoleEnum.RoleOrganizationVo, RoleEnum.RoleStudent],
        },
        {
            name: NavItemEnum.Classes,
            route: {
                path: '/classes',
            },
            icon: 'klassen',
            scopes: [NavItemScope.Default],
            roles: [RoleEnum.RoleTeacher],
        },
        {
            name: NavItemEnum.Classes,
            route: {
                path: '/classes/book/:bookUuid',
            },
            icon: 'klassen',
            roles: [RoleEnum.RoleTeacher],
            scopes: [NavItemScope.Book],
        },
        {
            name: NavItemEnum.Classes,
            route: {
                path: '/profile/groups',
            },
            icon: 'klassen',
            scopes: [NavItemScope.Default],
            roles: [RoleEnum.RoleOrganizationVo, RoleEnum.RoleStudent],
        },
        {
            name: NavItemEnum.TeacherMaterial,
            route: {
                path: '/teacher-material/:bookUuid',
            },
            icon: 'folder',
            roles: [RoleEnum.RoleOrganizationVo, RoleEnum.RoleTeacher],
            scopes: [NavItemScope.Book],
        },
        {
            name: NavItemEnum.Sources,
            route: {
                path: '/sources/:bookUuid',
            },
            icon: 'sources',
            roles: [RoleEnum.RoleOrganizationVo, RoleEnum.RoleTeacher],
            scopes: [NavItemScope.Book],
        },
        {
            name: NavItemEnum.Exam,
            route: {
                path: '/' + AppRouteEnum.Exam,
            },
            icon: 'exam-purple',
            roles: [RoleEnum.RoleExams],
            scopes: [NavItemScope.Default],
        },
        {
            name: NavItemEnum.Messages,
            route: {
                path: '/messages',
                exact: true,
            },
            icon: 'berichten',
        },
        {
            name: NavItemEnum.Profile,
            route: {
                path: '/profile',
                exact: true,
            },
            icon: 'profile-user',
        },
    ];

    public constructor(
        private authorizationService: AuthorizationService,
        private stringService: StringService,
    ) {
    }

    public forContextState(contextState: LibraryContextInterface): NavItem[] {
        const scope: NavItemScope = this.getNavItemScope(contextState);
        const contextParams: Map<string, string> = new Map(Object.entries(contextState));

        return this.navItems.filter(navItem => {
            const isValidScope: boolean = undefined === navItem.scopes || navItem.scopes.includes(scope);
            const isGranted: boolean = undefined === navItem.roles || this.authorizationService.isEveryGranted(navItem.roles);

            return isValidScope && isGranted;
        }).map((navItem: NavItem) => {
            navItem.route = {
                ...navItem.route,
                url: this.stringService.getMappedString(navItem.route.path, contextParams),
            };

            return navItem;
        });
    }

    private getNavItemScope(contextState: LibraryContextInterface): NavItemScope {
        return undefined !== contextState.bookUuid ? NavItemScope.Book : NavItemScope.Default;
    }
}
