import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, OperatorFunction, Subscription} from 'rxjs';
import {UserInterface} from '@shared/interfaces/user.interface';
import {filter, take} from 'rxjs/operators';
import {AssignmentInterface} from '@shared/interfaces/assignment.interface';
import {AssignmentDocumentDetailsInterface} from '@core/services/assignment/assignment.service';
import {LibraryContextInterface} from '@store/library';

export interface ResetAssignmentInterface {
    assignment: AssignmentInterface;
    user?: UserInterface;
}

export interface ToggleAssignmentAnswersInterface {
    assignment: AssignmentInterface;
    value: boolean;
}

export interface FetchAssignmentPropertiesInterface {
    bookUuid: string;
    chapterUuid: string;
    documentDpsId: string;
}

@Injectable({
    providedIn: 'root',
})
export class DocumentAssignmentService {
    #resetSubject: BehaviorSubject<ResetAssignmentInterface | undefined> = new BehaviorSubject<ResetAssignmentInterface | undefined>(undefined);

    #selectByDpsIdSubject: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);

    #selectByDpsIdSuccessSubject: BehaviorSubject<AssignmentInterface | undefined> = new BehaviorSubject<AssignmentInterface | undefined>(undefined);

    #fetchByContextSubject: BehaviorSubject<FetchAssignmentPropertiesInterface | undefined> = new BehaviorSubject<FetchAssignmentPropertiesInterface | undefined>(undefined);

    #fetchByDpsIdSubjectSuccess: BehaviorSubject<AssignmentDocumentDetailsInterface | undefined> = new BehaviorSubject<AssignmentDocumentDetailsInterface | undefined>(undefined);

    #handInSubject: BehaviorSubject<AssignmentInterface | undefined> = new BehaviorSubject<AssignmentInterface | undefined>(undefined);

    #autoCheckSubject: BehaviorSubject<AssignmentInterface | undefined> = new BehaviorSubject<AssignmentInterface | undefined>(undefined);

    #contextSubject: BehaviorSubject<LibraryContextInterface | undefined> = new BehaviorSubject<LibraryContextInterface | undefined>(undefined);

    /**
     * This method is to be called by code from inside and outside the content module, it'll trigger state changes
     */
    public setContext(bookUuid?: string, chapterUuid?: string, documentDpsId?: string): void {
        this.#contextSubject.next({bookUuid, chapterUuid, documentDpsId});
    }

    /**
     * This method is to be listened to from code outside the content module
     */
    public subscribeToContext(fn: (context: LibraryContextInterface) => void): Subscription {
        return this.#contextSubject.pipe(
            filter(context => undefined !== context) as OperatorFunction<LibraryContextInterface | undefined, LibraryContextInterface>,
        ).subscribe(context => fn(context));
    }

    /**
     * Can be used anywhere if you want the current context just once
     */
    public selectContext(fn: (context: LibraryContextInterface) => void): Subscription {
        return this.#contextSubject.pipe(
            take(1),
            filter(context => undefined !== context) as OperatorFunction<LibraryContextInterface | undefined, LibraryContextInterface>,
        ).subscribe(context => fn(context));
    }

    /**
     * This method is to be called by code from inside and outside the content module
     */
    public selectByDpsId(assignmentDpsId: string): Observable<AssignmentInterface> {
        this.#selectByDpsIdSubject.next(assignmentDpsId);

        return this.#selectByDpsIdSuccessSubject.pipe(
            filter((assignment?: AssignmentInterface) => assignmentDpsId === assignment?.dpsId) as OperatorFunction<AssignmentInterface | undefined, AssignmentInterface>,
        );
    }

    /**
     * This method is to be listened to from code outside the content module
     */
    public subscribeToSelectByDpsId(fn: (assignmentDpsId: string) => void): Subscription {
        return this.#selectByDpsIdSubject.pipe(
            filter(assignmentDpsId => undefined !== assignmentDpsId) as OperatorFunction<string | undefined, string>,
        ).subscribe(assignmentDpsId => fn(assignmentDpsId));
    }

    /**
     * This method is to be called from code outside the content module
     */
    public selectByDpsIdSuccess(assignment: AssignmentInterface): void {
        this.#selectByDpsIdSuccessSubject.next(assignment);
    }

    /**
     * This method is to be called by code from inside and outside the content module
     */
    public fetchByContext(bookUuid: string, chapterUuid: string, documentDpsId: string): Observable<AssignmentDocumentDetailsInterface> {
        this.#fetchByContextSubject.next({bookUuid, chapterUuid, documentDpsId});

        return this.#fetchByDpsIdSubjectSuccess.pipe(
            filter(assignmentDocumentDetails => documentDpsId === assignmentDocumentDetails?.assignment.dpsId) as OperatorFunction<AssignmentDocumentDetailsInterface | undefined, AssignmentDocumentDetailsInterface>,
        );
    }

    /**
     * This method is to be listened to from code outside the content module
     */
    public subscribeToFetchByContext(fn: (properties: FetchAssignmentPropertiesInterface) => void): Subscription {
        return this.#fetchByContextSubject.pipe(
            filter(properties => undefined !== properties) as OperatorFunction<FetchAssignmentPropertiesInterface | undefined, FetchAssignmentPropertiesInterface>,
        ).subscribe(properties => fn(properties));
    }

    /**
     * This method is to be called from code outside the content module
     */
    public fetchByDpsIdSuccess(document: AssignmentDocumentDetailsInterface): void {
        this.#fetchByDpsIdSubjectSuccess.next(document);
    }

    /**
     * This method is to be called by code from inside and outside the content module, it'll trigger state changes
     */
    public reset(resetAssignment: ResetAssignmentInterface): void {
        this.#resetSubject.next(resetAssignment);
    }

    /**
     * This method is to be listened to from code outside the content module
     */
    public subscribeToReset(fn: (resetAssignment: ResetAssignmentInterface) => void): Subscription {
        return this.#resetSubject.pipe(
            filter(resetAssignment => undefined !== resetAssignment) as OperatorFunction<ResetAssignmentInterface | undefined, ResetAssignmentInterface>,
        ).subscribe(resetAssignment => fn(resetAssignment));
    }

    /**
     * This method is to be called by code from inside and outside the content module, it'll trigger state changes
     */
    public handIn(assignment: AssignmentInterface): void {
        this.#handInSubject.next(assignment);
    }

    /**
     * This method is to be called by code from inside and outside the content module, it'll trigger state changes
     */
    public autoCheck(assignment: AssignmentInterface): void {
        this.#autoCheckSubject.next(assignment);
    }

    /**
     * This method is to be listened to from code outside the content module
     */
    public subscribeToHandIn(fn: (assignment: AssignmentInterface) => void): Subscription {
        return this.#handInSubject.pipe(
            filter(assignment => undefined !== assignment) as OperatorFunction<AssignmentInterface | undefined, AssignmentInterface>,
        ).subscribe(assignment => fn(assignment));
    }
}
