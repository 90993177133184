export enum GeneralActionsEnum {
    ToggleNavigationSecondExpanded = '[general] ToggleNavigationSecondExpanded',
    ClickTableOfContentsItem = '[general] ClickTableOfContentsItem',
    FetchPublisher = '[general] FetchPublisher',
    FetchPublisherSuccess = '[general] FetchPublisherSuccess',
    FetchPublisherFailed = '[general] FetchPublisherFailed',
    PatchUserSettings = '[general] PatchUserSettings',
    PatchUserSettingsSuccess = '[general] PatchUserSettingsSuccess',
    PatchUserSettingsFailed = '[general] PatchUserSettingsFailed',
}

export enum GeneralUserSettingType {
    ModuleVisited = 'module-visited',
    Locale = 'locale',
}
