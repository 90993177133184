import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, Observer} from 'rxjs';
import {inject, Injectable, Injector} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AppTranslateService} from '@core/services/translate/translate.service';

interface ContextualErrorInterface {
    message: string;
    context: string;
}

@Injectable({
    providedIn: 'root',
})
export class AuthenticationHttpInterceptor implements HttpInterceptor {
    #excludePaths: string[] = [];

    #snackbar = inject(MatSnackBar);

    #injector: Injector = inject(Injector);

    public constructor(
        excludePaths: string[] = [],
    ) {
        this.#excludePaths = excludePaths;
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return new Observable((observer: Observer<HttpEvent<any>>) => {
            next.handle(req).subscribe((response: HttpEvent<any>) => {
                observer.next(response);
            }, (httpErrorResponse: HttpErrorResponse) => {
                if (httpErrorResponse.status >= 400 && httpErrorResponse.status <= 499) {
                    if (httpErrorResponse.status === 401 && !this.#isExcludedUrl(httpErrorResponse)) {
                        location.href = httpErrorResponse.error.redirectUrl;

                        return;
                    }

                    if (httpErrorResponse.status === 403) {
                        const translateService = this.#injector.get(AppTranslateService);
                        const error: any = httpErrorResponse.error;
                        const message = translateService.instant(this.#isContextualException(error) ? error.context : 'general.security.accessDenied');

                        this.#snackbar.open(message, '', {
                            duration: 9000,
                            horizontalPosition: 'right',
                            verticalPosition: 'top',
                            panelClass: ['red-snackbar'],

                        });

                        return;
                    }

                    observer.error(httpErrorResponse);
                }
            }, () => {
                observer.complete();
            });
        });
    }

    #isContextualException(error: any): error is ContextualErrorInterface {
        return error.hasOwnProperty('message') && error.hasOwnProperty('context');
    }

    #isExcludedUrl(httpErrorResponse: HttpErrorResponse): boolean {
        const url: URL | undefined = null !== httpErrorResponse.url ? new URL(httpErrorResponse.url) : undefined;

        return undefined !== url ? this.#excludePaths.some(excludePath => url.pathname.startsWith(excludePath)) : false;
    }
}
