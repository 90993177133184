import {Component, HostBinding, OnDestroy} from '@angular/core';
import {VERSION} from '../../../version-control/version';
import {environment} from '@environment/environment';
import {GeneralSelectors} from '@store/general';
import {Store} from '@ngrx/store';
import {RoleEnum} from '@core/security/enum/role.enum';
import {PublisherInterface} from '@shared/interfaces/publisher.interface';
import {BookDetailInterface} from '@shared/interfaces/book.interface';
import {Subscription} from 'rxjs';
import {LibrarySelectors} from '@store/library';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnDestroy {
    @HostBinding('class')
    private readonly classes: string[] = ['footer'];

    private readonly subscriptions: Subscription[] = [];

    public readonly roleEnum = RoleEnum;

    public appVersion: string | undefined;

    public appTag: string | undefined;

    public appHash: string | undefined;

    public showGitVersion: boolean = false;

    public publisher?: PublisherInterface;

    public hasBook: boolean = false;

    public book?: BookDetailInterface;

    public constructor(private store: Store) {
        this.subscriptions.push(
            store.select(GeneralSelectors.selectPublisherAndBook).subscribe(selection => this.handlePublisher(selection.publisher, selection.book)),
            store.select(LibrarySelectors.selectBook).subscribe(book => this.book = book),
        );

        this.setGitVersion();
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    private handlePublisher(publisher?: PublisherInterface, book?: BookDetailInterface): void {
        this.hasBook = undefined !== book;
        this.publisher = publisher;
    }

    private setGitVersion(): void {
        this.showGitVersion = environment.gitVersion;
        this.appVersion = VERSION.version;
        this.appTag = VERSION.tag;
        this.appHash = VERSION.hash;
    }
}
