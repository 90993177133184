import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AssignmentFeedbackCreatePropertiesInterface, AssignmentFeedbackRespondPropertiesInterface} from '@store/assignment/assignment.properties';
import {Store} from '@ngrx/store';
import {ApiService} from '@core/services/api/api.service';
import {ApiV2EndpointsEnum} from '@core/enums/api-v2-endpoints.enum';
import {AssignmentDocumentDetailsInterface} from '@core/services/assignment/assignment.service';

@Injectable({
    providedIn: 'root',
})
export class FeedbackService {
    #api: ApiService;

    public constructor(store: Store, api: ApiService) {
        this.#api = api;
    }

    createFeedback(properties: AssignmentFeedbackCreatePropertiesInterface): Observable<AssignmentDocumentDetailsInterface> {
        const formData: FormData = new FormData();

        formData.append('message', properties.body);

        return this.#api.post<AssignmentDocumentDetailsInterface>(ApiV2EndpointsEnum.FeedbackCreate, formData, undefined, new Map([
            ['bookUuid', properties.bookUuid],
            ['chapterUuid', properties.chapterUuid],
            ['assignmentDocumentDpsId', properties.assignmentDocumentDpsId],
            ['fieldNumber', properties.fieldNumber.toString()],
            ['userId', properties.userId.toString()],
        ]));
    }

    feedbackRespond(properties: AssignmentFeedbackRespondPropertiesInterface): Observable<AssignmentDocumentDetailsInterface> {
        const formData: FormData = new FormData();

        formData.append('message', properties.body);

        return this.#api.post<AssignmentDocumentDetailsInterface>(ApiV2EndpointsEnum.FeedbackRespond, formData, undefined, new Map([
            ['bookUuid', properties.bookUuid],
            ['chapterUuid', properties.chapterUuid],
            ['feedbackId', properties.feedbackId.toString()],
        ]))
    }
}
