import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {SharedModule} from '@shared/shared.module';
import {ExtendedModule, FlexModule} from '@angular/flex-layout';
import {CoreModule} from '@core/core.module';
import {MessagesComponent} from '@/component/messages/messages.component';
import {MessageDialogComponent} from '@/component/messages/message-dialog/message-dialog.component';
import {MessageDeleteDialogComponent} from '@/component/messages/message-delete-dialog/message-delete-dialog.component';
import {AppStoreModule} from '@store/store.module';
import {PaginationModule} from '@component/pagination/pagination.module';
import {LanguageDropdownModule} from '@component/language/dropdown/language-dropdown.module';
import {ContainerHeaderModule} from '@component/container-header/container-header.module';
import {MessagesNewExamAssignComponent} from '@component/messages/messages-new-exam-assign/messages-new-exam-assign.component';
import {MessagesNewFeedbackComponent} from '@component/messages/messages-new-feedback/messages-new-feedback.component';

@NgModule({
    declarations: [
        MessagesComponent,
        MessageDialogComponent,
        MessageDeleteDialogComponent,
        MessagesNewExamAssignComponent,
        MessagesNewFeedbackComponent,
    ],
    imports: [
        CommonModule,
        TranslateModule,
        SharedModule,
        ContainerHeaderModule,
        ExtendedModule,
        FlexModule,
        CoreModule,
        AppStoreModule,
        PaginationModule,
        LanguageDropdownModule,
    ],
})
export class MessagesModule {
}
