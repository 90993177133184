import {Injectable} from '@angular/core';
import {BookDetailInterface, BookListInterface} from '@shared/interfaces/book.interface';
import {ApiService} from '@core/services/api/api.service';
import {ApiV2EndpointsEnum} from '@core/enums/api-v2-endpoints.enum';
import {ChapterProgressInterface} from '@shared/interfaces/chapter.interface';
import {TableOfContentsInterface} from '@shared/interfaces/table-of-contents.interface';
import {DocumentDetailsInterface} from '@shared/interfaces/document.interface';
import {SearchResultsInterface} from '@core/search/component/search/search.component';
import {AssignmentDocumentDetailsInterface} from '@core/services/assignment/assignment.service';
import {sprintf} from '@core/core.functions';
import {Observable} from 'rxjs';

export interface BookPatchIndexInterface {
    position: number;
}

@Injectable({
    providedIn: 'root',
})
export class BookApiService {
     #currentBook: BookDetailInterface | null = null;

     public constructor(private api: ApiService) {}

     public retrieveBooks(offset?: number, limit?: number, query?: string | null): Observable<BookListInterface> {
         const urlSearchParams: URLSearchParams = new URLSearchParams([
             ['offset', (offset ?? 0).toString()],
             ['limit', (limit ?? -1).toString()],
         ]);

         if (query != null) {
             urlSearchParams.append('query', query);
         }

         // quick fix to prevent a books -1 call with progess (api call becomes too heavy)
         if (limit ?? -1 == -1) {
             urlSearchParams.append('withoutProgress', 'true');
         }

         return this.api.get<BookListInterface>(ApiV2EndpointsEnum.LibraryBooks, urlSearchParams);
     }

     public retrieveBooksSearchResults(query: string, offset: number, limit: number): Observable<SearchResultsInterface> {
         const urlSearchParams: URLSearchParams = new URLSearchParams([
             ['query', query],
             ['offset', offset.toString()],
             ['limit', limit.toString()],
         ]);

         return this.api.get<SearchResultsInterface>(ApiV2EndpointsEnum.SearchBooks, urlSearchParams);
     }

     getCurrentBook(): BookDetailInterface | null {
         return this.#currentBook;
     }

     public retrieveBook(bookUuid: string): Observable<BookDetailInterface> {
         const observable = this.api.get<BookDetailInterface>(sprintf(ApiV2EndpointsEnum.LibraryBook, bookUuid));

         observable.subscribe({
             next: (data) => {
                 this.#currentBook = data;
             },
             error: (error) => {
                 console.error('Error retrieving book:', error);
             },
         });

         return observable;
     }

     public patchBookIndex(bookUuid: string, data: BookPatchIndexInterface, offset?: number, limit?: number): Observable<BookListInterface> {
         const urlSearchParams: URLSearchParams = new URLSearchParams([
             ['offset', (offset ?? 0).toString()],
             ['limit', (limit ?? -1).toString()],
         ]);

         return this.api.patch<BookListInterface>(sprintf(sprintf(ApiV2EndpointsEnum.LibraryBookPatchIndex, bookUuid)), data, urlSearchParams);
     }

     public retrieveBookSearchResults(bookUuid: string, search: string, offset: number, limit: number): Observable<SearchResultsInterface> {
         const urlSearchParams: URLSearchParams = new URLSearchParams([
             ['query', search],
             ['offset', offset.toString()],
             ['limit', limit.toString()],
         ]);

         return this.api.get<SearchResultsInterface>(sprintf(ApiV2EndpointsEnum.SearchBook, bookUuid), urlSearchParams);
     }

     public retrieveChapters(bookUuid: string): Observable<ChapterProgressInterface[]> {
         return this.api.get<ChapterProgressInterface[]>(sprintf(ApiV2EndpointsEnum.LibraryBookChapters, bookUuid));
     }

     public retrieveChapter(bookUuid: string, chapterUuid: string): Observable<ChapterProgressInterface> {
         return this.api.get<ChapterProgressInterface>(sprintf(ApiV2EndpointsEnum.LibraryBookChapter, bookUuid, chapterUuid));
     }

     public retrieveChapterToc(bookUuid: string, chapterUuid: string): Observable<TableOfContentsInterface> {
         return this.api.get<TableOfContentsInterface>(sprintf(ApiV2EndpointsEnum.LibraryBookChapterToc, bookUuid, chapterUuid));
     }

     public retrieveDocument(bookUuid: string, chapterUuid: string, documentDpsId: string): Observable<DocumentDetailsInterface> {
         return this.api.get<DocumentDetailsInterface>(sprintf(ApiV2EndpointsEnum.LibraryBookChapterDocument, bookUuid, chapterUuid, documentDpsId));
     }

     public retrieveDocumentByAlfrescoId(alfrescoId: string): Observable<DocumentDetailsInterface> {
         return this.api.get<DocumentDetailsInterface>(sprintf(ApiV2EndpointsEnum.LibraryBookChapterDocumentByAlfrescoId, alfrescoId));
     }

     public retrieveDocumentForProgress(bookUuid: string, chapterUuid: string, documentDpsId: string, userId?: number): Observable<AssignmentDocumentDetailsInterface> {
         return this.api.get<AssignmentDocumentDetailsInterface>(sprintf(ApiV2EndpointsEnum.LibraryBookChapterDocumentProgress, bookUuid, chapterUuid, documentDpsId, userId));
     }
}
