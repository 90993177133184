import {createFeatureSelector, createSelector} from '@ngrx/store';
import {StoreFeatureSelectorEnum} from '@store/store.enum';
import {SelectionState} from '@store/selection/selection.reducer';
import {ChapterBookDetailsInterface} from '@shared/interfaces/chapter.interface';
import {GroupInterface} from '@shared/interfaces/group.interface';
import {GroupTypeHelper} from '@store/group/group.helpers';

export class SelectionSelectors {
    static readonly getState = createFeatureSelector<SelectionState>(
        StoreFeatureSelectorEnum.Selection,
    );

    static readonly selectChapter = createSelector(
        SelectionSelectors.getState,
        (state: SelectionState) => state.chapter,
    );

    static readonly selectChapterDocuments = createSelector(
        SelectionSelectors.selectChapter,
        (chapter?: ChapterBookDetailsInterface) => chapter?.documents,
    );

    static readonly selectDocument = createSelector(
        SelectionSelectors.getState,
        (state: SelectionState) => state.document,
    );

    static readonly selectGroup = createSelector(
        SelectionSelectors.getState,
        (state: SelectionState) => state.group,
    );

    static readonly selectGroupStudents = createSelector(
        SelectionSelectors.selectGroup,
        (group?: GroupInterface) => GroupTypeHelper.isTeacherGroup(group) ? group?.students : undefined,
    );

    static readonly selectUser = createSelector(
        SelectionSelectors.getState,
        (state: SelectionState) => state.user,
    );
}
