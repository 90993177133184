import {Store} from '@ngrx/store';
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {MessageService} from '@core/services/message/message.service';
import {MessageActionsEnum} from '@store/message/message.enum';
import {MarkMessagePropertiesInterface, MessageActions, MessagePropertiesInterface} from '@store/message/message.actions';
import {PaginationInterface} from '@core/interfaces/pagination.interface';
import {of} from 'rxjs';

@Injectable()
export class MessageEffects {
    #store: Store;

    #messageService: MessageService;

    public constructor(private actions$: Actions, store: Store, messageService: MessageService) {
        this.#store = store;
        this.#messageService = messageService;
    }

    readonly fetchMessages$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(MessageActionsEnum.fetchMessages),
            switchMap((properties: PaginationInterface) => this.#messageService.list(properties.offset, properties.limit).pipe(
                map(messages => MessageActions.fetchMessagesSuccess(messages)),
                catchError(error => of(MessageActions.fetchMessagesFailed({error}))),
            )),
        )
    });

    readonly markMessage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(MessageActionsEnum.markMessage),
            switchMap((properties: MarkMessagePropertiesInterface) => this.#messageService.mark(properties).pipe(
                map(message => MessageActions.markMessageSuccess(message)),
                catchError(error => of(MessageActions.markMessageFailed({error}))),
            )),
        )
    });

    readonly deleteMessage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(MessageActionsEnum.deleteMessage),
            switchMap((properties: MessagePropertiesInterface) => this.#messageService.delete(properties).pipe(
                map(messages => MessageActions.deleteMessageSuccess(messages)),
                catchError(error => of(MessageActions.deleteMessageFailed({error}))),
            )),
        )
    });
}
