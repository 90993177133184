import {createAction, props} from '@ngrx/store';
import {GeneralActionsEnum} from '@store/general/general.enum';
import {HttpErrorResponseInterface} from '@core/interfaces/http-error-response.interface';
import {PublisherInterface} from '@shared/interfaces/publisher.interface';
import {UserSettingInterface} from '@shared/interfaces/user.interface';

export class GeneralActions {
    public static readonly toggleNavigationSecondExpanded = createAction(GeneralActionsEnum.ToggleNavigationSecondExpanded, props<{ value: boolean }>());

    public static readonly clickTableOfContentsItem = createAction(GeneralActionsEnum.ClickTableOfContentsItem, props<{ dpsId: string }>());

    public static readonly fetchPublisher = createAction(GeneralActionsEnum.FetchPublisher);

    public static readonly fetchPublisherSuccess = createAction(GeneralActionsEnum.FetchPublisherSuccess, props<PublisherInterface>());

    public static readonly fetchPublisherFailed = createAction(GeneralActionsEnum.FetchPublisherFailed, props<HttpErrorResponseInterface>());

    public static readonly patchUserSettings = createAction(GeneralActionsEnum.PatchUserSettings, props<{ setting: UserSettingInterface<any> }>());

    public static readonly patchUserSettingsFailed = createAction(GeneralActionsEnum.PatchUserSettingsFailed, props<HttpErrorResponseInterface>());
}
