import {createFeatureSelector, createSelector} from '@ngrx/store';
import {StoreFeatureSelectorEnum} from '@store/store.enum';
import {BreadcrumbState} from '@store/breadcrumb/breadcrumb.reducer';

export const getBreadcrumbState = createFeatureSelector<BreadcrumbState>(
    StoreFeatureSelectorEnum.Breadcrumb,
);

export const selectBreadcrumbBreadcrumb = createSelector(
    getBreadcrumbState,
    (state: BreadcrumbState) => state,
);
