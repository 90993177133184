import {createAction, props} from '@ngrx/store';
import {ExamActionsEnum} from '@store/exam/exam.enum';
import {ExamInterface} from '@shared/modules/content/services/exam/exam.service';
import {HttpErrorResponseInterface} from '@core/interfaces/http-error-response.interface';

export interface ExamPropertiesInterface {
    bookUuid: string;
    chapterUuid: string;
    examUuid: string;
}

export interface ExamActionsFetchExamsInterface {
    bookUuid: string;
    types?: number[];
}

export interface ExamActionsFetchExamsFailedInterface extends ExamActionsFetchExamsInterface {
    error: HttpErrorResponseInterface;
}

export class ExamActions {
    // Deprecated remindo exams
    static readonly fetchStartRemindo = createAction(ExamActionsEnum.FetchStartRemindo, props<ExamPropertiesInterface>());

    static readonly fetchStartRemindoSuccess = createAction(ExamActionsEnum.FetchStartRemindoSuccess, props<{ exam?: ExamInterface }>());

    static readonly unsetExamAction = createAction(ExamActionsEnum.UnsetExam);
}
