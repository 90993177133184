import {Action, createReducer, on} from '@ngrx/store';
import {activateBookLicensesActionFailed, activateBookLicensesActionSuccess} from '@store/license/license.actions';
import {HttpErrorResponseInterface} from '@core/interfaces/http-error-response.interface';

export interface LicenseState {
    activatedBookLicenses?: LicenseInterface[],
    activatedBookLicensesError?: HttpErrorResponseInterface,
    licenses?: LicenseInterface[];
}

export interface LicenseInterface {
    ean: string;
    status: boolean;
    isTeacherEan: boolean;
}

export const initialState: LicenseState = {};

export const reducer = createReducer(
    initialState,
    on(activateBookLicensesActionSuccess, (state, {licenses}) => ({...state, licenses})),
    on(activateBookLicensesActionFailed, (state, {error}) => ({...state, activatedBookLicensesError: error})),
);

export const licenseReducer = (
    state: LicenseState | undefined,
    action: Action,
): LicenseState => {
    return reducer(state, action);
};
