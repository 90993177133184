import {ApiService} from '@core/services/api/api.service';
import {Observable} from 'rxjs';
import {ApiV2EndpointsEnum} from '@core/enums/api-v2-endpoints.enum';
import {UserInterface} from '@shared/interfaces/user.interface';
import {Injectable} from '@angular/core';
import {sprintf} from '@core/core.functions';
import {ApiV1EndpointsEnum} from '@core/enums/api-v1-endpoints.enum';
import {ChapterInterface} from '@shared/interfaces/chapter.interface';
import {DocumentInterface} from '@shared/interfaces/document.interface';
import {SourcesOrderByInterface} from '@shared/interfaces/sources-order-by.interface';

export interface SourceTotalsByTypeInterface {
    [type: string]: number;
}

export interface SourceInterface {
    id: number;
    identifier: string;
    title: string;
    type: string;
    extension: string | null;
    path: string | null;
    teacherOnly: boolean;
    childCount: number | null;
    chapter: ChapterInterface | null;
    parent: DocumentInterface | null;
    owner: UserInterface | null;
}

export interface SourceDataInterface {
    sources: SourceInterface[];
    total: number;
}

export interface DocumentSourceDataInterface extends SourceDataInterface {
    totalsByType: SourceTotalsByTypeInterface;
}

export interface TeacherSourceDataInterface extends SourceDataInterface {
    folders: SourceInterface[];
}

export interface SourceDeleteInterface {
    id: number;
}

export interface FileInterface {
    id: number;
    name: string;
    mimetype: string;
    extension: string;
    created: number;
    updated: number;
    size: number;
    owner?: UserInterface;
}

export interface FileResponseInterface {
    code: number;
    data: FileInterface;
    msg: string | null;
}

@Injectable({
    providedIn: 'root',
})
export class SourceService {
    public constructor(private api: ApiService) {
    }

    public retrieveList(
        bookUuid: string,
        chapterUuids?: string[],
        documentDpsId?: string,
        offset?: number,
        limit?: number,
        extensions?: string[],
        folderDocumentId?: number,
        types?: string[],
        orderBy?: SourcesOrderByInterface,
    ): Observable<DocumentSourceDataInterface> {
        const urlSearchParams: URLSearchParams = new URLSearchParams();

        if (undefined !== chapterUuids) {
            chapterUuids.forEach(chapterUuid => urlSearchParams.has('chapterUuids[]') ? urlSearchParams.append('chapterUuids[]', chapterUuid) : urlSearchParams.set('chapterUuids[]', chapterUuid));
        }

        if (undefined !== documentDpsId) {
            urlSearchParams.set('documentDpsId', documentDpsId);
        }

        if (undefined !== offset && undefined !== limit) {
            urlSearchParams.set('offset', offset.toString());
            urlSearchParams.set('limit', limit.toString());
        }

        if (undefined !== orderBy) {
            urlSearchParams.set('orderby', JSON.stringify(orderBy));
        }

        if (undefined !== extensions) {
            extensions.forEach(extension => urlSearchParams.has('extensions[]') ? urlSearchParams.append('extensions[]', extension) : urlSearchParams.set('extensions[]', extension));
        }

        if (undefined !== types) {
            types.forEach(type => urlSearchParams.has('types[]') ? urlSearchParams.append('types[]', type) : urlSearchParams.set('types[]', type));
        }

        if (undefined !== folderDocumentId) {
            urlSearchParams.set('folderDocumentId', folderDocumentId.toString());
        }

        return this.api.get<DocumentSourceDataInterface>(
            sprintf(ApiV2EndpointsEnum.DocumentSources, bookUuid),
            0 !== Array.from(urlSearchParams.keys()).length ? urlSearchParams : undefined,
        );
    }

    public retrieveListTeacherMaterial(bookUuid: string, chapterUuids?: string[], documentDpsId?: string, offset?: number, limit?: number, fileSelection?: string[], folderDocumentId?: number): Observable<TeacherSourceDataInterface> {
        const urlSearchParams: URLSearchParams = new URLSearchParams();

        if (undefined !== chapterUuids) {
            chapterUuids.forEach(chapterUuid => urlSearchParams.has('chapterUuids[]') ? urlSearchParams.append('chapterUuids[]', chapterUuid) : urlSearchParams.set('chapterUuids[]', chapterUuid));
        }

        if (undefined !== documentDpsId) {
            urlSearchParams.set('documentDpsId', documentDpsId);
        }

        if (undefined !== offset && undefined !== limit) {
            urlSearchParams.set('offset', offset.toString());
            urlSearchParams.set('limit', limit.toString());
        }

        if (undefined !== fileSelection) {
            fileSelection.forEach(type => urlSearchParams.has('mimeTypes[]') ? urlSearchParams.append('mimeTypes[]', type) : urlSearchParams.set('mimeTypes[]', type));
        }

        if (undefined !== folderDocumentId) {
            urlSearchParams.set('folderDocumentId', folderDocumentId.toString());
        }

        return this.api.get<TeacherSourceDataInterface>(
            sprintf(ApiV2EndpointsEnum.DocumentSourcesTeacherMaterial, bookUuid),
            0 !== Array.from(urlSearchParams.keys()).length ? urlSearchParams : undefined,
        );
    }

    public addSource(bookUuid: string, fileId:number, file: File, link: string, title: string, teacherOnly: boolean, chapterUuid: string, documentDpsId: string): Observable<SourceInterface[]> {
        return this.api.post<SourceInterface[]>(sprintf(ApiV2EndpointsEnum.DocumentSourceCreate, bookUuid), {fileId, file, link, title, teacherOnly, chapterUuid, documentDpsId});
    }

    public removeSource(bookUuid:string, id:number): Observable<SourceDeleteInterface> {
        const urlSearchParams: URLSearchParams = new URLSearchParams();

        if (undefined !== id) {
            urlSearchParams.set('id', id.toString());
        }

        return this.api.delete<SourceDeleteInterface>(sprintf(ApiV2EndpointsEnum.DocumentSourceRemove, bookUuid),
            0 !== Array.from(urlSearchParams.keys()).length ? urlSearchParams : undefined,
        );
    }

    public addSourceFile(file: File): Observable<FileResponseInterface> {
        const formData: FormData = new FormData();

        formData.append('file', file, file.name);

        return this.api.post<FileResponseInterface>(ApiV1EndpointsEnum.FileAdd, formData);
    }
}
