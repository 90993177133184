import {Component, HostBinding, Input} from '@angular/core';

/**
 * @deprecated will be removed in future versions
 */
@Component({
    selector: 'app-status-dot',
    templateUrl: './status-dot.component.html',
    styleUrls: ['./status-dot.component.scss'],
})
export class StatusDotComponent {
    @HostBinding('class')
    public classes: string[] = ['status-dot'];

    @Input()
    public cursorPointer: boolean = true;
}
