import {Injectable} from '@angular/core';
import {ExtensionEnum} from '@shared/modules/content/modules/enums/extension.enum';

@Injectable()
export class ExtensionHelper {
    public resolve(extension: string): string {
        const extensions: ExtensionEnum[] = [
            ExtensionEnum.Avi,
            ExtensionEnum.Ds,
            ExtensionEnum.Aac,
            ExtensionEnum.Ai,
            ExtensionEnum.Bmp,
            ExtensionEnum.Cad,
            ExtensionEnum.Cdr,
            ExtensionEnum.Css,
            ExtensionEnum.Dat,
            ExtensionEnum.Dll,
            ExtensionEnum.Dmg,
            ExtensionEnum.Doc,
            ExtensionEnum.Eps,
            ExtensionEnum.Fla,
            ExtensionEnum.Flv,
            ExtensionEnum.Gif,
            ExtensionEnum.Html,
            ExtensionEnum.Indd,
            ExtensionEnum.Iso,
            ExtensionEnum.Jpg,
            ExtensionEnum.Js,
            ExtensionEnum.Midi,
            ExtensionEnum.Mov,
            ExtensionEnum.Mps,
            ExtensionEnum.Mpg,
            ExtensionEnum.Pdf,
            ExtensionEnum.Php,
            ExtensionEnum.Png,
            ExtensionEnum.Ppt,
            ExtensionEnum.Ps,
            ExtensionEnum.Psd,
            ExtensionEnum.Raw,
            ExtensionEnum.Sql,
            ExtensionEnum.Svg,
            ExtensionEnum.Tif,
            ExtensionEnum.Txt,
            ExtensionEnum.Wmv,
            ExtensionEnum.Xls,
            ExtensionEnum.Xml,
            ExtensionEnum.Zip,
        ];

        for (let type: number = 0; type < extensions.length; type++) {
            if (extension.toLowerCase() === extensions[type]) {
                return extension
            }
        }

        return this.resolveMapped(extension);
    }

    private resolveMapped(extension: string): string {
        switch (extension) {
            case ExtensionEnum.Xlsx:
                return ExtensionEnum.Xls;

            case ExtensionEnum.Docx:
                return ExtensionEnum.Doc;

            case ExtensionEnum.Pptx:
                return ExtensionEnum.Ppt;

            case ExtensionEnum.Jpeg:
                return ExtensionEnum.Jpg;

            default:
                return 'file';
        }
    }
}
