import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ButtonTypeEnum} from '@shared/button/button.directive';
import {ActionsSubject, Store} from '@ngrx/store';
import {UserInterface} from '@shared/interfaces/user.interface';
import {Subscription} from 'rxjs';
import {HttpErrorResponseInterface} from '@core/interfaces/http-error-response.interface';
import {OauthService} from '@core/security/services/oauth.service';
import {RoleEnum} from '@core/security/enum/role.enum';
import {AuthorizationService} from '@core/security/services/authorization.service';
import {SecurityActions, SecuritySelectors, updateOrganizationAction} from '@store/security';
import {OrganizationActions, OrganizationSelectors} from '@store/organization';
import {ofType} from '@ngrx/effects';
import {OrganizationInterface} from '@shared/interfaces/organization.interface';
import {SingleSelectInterface} from '@shared/interfaces/single-select.interface';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';

enum OrganizationFormFields {
    createOrganization = 'createOrganization',
}

@Component({
    selector: 'app-organization-dialog',
    templateUrl: './organization-dialog.component.html',
    styleUrls: ['./organization-dialog.component.scss'],
})
export class DialogOrganizationComponent implements OnInit, OnDestroy {
    public organizationForm: FormGroup;

    public readonly buttonType = ButtonTypeEnum;

    public readonly organizationFields = OrganizationFormFields;

    public readonly roleEnum = RoleEnum;

    public submit: boolean = false;

    public profile?: UserInterface;

    public isRoleHO: boolean;

    public toggleView: boolean = false;

    public toggleViewSecond: boolean = false;

    public list: SingleSelectInterface[] = [];

    public organizations?: OrganizationInterface[];

    public organizationId?: number;

    public organizationName?: string;

    public organizationNameBackup?: string;

    public organizationExists: boolean = false;

    public newOrganization: boolean = false;

    public organizationPending: boolean = false;

    private subscriptions: Subscription[] = [];

    public constructor(
        private dialogRef: MatDialogRef<DialogOrganizationComponent>,
        @Inject(MAT_DIALOG_DATA) public dialogData: any,
        public store: Store,
        actionsSubject: ActionsSubject,
        private oauthService: OauthService,
        private authorizationService: AuthorizationService,
        private matSnackBar: MatSnackBar,
        private translateService: TranslateService,
        private formBuilder: FormBuilder,
    ) {
        this.subscriptions.push(
            store.select(SecuritySelectors.selectCurrentUser).subscribe(user => this.handleUserProfile(user)),
            store.select(OrganizationSelectors.selectOrganizations).subscribe(organizations => this.handleSelectOrganizations(organizations)),
            actionsSubject.pipe(ofType(SecurityActions.updateOrganizationActionSuccess)).subscribe(() => this.handleUpdateOrganizationSuccess()),
            actionsSubject.pipe(ofType(OrganizationActions.requestOrganizationSuccess)).subscribe(() => this.organizationSuccess()),
            actionsSubject.pipe(ofType(OrganizationActions.requestOrganizationFailed)).subscribe(() => this.organizationFailed()),
            actionsSubject.pipe(ofType(SecurityActions.updateOrganizationActionFailed)).subscribe(error => this.handleHttpErrorResponse(error)),
        );

        this.organizationForm = this.formBuilder.group({
            [OrganizationFormFields.createOrganization]: new FormControl(null),
        });

        this.isRoleHO = this.authorizationService.isGranted(RoleEnum.RoleOrganizationHo);
    }

    public ngOnInit(): void {
        this.store.dispatch(OrganizationActions.fetchOrganizations());
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    public interactSingleSelect(singleSelect: any): void {
        this.organizationId = singleSelect.action;
    }

    public onCancel(): void {
        this.organizationName = undefined;
        this.organizationNameBackup = undefined;

        this.dialogRef.close(false);
    }

    public onSubmit(): void {
        if (this.organizationId) {
            this.store.dispatch(updateOrganizationAction({organizationId: this.organizationId.toString()}));

            return;
        }

        if (this.organizationName && this.organizationName?.length <= 1) {
            this.setErrorMessage('organization.dialog.validation.notValidOrganization');

            return;
        }

        if (!this.organizationName?.match(/^[a-zA-Z0-9 ]*$/)) {
            this.setErrorMessage('organization.dialog.validation.incorrectFormatOrganization');

            return;
        }

        if (this.organizationName) {

            this.organizationNameBackup = this.organizationName;

            this.organizations?.forEach(organization => {
                if (organization.name === this.organizationName) {
                    this.organizationExists = true;
                    this.organizationId = organization.id;
                }
            });

            if (!this.organizationExists) {
                this.store.dispatch(OrganizationActions.requestOrganization({name: this.organizationName}));
            }
        }
    }

    public organizationSuccess(): void {
        this.organizationExists = false;
        this.newOrganization = true;
        this.successOrganizationMessage();
    }

    public onCloseRequest(): void {
        this.dialogRef.close(true);
    }

    public organizationFailed(): void {
        this.organizationPending = true;
    }

    private setErrorMessage(error: string): void {
        const translatedError: string = this.translateService.instant(error);

        this.organizationForm.get(OrganizationFormFields.createOrganization)?.setErrors([translatedError]);
    }

    private handleSelectOrganizations(organizations?: OrganizationInterface[]): void {
        if (undefined === organizations) {
            return;
        }

        this.organizations = organizations;

        this.organizations.forEach(organization => {
            this.list.push({title: organization.name, action: organization.id.toString()});
        });
    }

    private handleUpdateOrganizationSuccess(): void {
        this.successMessage();
        this.dialogRef.close(true);
    }

    private handleHttpErrorResponse(apiErrorResponse?: HttpErrorResponseInterface): void {
        if (undefined === apiErrorResponse) {
            return;
        }
    }

    private successMessage(): void {
        this.matSnackBar.open(this.translateService.instant('organization.dialog.validation.serverSuccess'), 'success', {
            duration: 3000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
        });
    }

    private successOrganizationMessage(): void {
        this.matSnackBar.open(this.translateService.instant('organization.dialog.validation.serverOrganizationSuccess'), 'success', {
            duration: 3000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
        });
    }

    private handleUserProfile(user?: UserInterface): void {
        if (undefined === user) {
            return;
        }

        this.profile = user;
    }

    private toggleLayout(): void {
        this.organizationForm.reset();
        this.toggleView = !this.toggleView;
        this.organizationPending = false;
        this.organizationExists = false;
    }

    public handleInput(inputValue: string): void {
        this.organizationName = inputValue;
        this.organizationId = undefined;
        this.organizationExists = false;
    }
}
