import {Component, OnInit} from '@angular/core';
import {SearchResultProgressInterface} from '@shared/interfaces/search.interface';
import {AbstractSearchResultComponent} from '@core/search/contract/search-component.contract';

@Component({
    selector: 'app-search-result',
    templateUrl: './search-result.component.html',
    styleUrls: ['./search-result.component.scss'],
})
export class SearchResultComponent extends AbstractSearchResultComponent implements OnInit {
    public data!: SearchResultProgressInterface;

    public title!: string;

    public setData(data: SearchResultProgressInterface): void {
        this.data = data;
    }

    public ngOnInit(): void {
        this.title = this.data.highlights.title;
    }
}
