import {Pipe, PipeTransform} from '@angular/core';
import {RoleEnum} from '@core/security/enum/role.enum';
import {AuthorizationService} from '@core/security/services/authorization.service';
import {UserAuthenticatedInterface} from '@shared/interfaces/user.interface';

@Pipe({
    name: 'isGranted',
})
export class IsGrantedPipe implements PipeTransform {
    public constructor(private authorizationService: AuthorizationService) {
    }

    public transform(user: UserAuthenticatedInterface, role: RoleEnum) {
        return this.authorizationService.isGranted(role, user);
    }
}
