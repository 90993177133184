import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LanguageDropdownComponent} from './language-dropdown.component';
import {TranslateModule} from '@ngx-translate/core';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {SharedModule} from '@shared/shared.module';

@NgModule({
    declarations: [
        LanguageDropdownComponent,
    ],
    imports: [
        CommonModule,
        TranslateModule,
        MatMenuModule,
        MatButtonModule,
        SharedModule,
    ],
    exports: [
        LanguageDropdownComponent,
    ],
})
export class LanguageDropdownModule {
}
