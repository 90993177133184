import {inject, Injectable} from '@angular/core';
import {MercureService} from '@core/mercure/mercure.service';
import {UserAuthenticatedInterface} from '@shared/interfaces/user.interface';
import {Store} from '@ngrx/store';
import {MessageActions} from '@store/message';
import {MatDialog} from '@angular/material/dialog';
import {MessageDialogComponent} from '@/component/messages/message-dialog/message-dialog.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {MessagesNewExamAssignComponent} from '@component/messages/messages-new-exam-assign/messages-new-exam-assign.component';
import {MessagesNewFeedbackComponent} from '@component/messages/messages-new-feedback/messages-new-feedback.component';

enum MessageEventType {
    Push = 'push',
    Notification = 'notification',
    NewExam = 'new_exam',
    NewFeedback = 'new_feedback',
}

interface MessageEventInterface<DataType = any> {
    content: DataType;
    type: string;
}

@Injectable({
    providedIn: 'root',
})
export class MessageService {
    readonly #mercureService: MercureService = inject(MercureService);

    readonly #snackBar: MatSnackBar = inject(MatSnackBar);

    readonly #store: Store = inject(Store);

    readonly #matDialog: MatDialog = inject(MatDialog);

    readonly #translator: TranslateService = inject(TranslateService);

    load(currentUser: UserAuthenticatedInterface): void {
        this.#mercureService.subscribeToMessage<MessageEventInterface>(currentUser.mercure, event => this.#handleMessageEvent(event));
        this.#fetchMessages();
    }

    #handleMessageEvent(event: MessageEvent): void {
        const eventData: any | MessageEventInterface = JSON.parse(event.data);

        if (!this.#isMessageEvent(eventData)) {
            console.warn('Received non-message', eventData);

            return;
        }

        switch (eventData.type) {
            case MessageEventType.Push:
                this.#matDialog.open(MessageDialogComponent, {
                    panelClass: 'custom-dialog-container',
                    data: {message: eventData.content},
                });
                break;

            case MessageEventType.Notification:
                this.#snackBar.open(
                    eventData.content.description,
                    this.#translator.instant('general.close'),
                    {
                        panelClass: ['white-snackbar'],
                    },
                );
                break;

            case MessageEventType.NewExam:
                const title = this.#translator.instant('exam.snackbar.newExam', {exam: eventData.content.title});

                this.#snackBar.openFromComponent(MessagesNewExamAssignComponent, {
                    duration: 6000,
                    horizontalPosition: 'right',
                    verticalPosition: 'top',
                    panelClass: ['custom-snackbar'],
                    data: {title},
                });

                break;

            case MessageEventType.NewFeedback:
                this.#snackBar.openFromComponent(MessagesNewFeedbackComponent, {
                    duration: 6000,
                    horizontalPosition: 'right',
                    verticalPosition: 'top',
                    panelClass: ['custom-snackbar'],
                    data: {title: this.#translator.instant(eventData.content.title)},
                });

                break;

            default:
                console.warn('Received unsupported message type', event.data);
        }

        this.#fetchMessages();
    }

    #isMessageEvent(event: any): event is MessageEventInterface {
        return event.hasOwnProperty('content') && event.hasOwnProperty('type');
    }

    #fetchMessages(): void {
        this.#store.dispatch(MessageActions.fetchMessages({offset: 0, limit: 15}));
    }

    showSuccessMessage(message: string): void {
        this.#snackBar.open(this.#translator.instant(message), '', {
            duration: 6000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: ['green-snackbar'],
        });
    }

    showFailedMessage(message: string): void {
        this.#snackBar.open(this.#translator.instant(message), '', {
            duration: 6000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: ['red-snackbar'],
        });
    }
}
