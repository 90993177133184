import {AfterViewInit, Component, HostBinding, Input, OnChanges, SimpleChanges} from '@angular/core';
import {DomSanitizer, SafeStyle} from '@angular/platform-browser';

@Component({
    selector: 'app-card-deprecated',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss'],
})
/** @deprecated Card component is too generic **/
export class CardComponent implements AfterViewInit, OnChanges {
    @HostBinding('class')
    classes: string[] = ['card']

    @HostBinding('style.--background')
    backGroundImage?: SafeStyle;

    @Input()
    image: string | null = null;

    @Input()
    imageWidth: string = '100%';

    @Input()
    imageHeight: string = '100%';

    @Input()
    imageAlignment: string = 'center';

    @Input()
    imageToggle: boolean = true;

    constructor(private sanitizer: DomSanitizer) {
    }

    ngAfterViewInit() {
        this.#setBackgroundImage();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.image) {
            this.#setBackgroundImage();
        }
    }

    #setBackgroundImage(): void {
        if (!this.image) {
            return;
        }

        this.backGroundImage = this.sanitizer.bypassSecurityTrustStyle(`url("${this.image ?? undefined}")`);
    }
}
