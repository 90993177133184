import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '@core/services/api/api.service';
import {PublisherInterface} from '@shared/interfaces/publisher.interface';
import {ApiV2EndpointsEnum} from '@core/enums/api-v2-endpoints.enum';
import {UserAuthenticatedInterface, UserSettingInterface} from '@shared/interfaces/user.interface';
import {sprintf} from '@core/core.functions';

@Injectable({
    providedIn: 'root',
})
export class GeneralService {
    public constructor(private api: ApiService) {
    }

    public retrievePublisher(): Observable<PublisherInterface> {
        return this.api.get(ApiV2EndpointsEnum.GeneralPublisher);
    }

    public patchUserSetting(setting: UserSettingInterface<any>): Observable<UserAuthenticatedInterface> {
        return this.api.patch(sprintf(ApiV2EndpointsEnum.GeneralPatchUserSetting, setting.type), setting.data);
    }
}
