import {Injectable} from '@angular/core';
import {HttpClient, HttpContext, HttpHeaders, HttpParams} from '@angular/common/http';
import {StringService} from '@core/services/string/string.service';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {HttpMethodsEnum} from '@core/http/methods.enum';

interface HttpOptions {
    headers?: HttpHeaders;
    context?: HttpContext;
    observe?: 'body';
    params?: HttpParams;
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class ApiService {
    public constructor(
        private httpClient: HttpClient,
        private stringService: StringService,
    ) {
    }

    head<T>(
        url: string,
        queryParams?: URLSearchParams,
        urlProperties?: Map<string, string>,
        options?: any,
    ): Observable<T> {
        return this.httpClient.head<T>(this.buildUrl(url, urlProperties), this.#getOptions(queryParams, options)).pipe(take(1));
    }

    options<T>(
        url: string,
        queryParams?: URLSearchParams,
        urlProperties?: Map<string, string>,
        options?: any,
    ): Observable<T> {
        return this.httpClient.get<T>(this.buildUrl(url, urlProperties), this.#getOptions(queryParams, options)).pipe(take(1));
    }

    request<T>(
        mode: HttpMethodsEnum,
        url: string,
        body?: any | null,
        queryParams?: URLSearchParams,
        urlProperties?: Map<string, string>,
        options?: any,
    ): Observable<T> {
        switch (mode) {
            case HttpMethodsEnum.Post:
                return this.post<T>(url, body, queryParams, urlProperties, options);

            case HttpMethodsEnum.Put:
                return this.put<T>(url, body, queryParams, urlProperties, options);

            case HttpMethodsEnum.Get:
                return this.get<T>(url, queryParams, urlProperties, options);

            default:
                throw Error(`Mode(${mode}) is not (yet) supported`);
        }
    }

    get<T>(
        url: string,
        queryParams?: URLSearchParams,
        urlProperties?: Map<string, string>,
        options?: any,
    ): Observable<T> {
        return this.httpClient.get<T>(this.buildUrl(url, urlProperties), this.#getOptions(queryParams, options)).pipe(take(1));
    }

    download<T>(
        url: string,
        queryParams?: URLSearchParams,
        urlProperties?: Map<string, string>,
        options?: any,
    ): Observable<T> {
        return this.httpClient.get<T>(this.buildUrl(url, urlProperties), this.#getOptions(queryParams, options)).pipe(take(1));
    }

    post<T>(
        url: string,
        body?: any | null,
        queryParams?: URLSearchParams,
        urlProperties?: Map<string, string>,
        options?: any,
    ): Observable<T> {
        return this.httpClient.post<T>(this.buildUrl(url, urlProperties), body, this.#getOptions(queryParams, options)).pipe(take(1));
    }

    put<T>(
        url: string,
        body?: any | null,
        queryParams?: URLSearchParams,
        urlProperties?: Map<string, string>,
        options?: any,
    ): Observable<T> {
        return this.httpClient.put<T>(this.buildUrl(url, urlProperties), body, this.#getOptions(queryParams, options)).pipe(take(1));
    }

    patch<T>(
        url: string,
        body?: any | null,
        queryParams?: URLSearchParams,
        urlProperties?: Map<string, string>,
        options?: any,
    ): Observable<T> {
        return this.httpClient.patch<T>(this.buildUrl(url, urlProperties), body, this.#getOptions(queryParams, options)).pipe(take(1));
    }

    delete<T>(
        url: string,
        queryParams?: URLSearchParams,
        urlProperties?: Map<string, string>,
        options?: any,
    ): Observable<T> {
        return this.httpClient.delete<T>(this.buildUrl(url, urlProperties), this.#getOptions(queryParams, options)).pipe(take(1));
    }

    uploadFile<T>(
        file: File,
        url: string,
        queryParams?: URLSearchParams,
        urlProperties?: Map<string, string>,
        options?: any,
    ): Observable<T> {
        const formData: FormData = new FormData();

        formData.append('file', file, file.name);

        return this.httpClient.post<T>(this.buildUrl(url, urlProperties), formData, this.#getOptions(queryParams, options)).pipe(take(1));
    }

    buildUrl(url: string, urlProperties?: Map<string, string>): string {
        return this.stringService.getMappedString(url, urlProperties);
    }

    #getOptions(queryParams?: URLSearchParams, options?: any): HttpOptions {
        let defaultOptions: HttpOptions = {
            withCredentials: true,
            headers: new HttpHeaders({
                'Accept': 'application/json',
            }),
        };

        if (options !== undefined) {
            defaultOptions = {...defaultOptions, ...options};
        }

        if (queryParams instanceof URLSearchParams) {
            let httpParams = new HttpParams();

            for (const [key, value] of queryParams.entries()) {
                if ('undefined' === value) {
                    continue;
                }

                httpParams = httpParams.append(key, value);
            }

            defaultOptions.params = httpParams;
        }

        return defaultOptions;
    }
}
