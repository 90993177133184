import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';

@Directive({
    selector: '[appTemplateVar]',
})
export class TemplateVarDirective {
    @Input()
    public set appTemplateVar(context: any) {
        this.context.$implicit = this.context.appTemplateVar = context;

        this.updateView();
    }

    protected context: any = {};

    public constructor(private viewContainerRef: ViewContainerRef, private templateRef: TemplateRef<any>) {
    }

    private updateView(): void {
        this.viewContainerRef.clear();
        this.viewContainerRef.createEmbeddedView(this.templateRef, this.context);
    }
}
