import {Store} from '@ngrx/store';
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {ProfileService} from '@core/services/profile/profile.service';
import {ProfileActionsEnum} from '@store/profile/profile.enum';
import {UserInterface} from '@shared/interfaces/user.interface';
import {ProfileActions} from '@store/profile/profile.actions';

@Injectable()
export class ProfileEffects {
    public constructor(
        private store: Store,
        private actions$: Actions,
        private profileService: ProfileService,
    ) {
    }

    public $updateProfileAction = createEffect(() => {
        return this.actions$.pipe(
            ofType(ProfileActionsEnum.updateProfile),
            switchMap((props: { user: UserInterface, firstName: string, middleName: string, lastName: string, email: string, avatar: File }) => {
                return this.profileService.updateProfile(props.firstName, props.middleName, props.lastName, props.email, props.avatar).pipe(
                    map(() => ProfileActions.updateProfileActionSuccess({user: props.user})),
                    catchError(error => of(ProfileActions.updateProfileActionFailed({error}))),
                );
            }),
        );
    });

    public $updateProfileAvatarAction = createEffect(() => {
        return this.actions$.pipe(
            ofType(ProfileActionsEnum.updateProfileAvatar),
            switchMap((props: {user: UserInterface, avatar: File }) => {
                return this.profileService.updateProfileAvatar(props.avatar).pipe(
                    map(() => ProfileActions.updateProfileActionSuccess({user: props.user})),
                    catchError(error => of(ProfileActions.updateProfileActionFailed({error}))),
                );
            }),
        );
    });

    public $deleteProfileAvatarAction = createEffect(() => {
        return this.actions$.pipe(
            ofType(ProfileActionsEnum.deleteProfileAvatar),
            switchMap((props: {user: UserInterface }) => {
                return this.profileService.deleteProfileAvatar().pipe(
                    map(() => ProfileActions.updateProfileActionSuccess({user: props.user})),
                    catchError(error => of(ProfileActions.updateProfileActionFailed({error}))),
                );
            }),
        );
    });
}
