import {RoleEnum} from '@core/security/enum/role.enum';
import {environment} from '@environment/environment';

/**
 * RolesMapping allows us to apply role inheritance
 */
export const RolesMapping = new Map<RoleEnum, RoleEnum[]>([
    [
        RoleEnum.RoleTest,
        [
        ],
    ],
    [
        RoleEnum.RoleAcceptance,
        [
        ],
    ],
    [
        RoleEnum.RoleDevelopment,
        [
            RoleEnum.RoleTest,
            RoleEnum.RoleAcceptance,
            RoleEnum.RoleProduction,
        ],
    ],
    [
        RoleEnum.RoleUser,
        [
            environment.role,
        ],
    ],
    [
        RoleEnum.RoleStudent,
        [
            RoleEnum.RoleUser,
        ],
    ],
    [
        RoleEnum.RoleTeacher,
        [
            RoleEnum.RoleUser,
        ],
    ],
    [
        RoleEnum.RoleOrganizationHo,
        [
            RoleEnum.RoleBuyLicence,
        ],
    ],
    [
        RoleEnum.RoleOrganizationVo,
        [
            RoleEnum.RoleExams,
        ],
    ],
    [
        RoleEnum.RoleOrganizationBo,
        [
            RoleEnum.RoleOrganizationVo,
        ],
    ],
    [
        RoleEnum.RoleOrganization,
        [
            RoleEnum.RoleOrganizationHo,
            RoleEnum.RoleOrganizationVo,
            RoleEnum.RoleOrganizationBo,
        ],
    ],
]);
