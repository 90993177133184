import {Injectable, OnDestroy} from '@angular/core';
import {Store} from '@ngrx/store';
import {OperatorFunction, Subscription} from 'rxjs';
import {RoleEnum} from '@core/security/enum/role.enum';
import {SecuritySelectors} from '@store/security';
import {RolesMapping} from '@core/security/mapping/roles.mapping';
import {UserAuthenticatedInterface, UserInterface} from '@shared/interfaces/user.interface';
import {filter} from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class AuthorizationService implements OnDestroy {
    #subscriptions: Subscription[] = [];

    #currentUser!: UserAuthenticatedInterface;

    constructor(private store: Store) {
        this.#subscriptions.push(
            this.store.select(SecuritySelectors.selectCurrentUser).pipe(
                filter((user?: UserAuthenticatedInterface) => undefined !== user) as OperatorFunction<undefined | UserAuthenticatedInterface, UserAuthenticatedInterface>,
            ).subscribe((user: UserAuthenticatedInterface) => this.#currentUser = user),
        );
    }

    ngOnDestroy(): void {
        this.#subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    }

    isEveryGranted(roles: string[]): boolean {
        return roles.every(role => this.isGranted(role));
    }

    isGranted(role: string, user?: UserAuthenticatedInterface): boolean {
        const userModel: UserAuthenticatedInterface | undefined = user ?? this.#currentUser;
        const isGranted: boolean = undefined !== userModel && userModel.roles.some(userRole => this.#isRoleGranted(role, userRole));

        return isGranted;
    }

    isCurrentUser(user: UserInterface): boolean {
        return user.id === this.#currentUser.id;
    }

    #isRoleGranted(role: string, userRole: RoleEnum): boolean {
        const grantedRoles: string[] = this.#getGrantedRoles(userRole);

        return role === userRole || grantedRoles.includes(role);
    }

    /**
     * Roles inherit roles, because of this we'll have to generate an array the role, and it's inherited roles
     */
    #getGrantedRoles(role: RoleEnum): string[] {
        const grantedRoles = [role.toString()];
        const inheritedRoles: RoleEnum[] = RolesMapping.get(role) ?? [];

        inheritedRoles.forEach((inheritedRole: RoleEnum) => grantedRoles.push(...this.#getGrantedRoles(inheritedRole)));

        return [...new Set(grantedRoles)];
    }
}
