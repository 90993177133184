import {CanLoad, Route, UrlSegment, UrlTree} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '@environment/environment';

@Injectable()
export class DevEnvGuard implements CanLoad {
    public canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return !environment.production;
    }
}
