import {Directive, HostBinding, Input} from '@angular/core';

@Directive({
    selector: '[appCypressTestId]',
})
export class CypressDataAttributeDirective {
    @HostBinding('attr.data-cy')
    @Input()
    appCypressTestId!: string;
}
