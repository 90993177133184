import {Component, Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-messages-new-exam-assign',
    templateUrl: './messages-new-exam-assign.component.html',
    styleUrls: ['./messages-new-exam-assign.component.scss'],
})

export class MessagesNewExamAssignComponent {
    readonly #translateService: TranslateService;

    title: string = '';

    constructor(
        @Inject(MAT_SNACK_BAR_DATA) public data: any,
        translateService: TranslateService,
    ) {
        this.#translateService = translateService;
        this.title = data ? data.title : ' ';
    }
}
