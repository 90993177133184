import {Component, HostBinding, Input} from '@angular/core';
import {StatusDotTypeEnum} from '@shared/status-dot-check/enums/status-dot-type.enum';

@Component({
    selector: 'app-status-dot-check',
    templateUrl: './status-dot-check.component.html',
    styleUrls: ['./status-dot-check.component.scss'],
})
export class StatusDotCheckComponent {
    @HostBinding('class')
    public classes: string[] = ['status-dot-check'];

    @Input()
    public size?: string = '2.8rem';

    @Input()
    public type?: string = '';

    @Input()
    public backgroundColor?: string = '';

    @Input()
    public topBorder?: boolean = false;

    public statusDotType = StatusDotTypeEnum;
}
