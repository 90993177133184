import {Component, ElementRef, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren} from '@angular/core';
import {ButtonTypeEnum} from '@shared/button/button.directive';
import {MultiSelectInterface} from '@shared/interfaces/multi-select.interface';

export enum MenuPlaceHolder {
    All = 'multiSelect.select',
}

@Component({
    selector: 'app-multi-select',
    templateUrl: './multi-select.component.html',
    styleUrls: ['./multi-select.component.scss'],
})
export class MultiSelectComponent implements OnInit, OnChanges {
    @HostBinding('class')
    readonly classes: string[] = ['multi-select'];

    @ViewChildren('inputCheckBox')
    readonly checkBoxes!: QueryList<ElementRef>;

    @Input()
    placeHolder: MenuPlaceHolder = MenuPlaceHolder.All;

    @Input()
    ellipsisMax?: number;

    @Input()
    title?: string;

    @Input()
    bookUuid?: string;

    @Input()
    resetToggle?: boolean;

    @Input()
    countToggle?: boolean;

    @Input()
    hideFirstCheckBox?: boolean;

    @Input()
    items: MultiSelectInterface[] = [];

    @Input()
    selectedItems: string[] = [];

    @Output()
    selectEvent: EventEmitter<string[]> = new EventEmitter();

    @Output()
    resetEvent: EventEmitter<any> = new EventEmitter();

    @ViewChild('actionItem')
    actionItem?: ElementRef;

    buttonType = ButtonTypeEnum;

    selection: MultiSelectInterface[] = [];

    selectionOutput?: string;

    ngOnInit(): void {
        if(this.items === [] && this.selectedItems === []) {
            return;
        }

        // TODO mobile implementation replace for redux
        for(let i = 0; i <= this.items.length; i++) {
            if(this.selectedItems.includes(this.items[i]?.action)) {
                this.items[i].checked = !this.items[i].checked;
            }
        }
    }

    public ngOnChanges(changes: SimpleChanges) {
        if(this.resetToggle && (this.items && this.items.length > 0)) {
            for(let i = 0; i < this.items.length; i++) {
                this.items[i].checked = false;
            }
            this.selectionOutput = undefined;
        }
    }

    public handleSelectedItem(event: Event, index: number): void {
        const item: MultiSelectInterface = this.items[index];

        if(index === 0) {
            this.resetToggle= true;
            this.selectionOutput = undefined;
            this.resetEvent.emit(true);

            for(let i = 0; i < this.items.length; i++) {
                this.items[i].checked = false;
            }
        }

        if(item.action === '') {
            for(let i = 0; i < this.items.length; i++) {
                const checkboxElement = <HTMLInputElement>this.checkBoxes.get(i)?.nativeElement;

                checkboxElement.checked = this.items[i].checked = false;
            }

            this.handleSelectedCheckBoxFinder();

            return;
        }

        item.checked = !item.checked;

        const itemsWithoutAllSelected = this.items.filter(item => item.action !== '' && !item.checked);
        const itemAllSelected = this.items.find(item => item.action === '');

        itemsWithoutAllSelected.length > 0 && itemAllSelected ? itemAllSelected.checked = false : false;

        this.handleSelectedCheckBoxFinder();
    }

    public handleSelectedCheckBoxFinder(): void {
        let names: string[] = [];
        let values: string[] = [];

        this.items.map(item => {
            if(item.checked) {
                if (item.title != null && item.action !== '') {
                    names.push(item.title);
                    values.push(item.action);
                }
            }
        });

        this.selectionOutput = (names.toString().split(',').join(', ')).trim();
        this.selectEvent.emit(values);
    }
}
