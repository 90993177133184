import {Pipe, PipeTransform} from '@angular/core';

/**
 * @deprecated Use CSS instead, this is not reliable
 */
@Pipe({
    name: 'ellipsis',
})
export class Ellipsis implements PipeTransform {
    public transform(value?: string, limit = 25, completeWords = false, ellipsis = '...') {
        if (null === value || undefined === value) {
            return;
        }

        if (completeWords) {
            limit = value.substr(0, limit).lastIndexOf(' ');
        }

        return value.length > limit ? value.substr(0, limit) + ellipsis : value;
    }
}
