import {Injectable} from '@angular/core';
import {ActionsSubject, Store} from '@ngrx/store';
import {DocumentAssignmentService, ResetAssignmentInterface} from '@shared/modules/content/services/assignment/assignment.service';
import {AssignmentInterface} from '@shared/interfaces/assignment.interface';
import {DocumentDetailsInterface} from '@shared/interfaces/document.interface';
import {AssignmentActions} from '@store/assignment';
import {ofType} from '@ngrx/effects';
import {UserInterface} from '@shared/interfaces/user.interface';
import {SecuritySelectors} from '@store/security';
import {AssignmentSelectors} from '@store/assignment/assignment.selectors';
import {filter} from 'rxjs/operators';
import {OperatorFunction} from 'rxjs';

export interface AssignmentDocumentDetailsInterface extends DocumentDetailsInterface {
    assignment: AssignmentInterface;
}

@Injectable({
    providedIn: 'root',
})
export class AssignmentService {
    private currentUser?: UserInterface;

    public constructor(
        private store: Store,
        private documentAssignmentService: DocumentAssignmentService,
        actionSubject: ActionsSubject,
    ) {
        actionSubject.pipe(ofType(AssignmentActions.fetchByAssignmentContextFailed)).subscribe(properties => console.error(properties));
        actionSubject.pipe(ofType(AssignmentActions.fetchByAssignmentContextSuccess)).subscribe(
            properties => this.documentAssignmentService.fetchByDpsIdSuccess(properties.document),
        );

        this.store.select(SecuritySelectors.selectCurrentUser).subscribe(currentUser => this.currentUser = currentUser);
        this.store.select(AssignmentSelectors.selectContext).subscribe(
            context => this.documentAssignmentService.setContext(context?.bookUuid, context?.chapterUuid, context?.documentDpsId),
        );

        this.documentAssignmentService.subscribeToReset(removeAnswers => this.#handleRemoveAnswers(removeAnswers));
        this.documentAssignmentService.subscribeToSelectByDpsId(assignmentDpsId => this.#handleSelectByDpsId(assignmentDpsId));
        this.documentAssignmentService.subscribeToHandIn(assignment => this.store.dispatch(AssignmentActions.handIn({assignment})));
        this.documentAssignmentService.subscribeToFetchByContext(properties => this.store.dispatch(AssignmentActions.fetchByAssignmentContext(properties)));
    }

    #handleSelectByDpsId(assignmentDpsId: string): void {
        this.store.select(AssignmentSelectors.selectAssignmentByDpsId(assignmentDpsId))
            .pipe(filter((assignment?: AssignmentInterface): boolean => undefined !== assignment) as OperatorFunction<AssignmentInterface | undefined, AssignmentInterface>)
            .subscribe((assignment: AssignmentInterface) => this.documentAssignmentService.selectByDpsIdSuccess(assignment));
    }

    #handleRemoveAnswers(removeAnswers: ResetAssignmentInterface, forUser?: UserInterface): void {
        const user: UserInterface | undefined = undefined !== forUser ? forUser : this.currentUser;

        if (undefined === user) {
            throw new Error('Unable to remove answers, user is undefined');
        }

        this.store.dispatch(AssignmentActions.removeAnswers({assignment: removeAnswers.assignment, user}));
    }
}
