import {Component, HostBinding, Input, OnChanges, SimpleChanges} from '@angular/core';
import {IconAbstract} from '@shared/icon/icon.abstract';

@Component({
    selector: 'app-icon[icon]',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss'],
})
export class IconComponent extends IconAbstract implements OnChanges {
    private defaultClasses: string[] = ['icon'];

    @HostBinding('class')
    private classes!: string[];

    @Input()
    @HostBinding('class.icon--original-color')
    originalColor: boolean = false;

    @Input()
    @HostBinding('class.icon--no-margin')
    private noMargin: boolean = false;

    @Input()
    public paths: number = 8;

    @Input()
    @HostBinding('style.--rotate.deg')
    public rotate?: number;

    @Input()
    @HostBinding('style.fontSize.rem')
    public fontSize?: number;

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.icon) {
            this.classes = [...this.defaultClasses, `icon--${this.icon}`];
        }
    }
}
