import {createAction, props} from '@ngrx/store';
import {BreadcrumbActionsEnum} from '@store/breadcrumb/breadcrumb.enum';
import {BreadCrumbInterface} from '@shared/interfaces/breadcrumb.interface';

export class BreadcrumbActions {
    public static readonly setBreadcrumbsAction = createAction(
        BreadcrumbActionsEnum.setBreadcrumbs,
        props<{ breadcrumbs?: BreadCrumbInterface[] }>(),
    );

    public static readonly unsetBreadcrumbsAction = createAction(BreadcrumbActionsEnum.unsetBreadcrumbs);
}
