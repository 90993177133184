import {Injectable} from '@angular/core';
import {DocumentDetailsInterface} from '@shared/interfaces/document.interface';
import {AlgebrakitService} from '@shared/modules/content/services/algebrakit/algebrakit.service';
import {AlgebrakitSessionInterface} from '@shared/interfaces/algebrakit.interface';
import {ActionsSubject, Store} from '@ngrx/store';
import {LibraryActions} from '@store/library';
import {ofType} from '@ngrx/effects';
import {Observable} from 'rxjs';
import {ApiV2EndpointsEnum} from '@core/enums/api-v2-endpoints.enum';
import {ApiService} from '@core/services/api/api.service';
import {ResultInterface} from '@shared/interfaces/result.interface';
import {AlgebrakitSessionFinishSuccessPropertiesInterface, AlgebrakitSessionPropertiesInterface} from '@store/library/library.properties';
import {sprintf} from '@core/core.functions';

@Injectable({
    providedIn: 'root',
})
export class DocumentService {
    public constructor(private store: Store, private algebrakitService: AlgebrakitService, private api: ApiService, actionsSubject: ActionsSubject) {
        actionsSubject.pipe(ofType(LibraryActions.finishAlgebrakitSessionSuccess)).subscribe(algebrakitSessionFinish => this.handleFinishAlgebrakitSessionSuccess(algebrakitSessionFinish));
        actionsSubject.pipe(ofType(LibraryActions.newAlgebrakitSessionSuccess)).subscribe(algebrakitSession => this.handleNewAlgebrakitSessionSuccess(algebrakitSession));
    }

    public finishAlgebrakitSession(sessionId: string): Observable<ResultInterface> {
        return this.api.post<ResultInterface>(sprintf(ApiV2EndpointsEnum.LibraryAlgebrakitFinishSession, sessionId));
    }

    public getNewAlgebrakitSession(sessionId: string): Observable<AlgebrakitSessionInterface> {
        return this.api.get<AlgebrakitSessionInterface>(sprintf(ApiV2EndpointsEnum.LibraryAlgebrakitNewSession, sessionId));
    }

    /**
     * For now, we'll only handle algebrakit sessions
     */
    public handleDocument(document: DocumentDetailsInterface): void {
        const algebraKitSession: AlgebrakitSessionInterface | undefined = document.algebrakitSession ?? undefined;

        if (undefined === algebraKitSession) {
            return;
        }

        // We'll have to link the document's algebrakit events to our redux store
        this.algebrakitService.subscribeToFinish(document.dpsId, event => this.store.dispatch(LibraryActions.finishAlgebrakitSession(event)));
        this.algebrakitService.subscribeToNew(document.dpsId, event => this.store.dispatch(LibraryActions.newAlgebrakitSession(event)));

        // Set session for algebra kit component
        this.algebrakitService.setSession(document.dpsId, algebraKitSession.sessionId, algebraKitSession.active, algebraKitSession.result ?? undefined);
    }

    private handleFinishAlgebrakitSessionSuccess(algebrakitSessionFinish: AlgebrakitSessionFinishSuccessPropertiesInterface): void {
        this.algebrakitService.setSession(algebrakitSessionFinish.dpsId, algebrakitSessionFinish.sessionId, true, algebrakitSessionFinish.result);
    }

    private handleNewAlgebrakitSessionSuccess(algebrakitSession: AlgebrakitSessionPropertiesInterface): void {
        this.algebrakitService.setSession(algebrakitSession.dpsId, algebrakitSession.sessionId, algebrakitSession.active);
    }
}
