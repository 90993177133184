export enum GroupActionsEnum {
    ClearAll = '[group] clearAll',
    FetchOrganizationGroups = '[groups] fetchOrganizationGroups',
    FetchOrganizationGroupsSuccess = '[groups] fetchOrganizationGroupsSuccess',
    FetchGroups = '[group] fetchGroups',
    FetchGroupsSuccess = '[group] fetchGroupsSuccess',
    SetGroup = '[group] SetGroup',
    ClearGroup = '[group] ClearGroup',
    CreateGroups = '[group] createGroups',
    CreateGroupsSuccess = '[group] createGroupsSuccess',
    CreateGroupsFailed = '[group] createGroupsFailed',
    JoinGroups = '[group] joinGroups',
    JoinGroupsSuccess = '[group] joinGroupsSuccess',
    JoinGroupsFailed = '[group] joinGroupsFailed',
    RenewGroupCode = '[group] renewGroupCode',
    RenewGroupCodeSuccess = '[group] renewGroupCodeSuccess',
    RenewGroupCodeFailed = '[group] renewGroupCodeFailed',
    UpdateGroupName = '[group] updateGroupName',
    UpdateGroupNameSuccess = '[group] updateGroupNameSuccess',
    UpdateGroupNameFailed = '[group] updateGroupNameFailed',
    RemoveGroupUser = '[group] removeGroupUser',
    RemoveGroupUserSuccess = '[group] removeGroupUserSuccess',
    RemoveGroupUserFailed = '[group] removeGroupUserFailed',
    UpdateGroupStatus = '[group] updateGroupStatus',
    UpdateGroupStatusSuccess = '[group] updateGroupStatusSuccess',
}
