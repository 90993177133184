import {inject, Injectable} from '@angular/core';
import {BookInterface} from '@shared/interfaces/book.interface';
import {ChapterInterface} from '@shared/interfaces/chapter.interface';
import {ApiExamsResponseInterface} from '@store/exam/exam-api.contract';
import {sprintf} from '@core/core.functions';
import {ApiService} from '@core/services/api/api.service';
import {Observable} from 'rxjs';
import {HttpMethodsEnum} from '@core/http/methods.enum';
import {AppExamMetadata} from '@component/exam/player/player.service';
import {ApiV2EndpointsEnum} from '@core/enums/api-v2-endpoints.enum';

export interface ApiExamInterface {
    id: number;
    type: number;
    title: string;
}

export interface ApiExamPlanInterface {
    id?: number;
    book: BookInterface;
    exam: ApiExamInterface;
    chapter: ChapterInterface;
    documentDpsId: string;
    startDate: string;
    endDate: string;
    userIds: number[];
}

export interface ApiExamWeekInterface {
    number: number;
    entries: ApiExamPlanInterface[];
    startDate: string;
    endDate: string;
}

export interface ApiExamListEntryInterface {
    year: number;
    weeks: ApiExamWeekInterface[];
    examCount: number;
    offset: number;
}

export interface ApiExamListInterface {
    previous: ApiExamListEntryInterface;
    current: ApiExamListEntryInterface;
    next: ApiExamListEntryInterface;
}

export interface ExamAssignInterface {
    id?: number;
    userIds: number[];
    examId: number;
    startDate: string;
    endDate: string;
}

@Injectable({
    providedIn: 'root',
})
export class AppExamService {
    #apiService: ApiService = inject(ApiService);

    assign(mode: HttpMethodsEnum.Put | HttpMethodsEnum.Post, bookUuid: string, exam: ExamAssignInterface): Observable<ApiExamListInterface> {
        return this.#apiService.request<ApiExamListInterface>(mode, sprintf('/api/v2/exam/%s/assign', bookUuid), exam)
    }

    fetchByBookUuid(bookUuid: string, types?: number[]): Observable<ApiExamsResponseInterface> {
        return this.#apiService.get<ApiExamsResponseInterface>(
            sprintf('/api/v2/exam/%s', bookUuid),
            undefined !== types ? new URLSearchParams([['types', JSON.stringify(types)]]) : undefined,
        );
    }

    fetchExamPlan(bookUuid: string, chapterUuid: string, documentDpsId: string, userId?: number, planId?: number): Observable<AppExamMetadata> {
        const searchParams: string[][] = [];

        if (undefined !== userId) {
            searchParams.push(['userId', userId.toString()])
        }

        if (undefined !== planId) {
            searchParams.push(['planId', planId.toString()])
        }

        return this.#apiService.get<AppExamMetadata>(
            sprintf(ApiV2EndpointsEnum.ExamMetadata, bookUuid, chapterUuid, documentDpsId),
            0 !== searchParams.length ? new URLSearchParams(searchParams) : undefined,
        );
    }

    fetchAssigned(types: number[], offset?: number, year?: number): Observable<ApiExamListInterface>
    {
        const searchParams: string[][] = [['types', JSON.stringify(types)]];

        if (undefined !== offset) {
            searchParams.push(['offset', offset.toString()])
        }

        if (undefined !== year) {
            searchParams.push(['year', year.toString()])
        }

        return this.#apiService.get<ApiExamListInterface>('/api/v2/exam', new URLSearchParams(searchParams));
    }
}
