import {Action, createReducer, on} from '@ngrx/store';
import {GroupActions} from '@store/group/group.actions';
import {GroupMap} from '@store/group/group.map';
import {GroupInterface} from '@shared/interfaces/group.interface';

export interface GroupState {
    groups: GroupMap;
    group?: GroupInterface;
    organizationGroup?: GroupInterface[];
    bookUuid?: string;
}

export const initialState: GroupState = {
    groups: new GroupMap(),
};

export const reducer = createReducer(
    initialState,
    on(GroupActions.clearAll, () => ({groups: new GroupMap()})),
    on(GroupActions.setGroup, (state, group) => ({...state, group})),
    on(GroupActions.clearGroup, (state) => ({...state, group: undefined})),
    on(GroupActions.fetchOrganizationGroupsSuccess, (state, {organizationGroup}) => ({...state, organizationGroup})),
    on(GroupActions.fetchGroupsSuccess, (state, props) => ({...state, groups: state.groups.setGroups(props.groups)})),
    on(GroupActions.createGroupSuccess, (state, props) => ({...state, groups: state.groups.addGroup(props)})),
    on(GroupActions.joinGroupsSuccess, (state, props) => ({...state, groups: state.groups.joinGroup(props.groups, props.user)})),
    on(GroupActions.renewGroupCodeSuccess, (state, props) => ({...state, groups: state.groups.renewGroupCode(props.id, props.code)})),
    on(GroupActions.updateGroupNameSuccess, (state, props) => ({...state, groups: state.groups.updateGroupName(props.id, props.name)})),
    on(GroupActions.removeGroupUserSuccess, (state, {groupId, userId}) => ({...state, groups: state.groups.removeGroupUser(groupId, userId)})),
    on(GroupActions.updateGroupStatusSuccess, (state, props) => ({...state, groups: state.groups.updateGroupStatus(props.id, props.active)})),
);

export const groupReducer = (
    state: GroupState | undefined,
    action: Action,
): GroupState => {
    return reducer(state, action);
};
