import {Directive, ElementRef, Injectable, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {AuthorizationService} from '@core/security/services/authorization.service';
import {RoleEnum} from '@core/security/enum/role.enum';
import {ColorHelper} from '@shared/helpers/color.helper';

// This is not a good solution, we might have to improve this
enum ColorAdjustmentEnum {
    // HO adjustments
    HoAdjustedColor = -20,
    HoAdjustedColorAlt = -50,

    // VO adjustments
    VoAdjustedColor = 15,
    VoAdjustedColorAlt = 30,
}

@Directive({
    selector: '[appThemeHelperAutoTextColorVariable]',
})
export class ThemeHelperAutoTextColorVariableDirective implements OnInit, OnChanges {
    @Input()
    public enabled: boolean = true;

    @Input()
    public variable: string = '--primary-color';

    @Input()
    public targetVariable: string = '--primary-text-color';

    public constructor(private elementRef: ElementRef, private colorHelper: ColorHelper, private themeHelper: ThemeHelper) {
    }

    public ngOnInit(): void {
        this.updateColorVariable();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.enabled && !changes.enabled.firstChange) {
            this.updateColorVariable();
        }
    }

    private updateColorVariable(): void {
        if (!this.enabled) {
            this.elementRef.nativeElement.style.removeProperty(this.targetVariable);

            return;
        }

        const styleColor: string | undefined = this.themeHelper.getVariableColor(this.elementRef, this.variable);

        if (undefined === styleColor) {
            return;
        }

        this.elementRef.nativeElement.style.setProperty(this.targetVariable, this.colorHelper.pickColorByColorIntensity(styleColor));
    }
}

@Injectable()
export class ThemeHelper {
    public constructor(private authorizationService: AuthorizationService, private colorHelper: ColorHelper) {
    }

    public getHexAsRGBA(hexColor: string, opacity: number = 0.1): string | undefined {
        return this.colorHelper.getHexAsRGBA(hexColor, opacity);
    }

    public getVariableColor(elementRef: ElementRef, variable: string): string | undefined {
        return getComputedStyle(elementRef.nativeElement).getPropertyValue(variable);
    }

    public getPrimaryAdjustedColor(color: string): string {
        const isRoleHO: boolean = this.authorizationService.isGranted(RoleEnum.RoleOrganizationHo);

        return this.colorHelper.getAdjustedColor(color, isRoleHO ? ColorAdjustmentEnum.HoAdjustedColor : ColorAdjustmentEnum.VoAdjustedColor);
    }

    public getPrimaryAdjustedAltColor(color: string): string {
        const isRoleHO: boolean = this.authorizationService.isGranted(RoleEnum.RoleOrganizationHo);

        return this.colorHelper.getAdjustedColor(color, isRoleHO ? ColorAdjustmentEnum.HoAdjustedColorAlt : ColorAdjustmentEnum.VoAdjustedColorAlt);
    }

    public updateDocumentMarkTheming(elementRef: ElementRef, color: string): void {
        this.addOrRemoveStyleVariable(elementRef, '--content-mark-color', this.getHexAsRGBA(color, 0.16) ?? undefined);
        this.addOrRemoveStyleVariable(elementRef, '--icon-background-color', this.getHexAsRGBA(color, 0.16) ?? undefined);
        this.addOrRemoveStyleVariable(elementRef, '--text-color', color ? color : undefined);
        this.addOrRemoveStyleVariable(elementRef, '--content-text-color', color ? '#000' : undefined);
    }

    public updateDocumentTermTheming(elementRef: ElementRef, color: string): void {
        this.addOrRemoveStyleVariable(elementRef, '--term-color', this.getHexAsRGBA(color, 1) ?? undefined);
        this.addOrRemoveStyleVariable(elementRef, '--term-background-color', color ? this.colorHelper.getAdjustedColor(color, -50) : undefined);
    }

    public updateSourceIconTheming(elementRef: ElementRef, color: string): void {
        this.addOrRemoveStyleVariable(elementRef, '--source-icon-color', color ?? undefined);
        this.addOrRemoveStyleVariable(elementRef, '--source-icon-background-color', this.getHexAsRGBA(color, 0.16) ?? undefined);
    }

    public updateSourceMenuIconTheming(elementRef: ElementRef, color: string): string[] {
        return color ? [(this.colorHelper.getHexAsRGBA(color, 0.16) ?? undefined), color] : ['', ''];
    }

    public addOrRemoveStyleVariable(elementRef: ElementRef, variable: string, color?: string): void {
        if (undefined === color) {
            this.removeStyleVariable(elementRef, variable);
        } else {
            elementRef.nativeElement.style.setProperty(variable, color);
        }
    }

    public removeStyleVariable(elementRef: ElementRef, variable: string): void {
        elementRef.nativeElement.style.removeProperty(variable);
    }
}
