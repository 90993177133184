import {NgModule} from '@angular/core';
import {HTMLElementHelper} from '@shared/helpers/html-element.helper';
import {ThemeHelper, ThemeHelperAutoTextColorVariableDirective} from '@shared/helpers/theming.helper';
import {ExtensionHelper} from '@shared/helpers/extension-helper.service';
import {ColorHelper} from '@shared/helpers/color.helper';

@NgModule({
    declarations: [
        ThemeHelperAutoTextColorVariableDirective,
    ],
    exports: [
        ThemeHelperAutoTextColorVariableDirective,
    ],
    providers: [
        ColorHelper,
        ThemeHelper,
        HTMLElementHelper,
        ExtensionHelper,
    ],
})
export class HelpersModule {
}
