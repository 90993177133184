import {Injectable} from '@angular/core';
import {BehaviorSubject, OperatorFunction, Subscription} from 'rxjs';
import {DrillsterSessionInterface} from '@shared/interfaces/drillster.interface';
import {filter, take} from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class DrillsterService {
    private sessionSubject: BehaviorSubject<DrillsterSessionInterface | null> = new BehaviorSubject<DrillsterSessionInterface | null>(null);

    public subscribeToSession(code: string, fn: (drillsterSession: DrillsterSessionInterface) => void): Subscription {
        return this.sessionSubject.pipe(
            filter(drillsterSession => drillsterSession?.code === code) as OperatorFunction<DrillsterSessionInterface | null, DrillsterSessionInterface>,
            take(1),
        ).subscribe(drillsterSession => fn(drillsterSession));
    }

    public setSession(session: DrillsterSessionInterface): void {
        this.sessionSubject.next(session);
    }
}
