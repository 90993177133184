import {Component, OnDestroy} from '@angular/core';
import {ButtonTypeEnum} from '@shared/button/button.directive';
import {Subscription} from 'rxjs';
import {LanguageService, LocaleEnum} from '@component/language/language.service';

@Component({
    selector: 'app-language-dropdown',
    templateUrl: './language-dropdown.component.html',
    styleUrls: ['./language-dropdown.component.scss'],
})

export class LanguageDropdownComponent implements OnDestroy {
    readonly #languageService: LanguageService;

    readonly #localeSubscription!: Subscription;

    readonly buttonType = ButtonTypeEnum;

    readonly localeEnum = LocaleEnum;

    selectedLanguage!: LocaleEnum;

    constructor(languageService: LanguageService) {
        this.#languageService = languageService;
        this.#localeSubscription = languageService.subscribe((locale: LocaleEnum) => this.selectedLanguage = locale);
    }

    ngOnDestroy(): void {
        this.#localeSubscription.unsubscribe();
    }

    handleSelectDutch(): void {
        this.#languageService.select(LocaleEnum.Dutch);
    }

    handleSelectEnglish(): void {
        this.#languageService.select(LocaleEnum.English);
    }
}
