import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '@core/services/api/api.service';
import {ApiV2EndpointsEnum} from '@core/enums/api-v2-endpoints.enum';
import {GroupInterface} from '@shared/interfaces/group.interface';
import {sprintf} from '@core/core.functions';

@Injectable({
    providedIn: 'root',
})
export class GroupsService {
    public constructor(private api: ApiService) {
    }

    public retrieveGroups(bookUuid?: string, active?: boolean): Observable<GroupInterface[]> {
        const urlSearchParams: URLSearchParams = new URLSearchParams();

        if (undefined !== active) {
            urlSearchParams.set('active', String(active));
        }

        const endPoint: string = bookUuid ? sprintf(ApiV2EndpointsEnum.GroupsByBookUuid, bookUuid) : ApiV2EndpointsEnum.Groups;

        return this.api.get<GroupInterface[]>(endPoint, urlSearchParams);
    }

    public retrieveOrganizationGroups(bookUuid?: string): Observable<GroupInterface[]> {
        const endPoint: string = bookUuid ? sprintf(ApiV2EndpointsEnum.GroupsOrganizationAndBook, bookUuid) : ApiV2EndpointsEnum.GroupsOrganization;

        return this.api.get<GroupInterface[]>(endPoint);
    }

    public retrieveGroup(groupId: number): Observable<GroupInterface> {
        return this.api.get<GroupInterface>(sprintf(ApiV2EndpointsEnum.Group, groupId));
    }

    public createGroups(name: string, bookUuid?: string): Observable<GroupInterface> {
        const body: any = {
            name,
            bookUuid,
        };

        return this.api.post<GroupInterface>(ApiV2EndpointsEnum.GroupCreate, body);
    }

    public joinGroups(codes: string[], bookUuid?: string): Observable<GroupInterface[]> {
        const endPoint: string = undefined !== bookUuid ? sprintf(ApiV2EndpointsEnum.GroupJoinOrConnect, bookUuid) : ApiV2EndpointsEnum.GroupJoin

        return this.api.post(endPoint, {codes});
    }

    public regenerateGroupCode(groupId: number): Observable<GroupInterface> {
        return this.api.patch<GroupInterface>(sprintf(ApiV2EndpointsEnum.GroupRenewCode, groupId));
    }

    public updateGroupName(groupId: number, name: string): Observable<GroupInterface> {
        const body: any = {
            name: name,
        };

        return this.api.patch<GroupInterface>(sprintf(ApiV2EndpointsEnum.Group, groupId), body);
    }

    public removeGroupUser(groupId?: number, userId?: number): Observable<GroupInterface> {
        return this.api.delete<GroupInterface>(sprintf(ApiV2EndpointsEnum.GroupUserRemove, groupId, userId));
    }

    public updateGroupStatus(groupId: number, active: boolean): Observable<GroupInterface> {
        const body: any = {
            active,
        };

        return this.api.patch<GroupInterface>(sprintf(ApiV2EndpointsEnum.GroupStatusUpdate, groupId), body);
    }
}
