import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AuthorizationService} from '@core/security/services/authorization.service';
import {RoleEnum} from '@core/security/enum/role.enum';

@Injectable()
export class RouteGuard implements CanActivate {
    public constructor(private authorizationService: AuthorizationService, private router: Router) {
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        let roles: RoleEnum[] = route.data.roles;

        if(this.authorizationService.isEveryGranted(roles)) {
            return true;
        }

        this.router.navigate(['404']);

        return false;
    }
}
