import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {IconAbstract} from '@shared/icon/icon.abstract';

@Component({
    selector: 'app-icon-circle[icon]',
    templateUrl: './icon-circle.component.html',
    styleUrls: ['./icon-circle.component.scss'],
})
export class IconCircleComponent extends IconAbstract implements OnInit {
    @HostBinding('class')
    private class: string[] = ['icon-circle'];

    @Input()
    public size: number = 3.2;

    @Input()
    public modifier: string = 'module';

    public ngOnInit(): void {
        this.class = [...this.class, `icon-circle--${this.modifier}`];
    }
}
