import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {OperatorFunction, Subscription} from 'rxjs';
import {fetchCurrentUserAction, SecuritySelectors} from '@store/security';
import {UserAuthenticatedInterface} from '@shared/interfaces/user.interface';
import {GeneralActions, GeneralSelectors} from '@store/general';
import {filter, take} from 'rxjs/operators';
import {FaviconService} from '@core/services/favicon/favicon.service';
import {PublisherInterface} from '@shared/interfaces/publisher.interface';
import {MessageService} from '@/component/messages/message.service';
import {RoutingService} from '@component/routing/routing.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    readonly #subscriptions: Subscription[] = [];

    readonly #store: Store;

    readonly #faviconService: FaviconService;

    readonly #messageService: MessageService;

    readonly #routingService: RoutingService;

    currentUser?: UserAuthenticatedInterface;

    publisher?: PublisherInterface;

    public constructor(
        store: Store,
        faviconService: FaviconService,
        messageService: MessageService,
        routingService: RoutingService,
    ) {
        this.#store = store;
        this.#faviconService = faviconService;
        this.#messageService = messageService;
        this.#routingService = routingService;
    }

    ngOnInit(): void {
        this.#store.select(GeneralSelectors.selectPublisher)
            .pipe(filter(publisher => undefined !== publisher) as OperatorFunction<PublisherInterface | undefined, PublisherInterface>, take(1))
            .subscribe(publisher => this.#handleSelectPublisher(publisher));

        this.#subscriptions.push(
            this.#store.select(SecuritySelectors.selectCurrentUser).subscribe(currentUser => this.#handleSelectCurrentUser(currentUser)),
        );

        this.#store.dispatch(GeneralActions.fetchPublisher());
    }

    ngOnDestroy(): void {
        this.#subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    #handleSelectPublisher(publisher: PublisherInterface): void {
        this.publisher = publisher;
        this.#faviconService.load(publisher);
        this.#store.dispatch(fetchCurrentUserAction());
    }

    #handleSelectCurrentUser(currentUser?: UserAuthenticatedInterface): void {
        if (undefined === currentUser) {
            return;
        }

        this.#messageService.load(this.currentUser = currentUser);
    }
}
