import {LibraryEffects} from '@store/library';
import {SecurityEffects} from '@store/security';
import {LicenseEffects} from '@store/license';
import {AssignmentEffects} from '@store/assignment/assignment.effects';
import {GroupEffects} from '@store/group/group.effects';
import {ProfileEffects} from '@store/profile/profile.effects';
import {ExamEffects} from '@store/exam/exam.effects';
import {ProgressEffects} from '@store/progress/progress.effects';
import {SearchEffects} from '@store/search';
import {GeneralEffects} from '@store/general';
import {SelfExamineEffects} from '@store/self-examine/self-examine.effects';
import {OrganizationEffects} from '@store/organization/organization.effects';
import {MessageEffects} from '@store/message/message.effects';
import {TermEffects} from '@store/term/term.effects';
import {AppExamEffects} from '@component/exam/redux/exam.effects';

export const rootEffects = [
    LibraryEffects,
    SecurityEffects,
    LicenseEffects,
    AssignmentEffects,
    GroupEffects,
    ProfileEffects,
    ExamEffects,
    AppExamEffects,
    ProgressEffects,
    SearchEffects,
    GeneralEffects,
    SelfExamineEffects,
    OrganizationEffects,
    MessageEffects,
    TermEffects,
];
