import {Injectable} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {AuthorizationService} from '@core/security/services/authorization.service';
import {RoleEnum} from '@core/security/enum/role.enum';

@Injectable({
    providedIn: 'root',
})
export class HeaderTitleService {
    public isBoomHo: boolean = false;

    public constructor(
        private titleService: Title,
        private translateService: TranslateService,
        private authorizationService: AuthorizationService,
    ) {
        this.isBoomHo = this.authorizationService.isGranted(RoleEnum.RoleOrganizationHo);
    }

    public setHeaderTitle(title: string):void {
        this.titleService.setTitle(title + this.translateService.instant(this.isBoomHo ? 'pageTitle.ho' : 'pageTitle.vo'));
    }
}
