export enum SecurityActions {
    fetchCurrentUser = '[security] fetchCurrentUser',
    updateLoadedCurrentUser = '[security] updateLoadedCurrentUser',
    fetchAcceptTermsAction = '[security] fetchAcceptTermsAction,',
    updateAcceptTermsAction = '[security] updateAcceptTermsAction',
    updateAcceptTermsActionSuccess = '[security] updateAcceptTermsActionSuccess',
    updateAcceptTermsActionFailed = '[security] updateAcceptTermsActionFailed',
    updateOrganizationAction = '[security] updateOrganizationAction',
    updateOrganizationActionSuccess = '[security] updateOrganizationActionSuccess',
    updateOrganizationActionFailed = '[security] updateOrganizationActionFailed',
    upgradeToTeacherActionSuccess = '[security] upgradeToTeacherActionSuccess',
    upgradeToTeacherActionFailed = '[security] upgradeToTeacherActionFailed',
}
