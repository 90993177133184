import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'remindoDurationTime',
})
export class RemindoDurationTime implements PipeTransform {
    public transform(minutes: number) {
        return moment.utc(moment.duration((minutes * 60), 'seconds').asMilliseconds()).format('HH:mm').toString();
    }
}
