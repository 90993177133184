import {createAction, props} from '@ngrx/store';
import {MessageActionsEnum} from '@store/message/message.enum';
import {HttpErrorResponseInterface} from '@core/interfaces/http-error-response.interface';
import {MessagesInterface} from '@shared/interfaces/message.interface';
import {PaginationInterface} from '@core/interfaces/pagination.interface';

export interface MessagePropertiesInterface extends PaginationInterface {
    ids: number[];
}

export interface MarkMessagePropertiesInterface extends MessagePropertiesInterface {
    ids: number[];
    isRead: boolean;
}

export class MessageActions {
    static readonly fetchMessages = createAction(MessageActionsEnum.fetchMessages, props<PaginationInterface>());

    static readonly fetchMessagesSuccess = createAction(MessageActionsEnum.fetchMessagesSuccess, props<MessagesInterface>());

    static readonly fetchMessagesFailed = createAction(MessageActionsEnum.fetchMessagesFailed, props<{ error: HttpErrorResponseInterface }>());

    static readonly markMessage = createAction(MessageActionsEnum.markMessage, props<MarkMessagePropertiesInterface>());

    static readonly markMessageSuccess = createAction(MessageActionsEnum.markMessageSuccess, props<MessagesInterface>());

    static readonly markMessageFailed = createAction(MessageActionsEnum.markMessageFailed, props<{ error: HttpErrorResponseInterface }>());

    static readonly deleteMessage = createAction(MessageActionsEnum.deleteMessage, props<MessagePropertiesInterface>());

    static readonly deleteMessageSuccess = createAction(MessageActionsEnum.deleteMessageSuccess, props<MessagesInterface>());

    static readonly deleteMessageFailed = createAction(MessageActionsEnum.deleteMessageFailed, props<{ error: HttpErrorResponseInterface }>());
}
