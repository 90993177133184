import {Component, Input} from '@angular/core';
import {StarRatingSizeEnum} from '@shared/star-rating/enums/star-rating-size.enum';
import {StarRatingPositionEnum} from '@shared/star-rating/enums/star-rating-position.enum';

@Component({
    selector: 'app-star-rating',
    templateUrl: './star-rating.component.html',
    styleUrls: ['./star-rating.component.scss'],
})
export class StarRatingComponent {
    @Input()
    public percentage: number = 0;

    @Input()
    public size?: StarRatingSizeEnum;

    @Input()
    public position: StarRatingPositionEnum = StarRatingPositionEnum.Top;

    public starRatingPosition = StarRatingPositionEnum;
}
