import {Component, ElementRef, HostBinding, Input, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {Subscription} from 'rxjs';
import {GeneralSelectors} from '@store/general';
import {PublisherInterface} from '@shared/interfaces/publisher.interface';
import {ColorEnum} from '@core/enums/color.enum';
import {ThemeHelper} from '@shared/helpers/theming.helper';
import {RoleEnum} from '@core/security/enum/role.enum';

@Component({
    selector: 'app-publisher-brand',
    templateUrl: './publisher-brand.component.html',
    styleUrls: ['./publisher-brand.component.scss'],
})
export class PublisherBrandComponent implements OnInit, OnDestroy {
    @HostBinding('class')
    private classes: string[] = ['publisher-brand'];

    private subscriptions: Subscription[] = [];

    @Input()
    @HostBinding('style.fontSize.rem')
    public fontSize: number = 2;

    @Input()
    public variable?: string;

    @Input()
    @HostBinding('style.--primary-text-color')
    public prefixColor?: string | null;

    @Input()
    @HostBinding('style.--primary-color')
    public affixColor?: string | null;

    @Input()
    public autoColor: boolean = true;

    public publisher?: PublisherInterface;

    public constructor(private elementRef: ElementRef, private store: Store, private themeHelper: ThemeHelper) {
        this.subscriptions.push(
            this.store.select(GeneralSelectors.selectPublisher).subscribe(publisher => this.publisher = publisher),
        );
    }

    public ngOnInit(): void {
        const publisher: PublisherInterface | undefined = this.publisher;

        if (undefined === publisher) {
            throw new Error('Cannot init publisher brand without publisher');
        }

        const variableColor: string | undefined = undefined !== this.variable ? this.themeHelper.getVariableColor(this.elementRef, this.variable) : undefined;

        this.prefixColor = this.getColor(this.prefixColor, variableColor ?? ColorEnum.LightColor);
        this.affixColor = this.getColor(this.affixColor, publisher.colors?.primary);
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    /**
     * If NULL was passed to the color input value, it'll use the fallback colors defined in variables.scss
     */
    private getColor(variableValue: string | null | undefined, color: string | null | undefined): string | null | undefined {
        if (null === variableValue) {
            return null;
        }

        return variableValue ?? color ?? undefined;
    }

    protected readonly roleEnum = RoleEnum;
}
