import {Component, HostBinding, Input} from '@angular/core';
import {ButtonTypeEnum} from '@shared/button/button.directive';
import {AbstractSelect} from '@shared/abstract/select/abstract.select';
import {MemoizedSelector, Store} from '@ngrx/store';
import {UserInterface} from '@shared/interfaces/user.interface';
import {SelectionActions, SelectionSelectors} from '@store/selection';

@Component({
    selector: 'app-group-student-select',
    templateUrl: './group-student-select.component.html',
    styleUrls: ['./group-student-select.component.scss'],
})
export class GroupUserSelectComponent extends AbstractSelect<UserInterface> {
    public readonly buttonType = ButtonTypeEnum;

    @HostBinding('class')
    public readonly classes: string[] = ['single-select'];

    @Input()
    public ellipsisMax?: number;

    @Input()
    public buttonHeight: string = '5.2rem'

    @Input()
    public dropdownWidth: string = '26rem';

    @Input()
    public title?: string;

    public constructor(store: Store) {
        super(store);
    }

    public handleClick(student?: UserInterface): void {
        this.dispatch(SelectionActions.setUser({item: student}));
    }

    protected getItemSelector(): MemoizedSelector<object, UserInterface | undefined> {
        return SelectionSelectors.selectUser;
    }

    protected getItemsSelector(): MemoizedSelector<object, UserInterface[] | undefined> {
        return SelectionSelectors.selectGroupStudents;
    }

    protected filterItems(items: UserInterface[]): UserInterface[] {
        return items;
    }
}
