import {Action, createReducer, on} from '@ngrx/store';
import {SearchResultsMap} from '@store/search/search-results.map';
import {SearchActions} from '@store/search/search.actions';

export interface SearchState {
    results: SearchResultsMap;
}

export const initialState: SearchState = {
    results: new SearchResultsMap(),
};

export const reducer = createReducer(
    initialState,
    on(SearchActions.clearAll, () => (initialState)),
    on(SearchActions.setSearchResults, (state, props) => ({
        ...state,
        results: state.results.insertOrUpdate(props.datasourceId, props.searchResults),
    })),
);

export const searchReducer = (state: SearchState | undefined, action: Action): SearchState => {
    return reducer(state, action);
};
