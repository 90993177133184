import {NgModule} from '@angular/core';
import {CypressDataAttributeDirective} from '@component/cypress/data-attribute.directive';

@NgModule({
    declarations: [
        CypressDataAttributeDirective,
    ],
    exports: [
        CypressDataAttributeDirective,
    ],
})
export class CypressModule {
}
