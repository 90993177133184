import {Injectable} from '@angular/core';
import {iif, Observable, of, OperatorFunction} from 'rxjs';
import {ApiService} from '@core/services/api/api.service';
import {ApiV2EndpointsEnum} from '@core/enums/api-v2-endpoints.enum';
import {OrganizationInterface} from '@shared/interfaces/organization.interface';
import {DialogOrganizationComponent} from '@feature/books/components/organization-dialog/organization-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {AuthorizationService} from '@core/security/services/authorization.service';
import {SecuritySelectors} from '@store/security';
import {filter, map, mergeMap} from 'rxjs/operators';
import {UserAuthenticatedInterface} from '@shared/interfaces/user.interface';
import {HasOrganizationInterface} from '@core/security/contract/user.contract';

@Injectable({
    providedIn: 'root',
})
export class OrganizationService {
    #store: Store;

    #api: ApiService;

    #dialog: MatDialog;

    #authorizationService: AuthorizationService;

    public constructor(store: Store, api: ApiService, dialog: MatDialog, authorizationService: AuthorizationService) {
        this.#api = api;
        this.#dialog = dialog;
        this.#store = store;
        this.#authorizationService = authorizationService;
    }

    retrieveOrganizations(): Observable<OrganizationInterface[]> {
        return this.#api.get<OrganizationInterface[]>(ApiV2EndpointsEnum.OrganizationList);
    }

    requestOrganization(name: string): Observable<OrganizationInterface> {
        const body: any = {
            name,
        };

        return this.#api.post<OrganizationInterface>(ApiV2EndpointsEnum.requestOrganization, body);
    }

    displayDialogByHasTeacherLicense(hasTeacherLicense: boolean): Observable<HasOrganizationInterface> {
        return iif(() => hasTeacherLicense, this.#store.select(SecuritySelectors.selectCurrentUser))
            .pipe(
                filter(currentUser => undefined !== currentUser) as OperatorFunction<UserAuthenticatedInterface | undefined, UserAuthenticatedInterface>,
                mergeMap((currentUser: UserAuthenticatedInterface) => undefined !== currentUser.organizationName ? of(true) : this.#openOrganizationDialog()),
                map(hasOrganization => ({hasOrganization})),
            );
    }

    #openOrganizationDialog(): Observable<boolean> {
        return this.#dialog.open(DialogOrganizationComponent, {disableClose: true, panelClass: `custom-dialog-container`}).afterClosed();
    }
}
