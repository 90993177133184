import {NgModule} from '@angular/core';
import {IconCircleComponent} from '@shared/icon/circle/icon-circle.component';
import {IconComponent} from '@shared/icon/default/icon.component';
import {FlexModule} from '@angular/flex-layout';
import {CommonModule} from '@angular/common';

@NgModule({
    imports: [
        FlexModule,
        CommonModule,
    ],
    declarations: [
        IconComponent,
        IconCircleComponent,
    ],
    exports: [
        IconComponent,
        IconCircleComponent,
    ],
})
export class IconModule {
}
