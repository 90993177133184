import {Action, createReducer, on} from '@ngrx/store';
import {GeneralActions} from '@store/general/general.actions';
import {PublisherInterface} from '@shared/interfaces/publisher.interface';
import {BookDetailInterface} from '@shared/interfaces/book.interface';
import {ChapterProgressInterface} from '@shared/interfaces/chapter.interface';
import {DocumentDetailsInterface} from '@shared/interfaces/document.interface';

interface GeneralContext {
    book?: BookDetailInterface;
    chapter?: ChapterProgressInterface;
    document?: DocumentDetailsInterface;
}

export interface GeneralState {
    publisher?: PublisherInterface;
    navigationSecondExpanded: boolean;
    context: GeneralContext;
}

export const initialState: GeneralState = {
    navigationSecondExpanded: false,
    context: {},
};

export const reducer = createReducer(
    initialState,
    on(GeneralActions.toggleNavigationSecondExpanded, (state, properties) => ({...state, ...{navigationSecondExpanded: properties.value}})),
    on(GeneralActions.fetchPublisherSuccess, (state, publisher) => ({...state, publisher})),
);

export const generalReducer = (
    state: GeneralState | undefined,
    action: Action,
): GeneralState => {
    return reducer(state, action);
};
