import {Store} from '@ngrx/store';
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {TermService} from '@core/services/term/term.service';
import {TermsActionsEnum} from '@store/term/term.enum';
import {TermActions} from '@store/term/term.actions';

@Injectable()
export class TermEffects {
    #store: Store;

    #termService: TermService;

    public constructor(private actions$: Actions, store: Store, termService: TermService) {
        this.#store = store;
        this.#termService = termService;
    }

    fetchTermsForStudymaterial$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(TermsActionsEnum.fetchTermsForStudymaterial),
            switchMap((properties: {uuid: string}) => this.#termService.retrieveTermsForStudymaterial(properties.uuid).pipe(
                map(terms => TermActions.fetchTermsForStudymaterialSuccess({terms})),
                catchError(error => of(TermActions.fetchTermsForStudymaterialFailed({error}))),
            )),
        )
    });
}
