import {Component, ElementRef, HostBinding, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {AbstractSearchDatasource} from '@core/search/contract/abstract-search-datasource.service';

@Component({
    selector: 'app-search-bar[dataSource]',
    templateUrl: './search-bar.component.html',
    styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit {
    @ViewChild('searchInputElement')
    private searchInputElement?: ElementRef;

    @HostBinding('class')
    private className: string = 'search-bar';

    @Input()
    public dataSource!: AbstractSearchDatasource;

    @Input()
    public placeholder?: string;

    @Input()
    public collapsable: boolean = false;

    @Input()
    public collapsed?: boolean;

    @Input()
    public size: number = 5.6;

    @Input()
    public paragraphBackground: boolean = false;

    public readonly searchFormGroup: FormGroup;

    public constructor(formBuilder: FormBuilder, private elementRef: ElementRef, private translateService: TranslateService) {
        this.searchFormGroup = formBuilder.group({
            search: [null, {validators: [Validators.required, Validators.minLength(2)]}],
        }, {updateOn: 'submit'});
    }

    public ngOnInit(): void {
        this.collapsed = this.collapsed ?? this.collapsable;

        if (undefined === this.placeholder) {
            this.placeholder = this.translateService.instant('search.placeHolder');
        }
    }

    @HostListener('document:click', ['$event'])
    public handleDocumentClick(event: Event): void {
        if (!this.collapsable || this.collapsed) {
            return;
        }

        if (this.elementRef.nativeElement.contains(event.target) || this.hasValue()) {
            return;
        }

        this.resetSearchFormGroup();
        this.collapsed = true;
    }

    public handleSubmit(): void {
        if (this.searchFormGroup.invalid) {
            return;
        }

        this.dataSource.query(this.searchFormGroup.get('search')?.value, 0);
    }

    public toggleCollapse(event: Event): void {
        if (!this.collapsable || this.hasValue()) {
            return;
        }

        event.preventDefault();

        const isCollapsed = this.collapsed = !this.collapsed;

        if (!isCollapsed) {
            this.searchInputElement?.nativeElement.focus();
        }
    }

    private hasValue(): boolean {
        return '' !== (this.searchInputElement?.nativeElement as HTMLInputElement).value;
    }

    /**
     * It's way to hard to reset form validation...
     */
    private resetSearchFormGroup(): void {
        Object.keys(this.searchFormGroup.controls).forEach(key => {
            const searchFromControl: AbstractControl | null = this.searchFormGroup.get(key);

            if (null === searchFromControl) {
                throw new Error(`Form control "${key}" is NULL`);
            }

            searchFromControl.markAsPristine();
            searchFromControl.markAsUntouched();
            searchFromControl.setErrors(null);
        });
    }
}
