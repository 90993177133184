import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '@core/services/api/api.service';
import {ApiV2EndpointsEnum} from '@core/enums/api-v2-endpoints.enum';
import {UserInterface} from '@shared/interfaces/user.interface';
import {HttpParams} from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class ProfileService {
    public constructor(private api: ApiService) {
    }

    public retrieveProfile(): Observable<UserInterface> {
        return this.api.get<UserInterface>(ApiV2EndpointsEnum.Profile);
    }

    public updateProfile(firstName: string, middleName: string, lastName: string, email: string, avatar: File): Observable<UserInterface> {
        return this.api.patch<UserInterface>(ApiV2EndpointsEnum.Profile, {firstName, middleName, lastName, email, avatar});
    }

    public updateProfileAvatar( avatar: File): Observable<UserInterface> {
        const formData = new FormData();

        formData.append('avatar', avatar, avatar.name);

        return this.api.uploadFile<UserInterface>(avatar,ApiV2EndpointsEnum.Profile);
    }

    public deleteProfileAvatar(): Observable<UserInterface> {
        return this.api.delete<UserInterface>(ApiV2EndpointsEnum.Profile);
    }
}
