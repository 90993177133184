import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '@core/services/api/api.service';
import {ApiV2EndpointsEnum} from '@core/enums/api-v2-endpoints.enum';
import {Store} from '@ngrx/store';
import {AuthorizationService} from '@core/security/services/authorization.service';
import {MessagesInterface} from '@shared/interfaces/message.interface';
import {MarkMessagePropertiesInterface, MessagePropertiesInterface} from '@store/message';
import {PaginationInterface} from '@core/interfaces/pagination.interface';

@Injectable({
    providedIn: 'root',
})
export class MessageService {
    #store: Store;

    #api: ApiService;

    #authorizationService: AuthorizationService;

    public constructor(store: Store, api: ApiService, authorizationService: AuthorizationService) {
        this.#api = api;
        this.#store = store;
        this.#authorizationService = authorizationService;
    }

    list(offset?: number, limit?: number): Observable<MessagesInterface> {
        const urlSearchParams: URLSearchParams = new URLSearchParams();

        this.#appendOffsetAndLimit({offset, limit}, urlSearchParams);

        return this.#api.get<MessagesInterface>(ApiV2EndpointsEnum.Messages, urlSearchParams);
    }

    mark(properties: MarkMessagePropertiesInterface): Observable<MessagesInterface> {
        const urlSearchParams: URLSearchParams = new URLSearchParams([['isRead', properties.isRead.toString()]]);

        this.#appendIds(properties.ids, urlSearchParams);
        this.#appendOffsetAndLimit(properties, urlSearchParams);

        return this.#api.patch<MessagesInterface>(ApiV2EndpointsEnum.MessageMarkRead, properties.ids, urlSearchParams);
    }

    delete(properties: MessagePropertiesInterface): Observable<MessagesInterface> {
        const urlSearchParams: URLSearchParams = new URLSearchParams();

        this.#appendIds(properties.ids, urlSearchParams);
        this.#appendOffsetAndLimit(properties, urlSearchParams);

        return this.#api.delete<MessagesInterface>(ApiV2EndpointsEnum.MessagesDelete, urlSearchParams);
    }

    #appendOffsetAndLimit(pagination: PaginationInterface, urlSearchParams: URLSearchParams): void {
        if (undefined !== pagination.offset && undefined !== pagination.limit) {
            urlSearchParams.set('offset', pagination.offset.toString());
            urlSearchParams.set('limit', pagination.limit.toString());
        }
    }

    #appendIds(ids: number[], urlSearchParams: URLSearchParams): void {
        ids.forEach(id => urlSearchParams.has('ids[]') ? urlSearchParams.append('ids[]', id.toString()) : urlSearchParams.set('ids[]', id.toString()));
    }
}
