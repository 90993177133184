import {Action, createReducer, on} from '@ngrx/store';
import {BreadcrumbActions} from '@store/breadcrumb/breadcrumb.actions';
import {BreadCrumbInterface} from '@shared/interfaces/breadcrumb.interface';

export interface BreadcrumbState {
    breadcrumbs?: BreadCrumbInterface[],
}

export const initialState: BreadcrumbState = {
    breadcrumbs: [],
};

export const reducer = createReducer(
    initialState,
    on(BreadcrumbActions.setBreadcrumbsAction, (state, {breadcrumbs}) => ({...state, breadcrumbs})),
    on(BreadcrumbActions.unsetBreadcrumbsAction, (state) => ({...state, breadcrumbs: []})),
);

export const breadcrumbReducer = (
    state: BreadcrumbState | undefined,
    action: Action,
): BreadcrumbState => {
    return reducer(state, action);
};
