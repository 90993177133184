export enum ProgressActionsEnum {
    ClearAll = '[progress] ClearAll',
    SetPage = '[progress] SetPage',

    FetchProgression = '[progress] FetchProgression',
    FetchProgressionSuccess = '[progress] FetchProgressionSuccess',
    FetchProgressionFailed = '[progress] FetchProgressionFailed',

    FetchForUser = '[progress] FetchForUser',
    FetchForUserSuccess = '[progress] FetchForUserSuccess',
    FetchForUserFailed = '[progress] FetchForUserFailed',

    FetchForGroup = '[progress] FetchForGroup',
    FetchForGroupSuccess = '[progress] FetchForGroupSuccess',
    FetchForGroupFailed = '[progress] FetchForGroupFailed',

    SelectAssignment = '[progress] SelectAssignment',
    ClearAssignment = '[progress] ClearAssignment',

    SaveReview = '[progress] SaveReview',
    SaveReviewSuccess = '[progress] SaveReviewSuccess',
    SaveReviewFailed = '[progress] SaveReviewFailed',

    RemoveAnswers = '[progress] RemoveAnswers',
    RemoveAnswersSuccess = '[progress] RemoveAnswersSuccess',
    RemoveAnswersFailed = '[progress] RemoveAnswersFailed',

    SaveUpdatedReview = '[progress] SaveUpdatedReview',
    SaveUpdatedReviewSuccess = '[progress] SaveUpdatedReviewSuccess',
    SaveUpdatedReviewFailed = '[progress] SaveUpdatedReviewFailed',
}
