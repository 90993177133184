import {Directive, ViewContainerRef} from '@angular/core';

@Directive({
    selector: '[appViewContainerRef]',
})
export class ViewContainerRefDirective {
    readonly #viewContainerRef: ViewContainerRef;

    constructor(viewContainerRef: ViewContainerRef) {
        this.#viewContainerRef = viewContainerRef;
    }

    getViewContainerRef(): ViewContainerRef {
        return this.#viewContainerRef
    }
}
