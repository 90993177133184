import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {StoreRouterConnectingModule} from '@ngrx/router-store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {rootReducers} from '@store/store.reducer';
import {rootEffects} from '@store/store.effects';

@NgModule({
    imports: [
        StoreModule.forRoot(rootReducers),
        EffectsModule.forRoot(rootEffects),
        StoreRouterConnectingModule.forRoot(),
        StoreDevtoolsModule.instrument(),
    ],
})
export class AppStoreModule {
}
