import {createAction, props} from '@ngrx/store';
import {HttpErrorResponseInterface} from '@core/interfaces/http-error-response.interface';
import {ProfileActionsEnum} from '@store/profile/profile.enum';
import {UserInterface} from '@shared/interfaces/user.interface';

export class ProfileActions {
    public static readonly updateProfileAction = createAction(ProfileActionsEnum.updateProfile, props<{ firstName: string, middleName: string, lastName: string, email: string, avatar: File }>());

    public static readonly updateProfileAvatarAction = createAction(ProfileActionsEnum.updateProfileAvatar, props<{avatar: File }>());

    public static readonly updateProfileActionSuccess = createAction(ProfileActionsEnum.updateProfileSuccess, props<{ user: UserInterface }>());

    public static readonly updateProfileActionFailed = createAction(ProfileActionsEnum.updateProfileFailed, props<{ error: HttpErrorResponseInterface }>());

    public static readonly deleteProfileAvatarAction = createAction(ProfileActionsEnum.deleteProfileAvatar, props<{ user?: UserInterface }>());
}
