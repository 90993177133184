export enum AssignmentActionsEnum {
    Remove = '[assignment] Remove',

    SaveAnswer = '[assignment] saveAnswer',
    SaveAnswerSuccess = '[assignment] saveAnswerSuccess',
    SaveAnswerFailed = '[assignment] saveAnswerFailed',

    RemoveAnswers = '[assignment] removeAnswers',
    RemoveAnswersSuccess = '[assignment] removeAnswersSuccess',
    RemoveAnswersFailed = '[assignment] removeAnswersFailed',

    HandIn = '[assignment] handIn',
    HandInSuccess = '[assignment] handInSuccess',
    HandInFailed = '[assignment] handInFailed',

    AutoCheck = '[assignment] autoCheck',
    AutoCheckSuccess = '[assignment] autoCheckSuccess',
    AutoCheckFailed = '[assignment] autoCheckFailed',

    SaveReview = '[assignment] saveReview',
    SaveReviewSuccess = '[assignment] saveReviewSuccess',
    SaveReviewFailed = '[assignment] saveReviewFailed',

    FetchByAssignmentContext = '[assignment] fetchByAssignmentContext',
    FetchByAssignmentContextSuccess = '[assignment] fetchByAssignmentContextSuccess',
    FetchByAssignmentContextFailed = '[assignment] fetchByAssignmentContextFailed',

    CreateFeedback = '[assignment] createFeedback',
    CreateFeedbackSuccess = '[assignment] createFeedbackSuccess',
    CreateFeedbackFailed = '[assignment] createFeedbackFailed',

    RespondFeedback = '[assignment] respondFeedback',
    RespondFeedbackSuccess = '[assignment] respondFeedbackSuccess',
    RespondFeedbackFailed = '[assignment] respondFeedbackFailed',
}
