import {Store} from '@ngrx/store';
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {LicenseActions} from '@store/license/license.enum';
import {LicenseService} from '@core/services/license/license.service';
import {activateBookLicensesActionFailed, activateBookLicensesActionSuccess, processLicensesActionFailed, processLicensesActionSuccess} from '@store/license/license.actions';
import {LicenseInterface} from '@store/license/license.reducer';

@Injectable()
export class LicenseEffects {
    public constructor(
        private store: Store,
        private actions$: Actions,
        private licenseService: LicenseService,
    ) {
    }

    public activateBookLicensesSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(LicenseActions.activateBookLicensesSuccess),
            switchMap((properties: { licenses: LicenseInterface[] }) => {
                return this.licenseService.process(properties.licenses).pipe(
                    map(licenseResult => processLicensesActionSuccess({licenseResult})),
                    catchError(error => of(processLicensesActionFailed({error}))),
                );
            }),
        );
    });

    public activateBookLicenses$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(LicenseActions.activateBookLicenses),
            switchMap((properties: { licenses: string[] }) => {
                return this.licenseService.activate(properties.licenses).pipe(
                    map(licenses => activateBookLicensesActionSuccess({licenses})),
                    catchError(error => of(activateBookLicensesActionFailed({error}))),
                );
            }),
        );
    });
}
