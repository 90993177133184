import {createAction, props} from '@ngrx/store';
import {HttpErrorResponseInterface} from '@core/interfaces/http-error-response.interface';
import {TermsActionsEnum} from '@store/term/term.enum';
import {TermInterface} from '@core/services/term/term.service';

export class TermActions {
    static readonly fetchTermsForStudymaterial = createAction(TermsActionsEnum.fetchTermsForStudymaterial, props<{ uuid: string }>());

    static readonly fetchTermsForStudymaterialSuccess = createAction(TermsActionsEnum.fetchTermsForStudymaterialSuccess, props<{ terms: TermInterface[] }>());

    static readonly fetchTermsForStudymaterialFailed = createAction(TermsActionsEnum.fetchTermsForStudymaterialFailed, props<{ error: HttpErrorResponseInterface }>());
}
