import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '@core/services/api/api.service';
import {ApiV2EndpointsEnum} from '@core/enums/api-v2-endpoints.enum';
import {DrillsterSessionInterface} from '@shared/interfaces/drillster.interface';
import {ActionsSubject} from '@ngrx/store';
import {DrillsterService as DrillsterContentService} from '@shared/modules/content/services/drillster/drillster.service';
import {ofType} from '@ngrx/effects';
import {DrillsterSessionFailedPropertiesInterface, DrillsterSessionSuccessPropertiesInterface} from '@store/library/library.properties';
import {LibraryActions} from '@store/library';
import {sprintf} from 'sprintf-js';

@Injectable({
    providedIn: 'root',
})
export class DrillsterService {
    public constructor(private drillsterContentService: DrillsterContentService, private api: ApiService, actionsSubject: ActionsSubject) {
        actionsSubject.pipe(ofType(LibraryActions.fetchDrillsterSessionSuccess)).subscribe(drillsterSession => this.handleFetchDrillsterSessionSuccess(drillsterSession));
        actionsSubject.pipe(ofType(LibraryActions.fetchDrillsterSessionFailed)).subscribe(error => this.handleFetchDrillsterSessionFailed(error));
    }

    public getDrillsterSession(drillsterCode: string, documentVersionId: number): Observable<DrillsterSessionInterface> {
        return this.api.get<DrillsterSessionInterface>(sprintf(ApiV2EndpointsEnum.LibraryDrillsterSession, drillsterCode, documentVersionId));
    }

    public updateDrillsterSessionUpdateScore(code: string, id: number, score: number): Observable<DrillsterSessionInterface> {
        return this.api.patch<DrillsterSessionInterface>(sprintf(ApiV2EndpointsEnum.LibraryDrillsterSessionUpdateScore, code, id), {score});
    }

    private handleFetchDrillsterSessionSuccess(drillsterSessionSuccess: DrillsterSessionSuccessPropertiesInterface): void {
        this.drillsterContentService.setSession(drillsterSessionSuccess.drillsterSession);
    }

    private handleFetchDrillsterSessionFailed(error: DrillsterSessionFailedPropertiesInterface): void {
        const drillsterSessionFailed: DrillsterSessionInterface = {id: error.id, tokenId: error.tokenId, active: error.active, result: undefined, code: error.code};

        this.drillsterContentService.setSession(drillsterSessionFailed);
    }
}
