import {createFeatureSelector, createSelector} from '@ngrx/store';
import {StoreFeatureSelectorEnum} from '@store/store.enum';
import {LicenseState} from '@store/license/license.reducer';

export class LicenseSelectors{
    public static getLicenseState = createFeatureSelector<LicenseState>(
        StoreFeatureSelectorEnum.License,
    );

    public static activateBookLicensesSuccess = createSelector(
        LicenseSelectors.getLicenseState,
        (state: LicenseState) => state.licenses,
    );

    public static activateBookLicensesFailed = createSelector(
        LicenseSelectors.getLicenseState,
        (state: LicenseState) => state.activatedBookLicensesError,
    );
}
