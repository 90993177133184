export enum SelectionActionsEnum {
    ClearAll = '[selection] clearAll',
    SetChapter = '[selection] setChapter',
    ClearChapter = '[selection] clearChapter',
    SetDocument = '[selection] setDocument',
    ClearDocument = '[selection] clearDocument',
    SetGroup = '[selection] setGroup',
    ClearGroup = '[selection] clearGroup',
    SetUser = '[selection] setUser',
    ClearUser = '[selection] clearUser',
}
