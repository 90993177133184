import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {ProgressService} from '@core/services/progress/progress.service';
import {ProgressActionsEnum} from '@store/progress/progress.enum';
import {of} from 'rxjs';
import {ProgressActions} from '@store/progress/progress.actions';
import {ProgressGroupInterface, ProgressUserInterface, ProgressUserProgressionInterface} from '@shared/interfaces/progress.interface';
import {ProgressGroupPropertiesInterface, ProgressReviewPropertiesInterface, ProgressUserPropertiesInterface} from '@store/progress/progress.properties';

@Injectable()
export class ProgressEffects {
    #progressService: ProgressService;

    constructor(private actions$: Actions, progressService: ProgressService) {
        this.#progressService = progressService;
    }

    fetchProgression$ = createEffect(() => this.actions$.pipe(
        ofType(ProgressActionsEnum.FetchProgression),
        switchMap((properties: ProgressUserPropertiesInterface) => this.#progressService.progression(properties.bookUuid).pipe(
            map((progress: ProgressUserProgressionInterface) => ProgressActions.fetchProgressionSuccess(progress)),
            catchError(error => of(ProgressActions.fetchProgressionFailed({error, properties}))),
        )),
    ));

    fetchUserProgress$ = createEffect(() => this.actions$.pipe(
        ofType(ProgressActionsEnum.FetchForUser),
        switchMap((properties: ProgressUserPropertiesInterface) => this.#progressService.retrieveUserProgress(
            properties.bookUuid,
            properties.chapterUuid,
            properties.userId,
        ).pipe(
            map((progress: ProgressUserInterface) => ProgressActions.fetchForUserSuccess(progress)),
            catchError(error => of(ProgressActions.fetchForUserFailed({error, properties}))),
        )),
    ));

    fetchGroupProgress$ = createEffect(() => this.actions$.pipe(
        ofType(ProgressActionsEnum.FetchForGroup),
        switchMap((properties: ProgressGroupPropertiesInterface) => this.#progressService.retrieveGroupProgress(
            properties.bookUuid,
            properties.chapterUuid,
            properties.groupId,
        ).pipe(
            map((progress: ProgressGroupInterface) => ProgressActions.fetchForGroupSuccess(progress)),
            catchError(error => of(ProgressActions.fetchForGroupFailed({error, properties}))),
        )),
    ));

    saveReview$ = createEffect(() => this.actions$.pipe(
        ofType(ProgressActionsEnum.SaveReview),
        switchMap((properties: ProgressReviewPropertiesInterface) => this.#progressService
            .saveReview(properties.book.uuid, properties.chapter.uuid, properties.group.id, properties.user.id, properties.assignment.dpsId, properties.grade)
            .pipe(
                map(progress => ProgressActions.saveReviewSuccess(progress)),
                catchError(error => of(ProgressActions.saveReviewFailed({error, properties}))),
            )),
    ));

    removeAnswers$ = createEffect(() => this.actions$.pipe(
        ofType(ProgressActionsEnum.RemoveAnswers),
        switchMap((properties: ProgressReviewPropertiesInterface) => this.#progressService
            .removeAnswers(properties.book.uuid, properties.chapter.uuid, properties.group.id, properties.user.id, properties.assignment.dpsId)
            .pipe(
                map(progress => ProgressActions.removeAnswersSuccess(progress)),
                catchError(error => of(ProgressActions.removeAnswersFailed({error, properties}))),
            )),
    ));

    saveUpdatedReview$ = createEffect(() => this.actions$.pipe(
        ofType(ProgressActionsEnum.SaveUpdatedReview),
        switchMap((properties: ProgressReviewPropertiesInterface) => this.#progressService
            .saveUpdatedReview(properties.book.uuid, properties.chapter.uuid, properties.group.id, properties.user.id, properties.assignment.dpsId, properties.grade)
            .pipe(
                map(progress => ProgressActions.saveUpdatedReviewSuccess(progress)),
                catchError(error => of(ProgressActions.saveUpdatedReviewFailed({error, properties}))),
            )),
    ));
}
