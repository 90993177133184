import {Injectable} from '@angular/core';
import {ApiService} from '@core/services/api/api.service';
import {Observable, Subject, Subscription} from 'rxjs';
import {ApiV2EndpointsEnum} from '@core/enums/api-v2-endpoints.enum';
import {sprintf} from '@core/core.functions';
import {AssignmentInterface, AssignmentReviewInterface} from '@shared/interfaces/assignment.interface';
import {filter} from 'rxjs/operators';

export enum DocumentAnswerTypeEnum {
    Current,
    Initial,
    Reviewed,
    Model,
}

export interface DocumentAnswerByTypeInterface {
    answerType: DocumentAnswerTypeEnum;
    assignmentId: string;
    fieldId: number;
}

@Injectable({
    providedIn: 'root',
})
export class DocumentAnswersService {
    #answerByTypeSubject: Subject<DocumentAnswerByTypeInterface> = new Subject<DocumentAnswerByTypeInterface>();

    public constructor(private apiService: ApiService) {
    }

    setAnswerByType(documentAnswerType: DocumentAnswerByTypeInterface): void {
        this.#answerByTypeSubject.next(documentAnswerType);
    }

    subscribeToSetAnswerByType(assignmentId: string, fieldId: number, fn: (answerByType: DocumentAnswerByTypeInterface) => void): Subscription {
        return this.#answerByTypeSubject
            .pipe(filter(answerByType => assignmentId === answerByType.assignmentId && fieldId === answerByType.fieldId))
            .subscribe(fn);
    }

    public handIn(chapterUuid: string, assignmentDocumentDpsId: string): Observable<AssignmentInterface> {
        return this.apiService.put(sprintf(ApiV2EndpointsEnum.AssignmentHandIn, chapterUuid, assignmentDocumentDpsId));
    }

    public autoCheck(chapterUuid: string, assignmentDocumentDpsId: string): Observable<AssignmentInterface> {
        return this.apiService.get(sprintf(ApiV2EndpointsEnum.AssignmentAutoCheck, chapterUuid, assignmentDocumentDpsId));
    }

    public saveAnswer(chapterUuid: string, assignmentDocumentDpsId: string, fieldId: number, value: any): Observable<void> {
        return this.apiService.post(sprintf(ApiV2EndpointsEnum.AssignmentAnswersField, chapterUuid, assignmentDocumentDpsId, fieldId), value);
    }

    public removeAnswers(chapterUuid: string, assignmentDocumentDpsId: string, userId: number): Observable<AssignmentInterface> {
        return this.apiService.delete(sprintf(ApiV2EndpointsEnum.AssignmentAnswers, chapterUuid, assignmentDocumentDpsId, userId));
    }

    public saveReview(chapterUuid: string, assignmentDocumentDpsId: string, assignmentPreview: AssignmentReviewInterface): Observable<any> {
        return this.apiService.post(sprintf(ApiV2EndpointsEnum.AssignmentReview, chapterUuid, assignmentDocumentDpsId), assignmentPreview);
    }
}
