export enum StoreFeatureSelectorEnum {
    Library = 'library',
    Security = 'security',
    Breadcrumb = 'breadcrumb',
    Feedback = 'feedback',
    License = 'license',
    Assignment = 'assignment',
    Group = 'group',
    Profile = 'profile',
    Exam = 'exam',
    ContentExam = 'contentExam',
    Progress = 'progress',
    Selection = 'selection',
    Search = 'search',
    General = 'general',
    SelfExamine = 'selfExamine',
    Organization = 'organization',
    Message = 'message',
    Term = 'term',
}
