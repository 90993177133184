import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '@core/services/api/api.service';
import {sprintf} from '@core/core.functions';
import {TermResponseInterface} from '@core/services/term/term.contract';
import {TermEndpointsEnum} from '@core/services/term/term.enum';
import {ApiV2EndpointsEnum} from '@core/enums/api-v2-endpoints.enum';

export interface TermsInterface {
    terms: TermInterface[];
    type?: string;
}

export interface TermInterface {
    id: string;
    title: string;
    body: string;
}

@Injectable({
    providedIn: 'root',
})
export class TermService {
    #apiService = inject(ApiService);

    public retrieveTerm(termId: string): Observable<TermResponseInterface> {
        return this.#apiService.get<TermResponseInterface>(sprintf(TermEndpointsEnum.Terms, termId));
    }

    retrieveTermsForStudymaterial(uuid: string): Observable<TermInterface[]> {
        const urlSearchParams: URLSearchParams = new URLSearchParams();

        urlSearchParams.set('uuid', uuid);

        return this.#apiService.get<TermInterface[]>(ApiV2EndpointsEnum.TermsTrain, 0 !== Array.from(urlSearchParams.keys()).length ? urlSearchParams : undefined);
    }
}
