import {Action, createReducer, on} from '@ngrx/store';
import {ExamInterface as LegacyExamInterface} from '@shared/modules/content/services/exam/exam.service';
import {ExamActions} from '@store/exam/exam.actions';

export interface ExamState {
    exam?: LegacyExamInterface;
}

const initialState: ExamState = {};

const reducer = createReducer(
    initialState,
    on(ExamActions.fetchStartRemindoSuccess, (state, {exam}) => ({...state, exam})),
    on(ExamActions.unsetExamAction, (state) => ({...state, exam: undefined})),
);

export const ExamReducer = (
    state: ExamState | undefined,
    action: Action,
): ExamState => {
    return reducer(state, action);
};
