export enum OrganizationActionsEnum {
    fetchOrganizations = '[organization] fetchOrganizations',
    fetchOrganizationsSuccess = '[organization] fetchOrganizationsSuccess',
    fetchOrganizationsFailed = '[organization] fetchOrganizationsFailed',
    displayDialogByHasTeacherLicenseSuccess = '[organization] displayDialogByHasTeacherLicenseSuccess',
    displayDialogByHasTeacherLicenseFailed = '[organization] displayDialogByHasTeacherLicenseFailed',
    requestOrganization = '[organization] requestOrganization',
    requestOrganizationSuccess = '[organization] requestOrganizationSuccess',
    requestOrganizationFailed = '[organization] requestOrganizationFailed',
}
