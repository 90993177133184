import {Action, createReducer, on} from '@ngrx/store';
import {HttpErrorResponseInterface} from '@core/interfaces/http-error-response.interface';
import {OrganizationInterface} from '@shared/interfaces/organization.interface';
import {OrganizationActions} from '@store/organization/organization.actions';

export interface OrganizationState {
    organizations?: OrganizationInterface[];
    organizationFailedError?: HttpErrorResponseInterface,
}

const initialState: OrganizationState = {};

const reducer = createReducer(
    initialState,
    on(OrganizationActions.fetchOrganizationsSuccess, (state, {organizations}) => ({...state, organizations})),
    on(OrganizationActions.requestOrganizationSuccess, (state, {organization}) => ({...state, organization})),
);

export const OrganizationReducer = (
    state: OrganizationState | undefined,
    action: Action,
): OrganizationState => {
    return reducer(state, action);
};
