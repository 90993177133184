import {Directive, TemplateRef, ViewContainerRef} from '@angular/core';
import {AuthorizationService} from '@core/security/services/authorization.service';
import {RoleEnum} from '@core/security/enum/role.enum';
import {AbstractIsGrantedDirective} from '@core/security/directive/abstract-is-granted.directive';

@Directive({
    selector: '[appIsStudent]',
})
export class IsStudentDirective extends AbstractIsGrantedDirective {
    public constructor(
        authorizationService: AuthorizationService,
        viewContainer: ViewContainerRef,
        templateRef: TemplateRef<any>,
    ) {
        super(authorizationService, viewContainer, templateRef);
    }

    protected getRoles(): RoleEnum[] {
        return [RoleEnum.RoleStudent];
    }
}
