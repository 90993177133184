import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {BreadCrumbInterface} from '@shared/interfaces/breadcrumb.interface';

@Injectable({
    providedIn: 'root',
})
export class BreadcrumbService {
    private breadcrumbClickSubject = new Subject<string>();

    breadcrumbClick$ = this.breadcrumbClickSubject.asObservable();

    breadcrumbClick(breadcrumb: string) {
        this.breadcrumbClickSubject.next(breadcrumb);
    }
}
