import {createFeatureSelector, createSelector} from '@ngrx/store';
import {StoreFeatureSelectorEnum} from '@store/store.enum';
import {MessageState} from '@store/message/message.reducer';

export class MessageSelectors {
    public static readonly getState = createFeatureSelector<MessageState>(
        StoreFeatureSelectorEnum.Message,
    );

    public static readonly selectMessages = createSelector(
        MessageSelectors.getState,
        (state: MessageState) => state.messages,
    );

    public static readonly selectUnreadCount = createSelector(
        MessageSelectors.getState,
        (state: MessageState) => state.messages?.unread,
    );
}
