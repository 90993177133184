import {createFeatureSelector, createSelector} from '@ngrx/store';
import {StoreFeatureSelectorEnum} from '@store/store.enum';
import {AssignmentsInterface, AssignmentState} from '@store/assignment/assignment.reducer';
import {LibraryContextInterface, LibrarySelectors} from '@store/library';
import {AssignmentInterface} from '@shared/interfaces/assignment.interface';

export class AssignmentSelectors {
    static readonly selectState = createFeatureSelector<AssignmentState>(
        StoreFeatureSelectorEnum.Assignment,
    );

    static readonly selectContext = createSelector(
        LibrarySelectors.selectContext,
        (libraryContext: LibraryContextInterface) => ({
            bookUuid: libraryContext.bookUuid,
            chapterUuid: libraryContext.chapterUuid,
            documentDpsId: libraryContext.documentDpsId,
        }),
    );

    static readonly selectAssignments = createSelector(
        AssignmentSelectors.selectState,
        (state: AssignmentState) => state.assignments,
    );

    static readonly selectAssignmentByDpsId = (assignmentDocumentDpsId: string) => createSelector(
        AssignmentSelectors.selectAssignments,
        (assignments: AssignmentsInterface): AssignmentInterface | undefined => assignments[assignmentDocumentDpsId],
    );

    static readonly selectAssignmentLegacyDocumentById = (assignmentDocumentDpsId: string) => createSelector(
        AssignmentSelectors.selectState,
        (assignmentState: AssignmentState) => assignmentState.assignmentLegacyDocuments.get(assignmentDocumentDpsId),
    );
}
