import {Action, createReducer, on} from '@ngrx/store';
import {HttpErrorResponseInterface} from '@core/interfaces/http-error-response.interface';
import {TermActions} from '@store/term/term.actions';
import {TermInterface} from '@core/services/term/term.service';

export interface TermState {
    terms?: TermInterface[],
    termsFailedError?: HttpErrorResponseInterface,
}

const initialState: TermState = {};

const reducer = createReducer(
    initialState,
    on(TermActions.fetchTermsForStudymaterialSuccess, (terms) => (terms)),
);

export const TermReducer = (
    state: TermState | undefined,
    action: Action,
): TermState => {
    return reducer(state, action);
};
