import {createAction, props} from '@ngrx/store';
import {ProgressActionsEnum} from '@store/progress/progress.enum';
import {
    ProgressAssignmentInterface,
    ProgressGroupPropertiesInterface,
    ProgressPagePropertiesInterface,
    ProgressProgressionPropertiesInterface,
    ProgressReviewPropertiesInterface,
    ProgressUserPropertiesInterface,
} from '@store/progress/progress.properties';
import {PropertiesErrorResponseInterface} from '@store/interfaces/properties.interface';
import {
    ProgressGroupAssignmentInterface,
    ProgressGroupInterface,
    ProgressItemNumberedInterface,
    ProgressUserInterface,
    ProgressUserProgressionInterface,
} from '@shared/interfaces/progress.interface';

export class ProgressActions {
    public static readonly clearAll = createAction(ProgressActionsEnum.ClearAll);

    public static readonly setPage = createAction(ProgressActionsEnum.SetPage, props<ProgressPagePropertiesInterface>());

    public static readonly fetchProgression = createAction(ProgressActionsEnum.FetchProgression, props<ProgressProgressionPropertiesInterface>());

    public static readonly fetchProgressionSuccess = createAction(ProgressActionsEnum.FetchProgressionSuccess, props<ProgressUserProgressionInterface>());

    public static readonly fetchProgressionFailed = createAction(ProgressActionsEnum.FetchProgressionFailed, props<PropertiesErrorResponseInterface<ProgressProgressionPropertiesInterface>>());

    public static readonly fetchForUser = createAction(ProgressActionsEnum.FetchForUser, props<ProgressUserPropertiesInterface>());

    public static readonly fetchForUserSuccess = createAction(ProgressActionsEnum.FetchForUserSuccess, props<ProgressUserInterface>());

    public static readonly fetchForUserFailed = createAction(ProgressActionsEnum.FetchForUserFailed, props<PropertiesErrorResponseInterface<ProgressUserPropertiesInterface>>());

    public static readonly fetchForGroup = createAction(ProgressActionsEnum.FetchForGroup, props<ProgressGroupPropertiesInterface>());

    public static readonly fetchForGroupSuccess = createAction(ProgressActionsEnum.FetchForGroupSuccess, props<ProgressGroupInterface>());

    public static readonly fetchForGroupFailed = createAction(ProgressActionsEnum.FetchForGroupFailed, props<PropertiesErrorResponseInterface<ProgressGroupPropertiesInterface>>());

    public static readonly setAssignment = createAction(ProgressActionsEnum.SelectAssignment, props<ProgressItemNumberedInterface>());

    public static readonly clearAssignment = createAction(ProgressActionsEnum.ClearAssignment);

    public static readonly saveReview = createAction(ProgressActionsEnum.SaveReview, props<ProgressReviewPropertiesInterface>());

    public static readonly saveReviewSuccess = createAction(ProgressActionsEnum.SaveReviewSuccess, props<ProgressGroupAssignmentInterface>());

    public static readonly saveReviewFailed = createAction(ProgressActionsEnum.SaveReviewFailed, props<PropertiesErrorResponseInterface<ProgressReviewPropertiesInterface>>());

    public static readonly removeAnswers = createAction(ProgressActionsEnum.RemoveAnswers, props<ProgressAssignmentInterface>());

    public static readonly removeAnswersSuccess = createAction(ProgressActionsEnum.RemoveAnswersSuccess, props<ProgressGroupAssignmentInterface>());

    public static readonly removeAnswersFailed = createAction(ProgressActionsEnum.RemoveAnswersFailed, props<PropertiesErrorResponseInterface<ProgressAssignmentInterface>>());

    public static readonly saveUpdatedReview = createAction(ProgressActionsEnum.SaveUpdatedReview, props<ProgressReviewPropertiesInterface>());

    public static readonly saveUpdatedReviewSuccess = createAction(ProgressActionsEnum.SaveUpdatedReviewSuccess, props<ProgressGroupAssignmentInterface>());

    public static readonly saveUpdatedReviewFailed = createAction(ProgressActionsEnum.SaveUpdatedReviewFailed, props<PropertiesErrorResponseInterface<ProgressReviewPropertiesInterface>>());
}
