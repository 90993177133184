import {Component, HostBinding, OnDestroy} from '@angular/core';
import {TableOfContentsInterface} from '@shared/interfaces/table-of-contents.interface';
import {LibrarySelectors} from '@store/library';
import {ChapterProgressInterface} from '@shared/interfaces/chapter.interface';
import {GeneralActions, GeneralSelectors} from '@store/general';
import {SingleSelectInterface} from '@shared/interfaces/single-select.interface';
import {Router} from '@angular/router';
import {BookDetailInterface} from '@shared/interfaces/book.interface';
import {AuthorizationService} from '@core/security/services/authorization.service';
import {RoleEnum} from '@core/security/enum/role.enum';
import {DocumentDetailsInterface} from '@shared/interfaces/document.interface';
import {Subscription} from 'rxjs';
import {Store} from '@ngrx/store';

@Component({
    selector: 'app-chapter-toc',
    templateUrl: './chapter-toc.component.html',
    styleUrls: ['./chapter-toc.component.scss'],
})
export class ChapterTocComponent implements OnDestroy {
    @HostBinding('class')
    readonly elementClass: string = 'chapter-toc-component';

    #book?: BookDetailInterface;

    #subscriptions: Subscription[] = [];

    #chapters: ChapterProgressInterface[] = [];

    chapterListItems: SingleSelectInterface[] = [];

    currentChapter?: ChapterProgressInterface;

    tableOfContents?: TableOfContentsInterface;

    expanded: boolean = false;

    document?: DocumentDetailsInterface;

    isGrantedOrganizationVo: boolean;

    isGrantedOrganizationHo: boolean;

    constructor(
        private store: Store,
        private router: Router,
        authorizationService: AuthorizationService,
    ) {
        this.isGrantedOrganizationVo = authorizationService.isGranted(RoleEnum.RoleOrganizationVo);
        this.isGrantedOrganizationHo = authorizationService.isGranted(RoleEnum.RoleOrganizationHo);

        this.#subscriptions.push(
            store.select(LibrarySelectors.selectChapterToc).subscribe(tableOfContents => this.tableOfContents = tableOfContents),
            store.select(LibrarySelectors.selectBook).subscribe(book => this.#book = book),
            store.select(LibrarySelectors.selectChapter).subscribe(chapter => this.currentChapter = chapter),
            store.select(LibrarySelectors.selectChapters).subscribe(chapters => this.#handleLibrarySelectChapters(chapters)),
            store.select(LibrarySelectors.selectDocument).subscribe(document => this.document = document),
            store.select(GeneralSelectors.selectNavigationSecondExpanded).subscribe(expanded => this.expanded = expanded),
        );
    }

    ngOnDestroy(): void {
        this.#subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    toggle(): void {
        this.store.dispatch(GeneralActions.toggleNavigationSecondExpanded({value: !this.expanded}));
    }

    handleChapterSelectEvent(selected: SingleSelectInterface): void {
        const selectedChapterUuid: string = selected.action;

        if (this.currentChapter?.uuid === selected.action) {
            return;
        }

        const chapter: ChapterProgressInterface | undefined = this.#chapters.find(chapter => chapter.uuid === selectedChapterUuid);

        if (undefined === chapter) {
            throw new Error(`Unable to find chapter with UUID ${selectedChapterUuid}`);
        }

        this.router.navigate(['/books', this.#book?.uuid, 'chapters', selectedChapterUuid, 'documents', chapter.firstDocument]);
    }

    #handleLibrarySelectChapters(chapters?: ChapterProgressInterface[]): void {
        if (undefined === chapters || 0 === chapters.length) {
            return;
        }

        this.#chapters = chapters;
        this.chapterListItems = chapters.map(chapter => ({title: this.isGrantedOrganizationVo ? chapter.title : chapter.moduleId + '. ' + chapter.title, action: chapter.uuid}));
    }
}
