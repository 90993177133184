import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {sprintf} from '@core/core.functions';
import {ApiV2EndpointsEnum} from '@core/enums/api-v2-endpoints.enum';
import {ApiService} from '@core/services/api/api.service';

export enum ExamTypeEnum {
    Remindo = 11,
}

export interface ExamInterface {
    id: number;
    dpsId: string;
    title: string;
    system: number;
    type: ExamTypeEnum;
    url: string;
    exams?: ExamInterface
}

/**
 * @deprecated Remindo exams will be removed in the future
 */
@Injectable({
    providedIn: 'root',
})
export class ExamService {
    #apiService = inject(ApiService);

    startRemindoExam(bookUuid: string, chapterUuid: string, examUuid: string): Observable<ExamInterface> {
        return this.#apiService.get<ExamInterface>(sprintf(ApiV2EndpointsEnum.ExamStartRemindo, bookUuid, chapterUuid, examUuid));
    }
}
