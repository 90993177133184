import {Directive, HostBinding, Input, OnInit} from '@angular/core';

export enum ButtonTypeEnum {
    PrimarySmall,
    PrimaryMedium,
    PrimaryLarge,
    PrimaryInvertedSmall,
    PrimaryInvertedMedium,
    PrimaryInvertedLarge,
    SecondarySmall,
    SecondaryMedium,
    SecondaryLarge,
    WhiteSmall,
    WhiteMedium,
    WhiteLarge,
    GreyOnColorMedium,
    GreyOnColorLarge,
    ModuleColorSmall,
    ModuleColorMedium,
    ModuleColorLarge,
    BreadcrumbSmall,
    BreadcrumbOnColorSmall,
    SourcesLarge,
    NotionsLarge,
    LicenseLarge,
    LicenseOnColorLarge,
    PrimaryVoSmall,
    ManageLicensesLarge,
    WhiteLargeTransparent,
    OrangeLarge,
    OrangeInvertedLarge,
    AddAttachmentMedium
}

/**
 * @deprecated Use classes on where the button is needed instead, this causes too much overhead.
 */
const ButtonTypeMap: Map<ButtonTypeEnum, string[]> = new Map([
    [
        ButtonTypeEnum.PrimarySmall,
        ['btn--primary', 'btn--small'],
    ],
    [
        ButtonTypeEnum.PrimaryVoSmall,
        ['btn--primary-vo', 'btn--small'],
    ],
    [
        ButtonTypeEnum.PrimaryMedium,
        ['btn--primary', 'btn--medium'],
    ],
    [
        ButtonTypeEnum.PrimaryLarge,
        ['btn--primary', 'btn--large'],
    ],
    [
        ButtonTypeEnum.PrimaryInvertedSmall,
        ['btn--primary-inverted', 'btn--small'],
    ],
    [
        ButtonTypeEnum.PrimaryInvertedMedium,
        ['btn--primary-inverted', 'btn--medium'],
    ],
    [
        ButtonTypeEnum.PrimaryInvertedLarge,
        ['btn--primary-inverted', 'btn--large'],
    ],
    [
        ButtonTypeEnum.SecondarySmall,
        ['btn--secondary', 'btn--small'],
    ],
    [
        ButtonTypeEnum.SecondaryMedium,
        ['btn--secondary', 'btn--Medium'],
    ],
    [
        ButtonTypeEnum.SecondaryLarge,
        ['btn--secondary', 'btn--large'],
    ],
    [
        ButtonTypeEnum.WhiteSmall,
        ['btn--white', 'btn--small'],
    ],
    [
        ButtonTypeEnum.WhiteMedium,
        ['btn--white', 'btn--medium'],
    ],
    [
        ButtonTypeEnum.WhiteLarge,
        ['btn--white', 'btn--large'],
    ],
    [
        ButtonTypeEnum.GreyOnColorMedium,
        ['btn--grey-on-color', 'btn--medium'],
    ],
    [
        ButtonTypeEnum.GreyOnColorLarge,
        ['btn--grey-on-color', 'btn--large'],
    ],
    [
        ButtonTypeEnum.ModuleColorSmall,
        ['btn--module-color', 'btn--small'],
    ],
    [
        ButtonTypeEnum.ModuleColorMedium,
        ['btn--module-color', 'btn--medium'],
    ],
    [
        ButtonTypeEnum.ModuleColorLarge,
        ['btn--module-color', 'btn--large'],
    ],
    [
        ButtonTypeEnum.BreadcrumbSmall,
        ['btn--breadcrumb-color', 'btn--small'],
    ],
    [
        ButtonTypeEnum.BreadcrumbOnColorSmall,
        ['btn--breadcrumb-on-color', 'btn--small'],
    ],
    [
        ButtonTypeEnum.AddAttachmentMedium,
        ['btn--add-attachment-color', 'btn--medium'],
    ],
    [
        ButtonTypeEnum.SourcesLarge,
        ['btn--sources-color', 'btn--large'],
    ],
    [
        ButtonTypeEnum.NotionsLarge,
        ['btn--notions-color', 'btn--large'],
    ],
    [
        ButtonTypeEnum.LicenseLarge,
        ['btn--license-color', 'btn--large'],
    ],
    [
        ButtonTypeEnum.LicenseOnColorLarge,
        ['btn--license-on-color', 'btn--large'],
    ],
    [
        ButtonTypeEnum.ManageLicensesLarge,
        ['btn--manage-licenses', 'btn--large'],
    ],
    [
        ButtonTypeEnum.WhiteLargeTransparent,
        ['btn--white-transparent', 'btn--large'],
    ],
    [
        ButtonTypeEnum.OrangeLarge,
        ['btn--orange', 'btn--large'],
    ],
    [
        ButtonTypeEnum.OrangeInvertedLarge,
        ['btn--orange-inverted', 'btn--large'],
    ],
]);

@Directive({
    selector: '[appButton]',
})
export class ButtonDirective implements OnInit {
    @HostBinding('class')
    private classes: string[] = ['btn'];

    @Input()
    @HostBinding('style.--module-color')
    moduleColor?: string;

    @Input()
    @HostBinding('class.btn--disabled')
    public isDisabled: boolean = false;

    @Input()
    @HostBinding('class.btn--hidden')
    public isHidden: boolean = false;

    @Input()
    public buttonType?: ButtonTypeEnum;

    public ngOnInit() {
        const buttonType: ButtonTypeEnum | undefined = this.buttonType;

        if (undefined === buttonType) {
            return;
        }

        this.classes.push(...this.getButtonTypeModifiers(buttonType));
    }

    protected getButtonTypeModifiers(buttonType: ButtonTypeEnum): string[] {
        const buttonTypeModifiers = ButtonTypeMap.get(buttonType);

        if (undefined === buttonTypeModifiers) {
            throw new Error('Unsupported button type');
        }

        return buttonTypeModifiers;
    }
}
