import {createAction, props} from '@ngrx/store';
import {LicenseActions} from '@store/license/license.enum';
import {HttpErrorResponseInterface} from '@core/interfaces/http-error-response.interface';
import {LicenseInterface} from '@store/license/license.reducer';
import {LicenseResultInterface} from '@core/services/license/license.service';

export const activateBookLicensesAction = createAction(LicenseActions.activateBookLicenses, props<{ licenses: string[] }>());
export const activateBookLicensesActionSuccess = createAction(LicenseActions.activateBookLicensesSuccess, props<{ licenses: LicenseInterface[] }>());
export const activateBookLicensesActionFailed = createAction(LicenseActions.activateBookLicensesFailed, props<{ error: HttpErrorResponseInterface }>());

export const processLicensesActionSuccess = createAction(LicenseActions.processLicensesSuccess, props<{ licenseResult: LicenseResultInterface }>());
export const processLicensesActionFailed = createAction(LicenseActions.processLicensesFailed, props<{ error: HttpErrorResponseInterface }>());
