export enum MessageActionsEnum {
    fetchMessages = '[message] fetchMessages',
    fetchMessagesSuccess = '[message] fetchMessagesSuccess',
    fetchMessagesFailed = '[message] fetchMessagesFailed',
    markMessage = '[message] markMessage',
    markMessageSuccess = '[message] markMessageSuccess',
    markMessageFailed = '[message] markMessageFailed',
    deleteMessage = '[message] deleteMessage',
    deleteMessageSuccess = '[message] deleteMessageSuccess',
    deleteMessageFailed = '[message] deleteMessageFailed',
}
