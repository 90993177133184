import {Injectable} from '@angular/core';
import {BehaviorSubject, OperatorFunction, Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import {ResultInterface} from '@shared/interfaces/result.interface';

export interface AlgebrakitSessionInterface {
    dpsId: string;
    sessionId: string;
    active: boolean;
    result?: ResultInterface;
}

@Injectable({
    providedIn: 'root',
})
export class AlgebrakitService {
    private sessionSubject: BehaviorSubject<AlgebrakitSessionInterface | null> = new BehaviorSubject<AlgebrakitSessionInterface | null>(null);

    private finishSubject: BehaviorSubject<AlgebrakitSessionInterface | null> = new BehaviorSubject<AlgebrakitSessionInterface | null>(null);

    private newSubject: BehaviorSubject<AlgebrakitSessionInterface | null> = new BehaviorSubject<AlgebrakitSessionInterface | null>(null);

    public subscribeToSession(dpsId: string, fn: (event: AlgebrakitSessionInterface) => void): Subscription {
        return this.sessionSubject
            .pipe(filter(event => event?.dpsId === dpsId) as OperatorFunction<AlgebrakitSessionInterface | null, AlgebrakitSessionInterface>)
            .subscribe(event => fn(event));
    }

    public setSession(dpsId: string, sessionId: string, active: boolean, result?: ResultInterface): void {
        this.sessionSubject.next({dpsId, sessionId, active, result});
    }

    public subscribeToFinish(dpsId: string, fn: (session: AlgebrakitSessionInterface) => void): Subscription {
        return this.finishSubject
            .pipe(filter(event => dpsId === event?.dpsId) as OperatorFunction<AlgebrakitSessionInterface | null, AlgebrakitSessionInterface>)
            .subscribe(algebraKitSession => fn(algebraKitSession));
    }

    public finish(dpsId: string, sessionId: string): void {
        this.finishSubject.next({dpsId, sessionId, active: true});
    }

    public subscribeToNew(dpsId: string, fn: (session: AlgebrakitSessionInterface) => void): Subscription {
        return this.newSubject
            .pipe(filter(event => dpsId === event?.dpsId) as OperatorFunction<AlgebrakitSessionInterface | null, AlgebrakitSessionInterface>)
            .subscribe(algebraKitSession => fn(algebraKitSession));
    }

    public newSession(session: AlgebrakitSessionInterface): void {
        this.newSubject.next(session);
    }
}
