import {Directive, ElementRef, Input} from '@angular/core';

@Directive({
    selector: '[appVerticalScrollWheel]',
})
export class VerticalScrollWheelDirective {
    @Input()
    private scrollSize: number = 100;

    public constructor(elementRef: ElementRef) {
        const nativeElement: HTMLElement = elementRef.nativeElement;

        this.addScrollListener(nativeElement);
    }

    private addScrollListener(nativeElement: HTMLElement): void {
        nativeElement.addEventListener('wheel', (wheelEvent: WheelEvent) => {
            if (wheelEvent.deltaY < 0) {
                nativeElement.scrollLeft += this.scrollSize;
            } else {
                nativeElement.scrollLeft -= this.scrollSize;
            }

            if (!this.isAtStart(nativeElement) && !this.isAtEnd(nativeElement)) {
                wheelEvent.preventDefault();
            }
        }, false);
    }

    private isAtStart(nativeElement: HTMLElement): boolean {
        return nativeElement.scrollLeft === 0;
    }

    private isAtEnd(nativeElement: HTMLElement): boolean {
        return nativeElement.scrollLeft === (nativeElement.scrollWidth - nativeElement.offsetWidth);
    }
}
