import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ViewContainerRefDirective} from '@component/view-container-ref/view-container-ref.directive';

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        ViewContainerRefDirective,
    ],
    exports: [
        ViewContainerRefDirective,
    ],
})
export class ViewContainerRefModule {
}
