import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatCardModule} from '@angular/material/card';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatDialogModule} from '@angular/material/dialog';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatTabsModule} from '@angular/material/tabs';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatListModule} from '@angular/material/list';
import {MatDividerModule} from '@angular/material/divider';
import {CardComponent} from '@shared/card/card.component';
import {ButtonDirective} from '@shared/button/button.directive';
import {ProgressionBarComponent} from '@shared/progression-bar/progression-bar.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {RouterModule} from '@angular/router';
import {BreadcrumbComponent} from '@shared/breadcrumb/breadcrumb.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {HelpersModule} from '@shared/helpers/helpers.module';
import {TranslateModule} from '@ngx-translate/core';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {MatPaginatorModule} from '@angular/material/paginator';
import {ProgressCircleComponent} from '@shared/progress-circle/progress-circle.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {StatusDotComponent} from '@shared/status-dot/status-dot.component';
import {StarRatingComponent} from '@shared/star-rating/star-rating.component';
import {NgxPaginationModule} from 'ngx-pagination';
import {VerticalScrollWheelDirective} from '@shared/vertical-scroll-wheel/vertical-scroll-wheel.directive';
import {DocumentSelectComponent} from '@shared/document-select/document-select.component';
import {ChapterSelectComponent} from '@shared/chapter-select/chapter-select.component';
import {SingleSelectComponent} from '@shared/single-select/single-select.component';
import {PipeModule} from '@shared/pipe/pipe.module';
import {MultiSelectComponent} from '@shared/multi-select/multi-select.component';
import {GroupUserSelectComponent} from '@shared/group-student-select/group-student-select.component';
import {GroupSelectComponent} from '@shared/group-select/group-select.component';
import {NavigationLicenseComponent} from '@shared/navigation-license/navigation-license.component';
import {LoadingComponent} from '@shared/loading/loading.component';
import {PublisherBrandComponent} from '@shared/publisher-brand/publisher-brand.component';
import {NavigationModuleComponent} from '@shared/navigation-module/navigation-module.component';
import {IconModule} from '@shared/icon/icon.module';
import {StatusDotCheckComponent} from '@shared/status-dot-check/status-dot-check.component';
import {SecurityModule} from '@core/security/security.module';
import {ProgressTextComponent} from '@shared/progress-text/progress-text.component';
import {FaqButtonComponent} from '@shared/fag-button/faq-button.component';
import {TemplateVarModule} from '@component/template-var/template-var.module';
import {CypressModule} from '@component/cypress/cypress.module';
import {UserNameOrMailModule} from '@component/user-name-or-mail/user-name-or-mail.module';
import {MatNativeDateModule} from '@angular/material/core';

const materialModules = [
    MatCardModule,
    MatFormFieldModule,
    MatSnackBarModule,
    MatButtonModule,
    MatInputModule,
    MatToolbarModule,
    MatMenuModule,
    MatIconModule,
    MatTableModule,
    MatSortModule,
    MatDialogModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatListModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatDividerModule,
    MatTooltipModule,
    ClipboardModule,
    MatPaginatorModule,
    MatNativeDateModule,
];

/** @deprecated */
@NgModule({
    imports: [
        ...materialModules,
        PipeModule,
        ReactiveFormsModule,
        CommonModule,
        FlexLayoutModule,
        MatProgressBarModule,
        RouterModule,
        HelpersModule,
        TranslateModule,
        MatProgressSpinnerModule,
        NgxPaginationModule,
        IconModule,
        SecurityModule,
        TemplateVarModule,
        CypressModule,
        UserNameOrMailModule,
    ],
    declarations: [
        CardComponent,
        ProgressionBarComponent,
        ProgressCircleComponent,
        ProgressTextComponent,
        ButtonDirective,
        BreadcrumbComponent,
        NavigationLicenseComponent,
        NavigationModuleComponent,
        GroupSelectComponent,
        GroupUserSelectComponent,
        StatusDotComponent,
        StatusDotCheckComponent,
        StarRatingComponent,
        VerticalScrollWheelDirective,
        ChapterSelectComponent,
        DocumentSelectComponent,
        SingleSelectComponent,
        MultiSelectComponent,
        LoadingComponent,
        PublisherBrandComponent,
        FaqButtonComponent,
    ],
    exports: [
        ReactiveFormsModule,
        ...materialModules,
        IconModule,
        PipeModule,
        CardComponent,
        ButtonDirective,
        BreadcrumbComponent,
        NavigationLicenseComponent,
        NavigationModuleComponent,
        ProgressionBarComponent,
        ProgressCircleComponent,
        ProgressTextComponent,
        TranslateModule,
        DocumentSelectComponent,
        MatProgressSpinnerModule,
        GroupSelectComponent,
        GroupUserSelectComponent,
        StatusDotComponent,
        StatusDotCheckComponent,
        StarRatingComponent,
        VerticalScrollWheelDirective,
        ChapterSelectComponent,
        SingleSelectComponent,
        MultiSelectComponent,
        LoadingComponent,
        PublisherBrandComponent,
        FaqButtonComponent,
        CypressModule,
    ],
})
export class SharedModule {
}
