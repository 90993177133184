import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {UserInterface} from '@shared/interfaces/user.interface';
import {ButtonTypeEnum} from '@shared/button/button.directive';
import {MessageInterface} from '@shared/interfaces/message.interface';
import {MessageActions} from '@store/message';
import {fetchCurrentUserAction} from '@store/security';
import {MessageDeleteDialogComponent} from '@/component/messages/message-delete-dialog/message-delete-dialog.component';
import {Store} from '@ngrx/store';

export interface MessageDialogDataInterface {
    message: MessageInterface;
}

@Component({
    selector: 'app-message-dialog',
    templateUrl: './message-dialog.component.html',
    styleUrls: ['./message-dialog.component.scss'],
})
export class MessageDialogComponent {
    public readonly buttonType = ButtonTypeEnum;

    public user?: UserInterface;

    public isLoading: boolean = false;

    public constructor(
        private dialogRef: MatDialogRef<MessageDialogComponent>,
        private dialog: MatDialog,
        private store: Store,
        @Inject(MAT_DIALOG_DATA) public dialogData: MessageDialogDataInterface,
    ) {
        this.store.dispatch(MessageActions.markMessage({ids: [dialogData.message.id], isRead: true}));
        this.store.dispatch(fetchCurrentUserAction());
        this.store.dispatch(MessageActions.fetchMessages({offset: 0, limit: 15}));

    }

    onCancel(): void {
        this.dialogRef.close();
    }

    openDeleteDialog() {
        this.dialogRef.close();
        this.dialog.open(MessageDeleteDialogComponent, {panelClass: `custom-dialog-container`, data: {
            ids: [this.dialogData.message.id],
            total: 1,
            offset: 0,
            limit: 15,
        }});
    }
}
