import {inject, Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {RoleEnum} from '@core/security/enum/role.enum';
import {AuthorizationService} from '@core/security/services/authorization.service';

@Injectable({
    providedIn: 'root',
})
export class AppTranslateService {
    readonly #translateService = inject(TranslateService);

    readonly #domain: string;

    constructor(authorizationService: AuthorizationService) {
        this.#domain = authorizationService.isGranted(RoleEnum.RoleOrganizationVo) ? 'vo' : 'ho';
    }

    instant(key: string | string[], interpolateParams?: Object): string {
        return this.#translateService.instant(key, interpolateParams);
    }

    instantForPublisher(key: string, interpolateParams?: Object): string {
        return this.instant(this.#getPublisherTranslationKey(key), interpolateParams);
    }

    #getPublisherTranslationKey(key: string): string {
        return `${this.#domain}.${key}`;
    }
}
