import {Action, createReducer, on} from '@ngrx/store';
import {HttpErrorResponseInterface} from '@core/interfaces/http-error-response.interface';
import {MessagesInterface} from '@shared/interfaces/message.interface';
import {MessageActions} from '@store/message/message.actions';

export interface MessageState {
    messages?: MessagesInterface,
    total: number,
    unread: number,
    messagesFailedError?: HttpErrorResponseInterface,
}

const initialState: MessageState = {total: 0, unread: 0};

const reducer = createReducer(
    initialState,
    on(MessageActions.fetchMessagesSuccess, (state, messages) => ({...state, messages})),
    on(MessageActions.markMessageSuccess, (state, messages) => ({...state, messages})),
    on(MessageActions.deleteMessageSuccess, (state, messages) => ({...state, messages})),
);

export const MessageReducer = (
    state: MessageState | undefined,
    action: Action,
): MessageState => {
    return reducer(state, action);
};
