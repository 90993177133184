import {Component, Input, OnDestroy} from '@angular/core';
import {BreadCrumbInterface} from '@shared/interfaces/breadcrumb.interface';
import {ButtonTypeEnum} from '@shared/button/button.directive';
import {selectBreadcrumbBreadcrumb} from '@store/breadcrumb';
import {Store} from '@ngrx/store';
import {Subscription} from 'rxjs';
import {BreadcrumbService} from '@shared/breadcrumb/service/breadcrumb.service';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnDestroy {
    public buttonType = ButtonTypeEnum;

    public bookTitle?: string;

    public breadcrumbs: BreadCrumbInterface[] | undefined;

    private subscriptions: Subscription[] = [];

    public isLoading: boolean = true;

    @Input()
    public breadcrumbButtonTypeStyle: ButtonTypeEnum = ButtonTypeEnum.BreadcrumbSmall;

    public constructor(public store: Store, private breadcrumbService: BreadcrumbService) {
        this.subscriptions.push(
            this.store.select(selectBreadcrumbBreadcrumb).subscribe((breadcrumbState => this.handleBreadcrumbs(breadcrumbState.breadcrumbs))),
        );
    }

    public handleBreadcrumbs(breadcrumbs?: BreadCrumbInterface[]): void {
        if (undefined === breadcrumbs) {
            return;
        }

        const parsedBreadcrumbs: BreadCrumbInterface[] = [];

        breadcrumbs.forEach(breadcrumb => {
            if (undefined !== breadcrumb.translate && breadcrumb.translate) {
                breadcrumb = {...breadcrumb, label: breadcrumb.label, translate: true};
            }

            parsedBreadcrumbs.push(breadcrumb);
        });

        this.breadcrumbs = parsedBreadcrumbs;
        this.isLoading = false;
    }

    breadcrumbPrevious(): void {
        this.breadcrumbService.breadcrumbClick('previous');
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
}
