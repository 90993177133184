import {NgModule} from '@angular/core';
import {NavigationComponent} from '@core/navigation/navigation.component';
import {AvatarComponent} from '@core/navigation/avatar/avatar.component';
import {ChapterTocComponent} from '@core/navigation/chapter-toc/chapter-toc.component';
import {FlexLayoutModule, FlexModule} from '@angular/flex-layout';
import {RouterModule} from '@angular/router';
import {NavItemFactory} from '@core/navigation/factory/nav-item.factory';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';
import {TranslateModule} from '@ngx-translate/core';
import {HelpersModule} from '@shared/helpers/helpers.module';
import {SecurityModule} from '@core/security/security.module';
import {ChapterTocItemComponent} from '@core/navigation/chapter-toc/chapter-toc-item/chapter-toc-item.component';
import {TemplateVarModule} from '@component/template-var/template-var.module';
import {AssignmentModule} from '@component/assignment/assignment.module';

@NgModule({
    declarations: [
        AvatarComponent,
        ChapterTocComponent,
        ChapterTocItemComponent,
        NavigationComponent,
    ],
    exports: [
        NavigationComponent,
    ],
    providers: [
        NavItemFactory,
    ],
    imports: [
        FlexModule,
        RouterModule,
        CommonModule,
        SharedModule,
        TranslateModule,
        FlexLayoutModule,
        SecurityModule,
        HelpersModule,
        TemplateVarModule,
        AssignmentModule,
    ],
})
export class NavigationModule {
}
