import {Component, Inject, OnDestroy} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ButtonTypeEnum} from '@shared/button/button.directive';
import {ActionsSubject, Store} from '@ngrx/store';
import {UserInterface} from '@shared/interfaces/user.interface';
import {Subscription} from 'rxjs';
import {fetchAcceptTermsAction, SecurityActions, selectCurrentUser} from '@store/security';
import {ofType} from '@ngrx/effects';
import {HttpErrorResponseInterface} from '@core/interfaces/http-error-response.interface';
import {OauthService} from '@core/security/services/oauth.service';
import {RoleEnum} from '@core/security/enum/role.enum';
import {AuthorizationService} from '@core/security/services/authorization.service';

@Component({
    selector: 'app-terms-and-conditions-dialog',
    templateUrl: './terms-and-conditions-dialog.component.html',
    styleUrls: ['./terms-and-conditions-dialog.component.scss'],
})
export class DialogTermsAndConditionsComponent implements OnDestroy {
    public readonly buttonType = ButtonTypeEnum;

    public readonly roleEnum = RoleEnum;

    public termToggle: boolean = false;

    public submit: boolean = false;

    public profile?: UserInterface;

    public isRoleHO: boolean;

    private subscriptions: Subscription[] = [];

    public constructor(
        private dialogRef: MatDialogRef<DialogTermsAndConditionsComponent>,
        @Inject(MAT_DIALOG_DATA) public dialogData: any,
        public store: Store,
        actionsSubject: ActionsSubject,
        private oauthService: OauthService,
        private authorizationService: AuthorizationService,
    ) {
        dialogRef.disableClose = true;

        this.subscriptions.push(
            store.select(selectCurrentUser).subscribe(user => this.handleUserProfile(user)),
            actionsSubject.pipe(ofType(SecurityActions.updateAcceptTermsActionSuccess)).subscribe(() => this.handleActivationSuccess()),
            actionsSubject.pipe(ofType(SecurityActions.updateAcceptTermsActionFailed)).subscribe(error => this.handleHttpErrorResponse(error)),
        );

        this.isRoleHO = this.authorizationService.isGranted(RoleEnum.RoleOrganizationHo);
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    public toggleTerms(): void {
        this.termToggle = !this.termToggle;
    }

    public onCancel(): void {
        this.oauthService.logout()
    }

    public onSubmit(): void {
        this.submit = true;

        if (!this.termToggle) {
            return;
        }
        this.store.dispatch(fetchAcceptTermsAction());
    }

    private handleActivationSuccess(): void {
        this.dialogRef.close();
    }

    private handleHttpErrorResponse(apiErrorResponse?: HttpErrorResponseInterface): void {
        if (undefined === apiErrorResponse) {
            return;
        }
    }

    private handleUserProfile(user?: UserInterface): void {
        if (undefined === user) {
            return;
        }

        this.profile = user;
    }
}
