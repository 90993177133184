import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActionsSubject, Store} from '@ngrx/store';
import {Subscription} from 'rxjs';
import {LibrarySelectors} from '@store/library';
import {BookDetailInterface} from '@shared/interfaces/book.interface';
import {ActivatedRoute, Router} from '@angular/router';
import {SingleSelectInterface} from '@shared/interfaces/single-select.interface';
import {ChapterProgressInterface} from '@shared/interfaces/chapter.interface';

@Component({
    selector: 'app-navigation-module',
    templateUrl: './navigation-module.component.html',
    styleUrls: ['./navigation-module.component.scss'],
})
export class NavigationModuleComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];

    @Input()
    public activeBook?: string;

    @Input()
    public activeChapter?: string;

    @Input()
    public type?: string;

    public chapters: ChapterProgressInterface[] = [];

    public book?: BookDetailInterface;

    public bookUuid?: string;

    public selected?: string = '';

    public list: SingleSelectInterface[] = [];

    public selectedChapterUuid: string | undefined;

    public constructor(
        private activatedRoute: ActivatedRoute,
        private readonly router: Router,
        public actionsSubject: ActionsSubject,
        public store: Store,
    ) {
    }

    public ngOnInit(): void {
        this.subscriptions.push(
            this.store.select(LibrarySelectors.selectChapters).subscribe((chapters => this.handleSelectLibraryChapters(chapters))),
        );
    }

    private handleSelectLibraryChapters(chapters?: ChapterProgressInterface[]): void {
        if (undefined === chapters || 0 === chapters.length) {
            return;
        }

        this.chapters = chapters

        for (let i = 0; i <= chapters.length; i++) {
            if (chapters[i] !== undefined && chapters.length !== this.list.length) {
                this.list.push({title: chapters[i].title, action: chapters[i].uuid});
            }

            if (chapters[i] !== undefined && this.activeChapter === chapters[i]?.uuid) {
                this.selected = chapters[i].title;
            }
        }
    }

    public interactModuleNavigation(route: SingleSelectInterface): void {
        this.selectedChapterUuid = route.action;

        if (this.selectedChapterUuid !== undefined) {
            for (let i = 0; i <= this.chapters.length; i++) {
                if (this.activeBook && this.chapters[i].uuid && this.chapters[i].uuid === this.selectedChapterUuid) {
                    this.router.navigate(['/books', this.activeBook, 'chapters', this.selectedChapterUuid, 'documents', this.chapters[i].firstDocument]);
                    this.selectedChapterUuid = undefined;
                }
            }
        }
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
}
