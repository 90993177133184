import {Component, HostBinding, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {Store} from '@ngrx/store';
import {Subscription} from 'rxjs';
import {BreakpointObserver, Breakpoints, BreakpointState} from '@angular/cdk/layout';
import {DomSanitizer, SafeStyle} from '@angular/platform-browser';
import {GeneralSelectors} from '@store/general';

@Component({
    selector: 'app-container-header',
    templateUrl: './container-header.component.html',
    styleUrls: ['./container-header.component.scss'],
})
export class ContainerHeaderComponent implements OnDestroy, OnChanges {
    private readonly subscriptions: Subscription[] = [];

    @HostBinding('class')
    private readonly classes: string[] = ['container-header'];

    @Input()
    @HostBinding('class.container-header--offset')
    offsetNext?: boolean;

    @HostBinding('class.container-header--has-background')
    private hasBackground: boolean = false;

    @HostBinding('style.background-image')
    private backgroundImage?: SafeStyle;

    @Input()
    public header: boolean = true;

    @Input()
    public breadcrumb: boolean = true;

    @Input()
    public heading: boolean = true;

    @Input()
    public content: boolean = true;

    @Input()
    public background?: string;

    @Input()
    public lessMargin: boolean = false;

    public tableOfContentsVisible: boolean = false;

    public constructor(private domSanitizer: DomSanitizer, store: Store, breakpointObserver: BreakpointObserver) {
        this.subscriptions.push(
            breakpointObserver.observe(Breakpoints.XSmall).subscribe(breakpointState => this.handleXSmallBreakpointState(breakpointState)),
            store.select(GeneralSelectors.selectNavigationSecondVisible).subscribe(isVisible => this.tableOfContentsVisible = isVisible),
        );
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (undefined !== changes.background) {
            const background: string | undefined = changes.background.currentValue ?? undefined;
            const hasBackground: boolean = this.hasBackground = undefined !== background;

            this.backgroundImage = hasBackground ? this.domSanitizer.bypassSecurityTrustStyle(`url(${changes.background.currentValue})`) : undefined;
        }
    }

    private handleXSmallBreakpointState(isXSmall: BreakpointState): void {
        if (undefined === this.offsetNext) {
            this.offsetNext = !isXSmall.matches;
        }
    }
}
