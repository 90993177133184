import {Component, HostBinding, Input} from '@angular/core';
import {RoleEnum} from '@core/security/enum/role.enum';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
    @HostBinding('class')
    private readonly classes: string[] = ['header'];

    @Input()
    public bookUuid?: string;

    @Input()
    public profile: boolean = false;

    public readonly roleEnum = RoleEnum;
}
