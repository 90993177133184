import {Pipe, PipeTransform} from '@angular/core';
import {UserBaseInterface} from '@shared/interfaces/user.interface';

@Pipe({
    name: 'userFullNamePipe',
})
export class UserFullNamePipe implements PipeTransform {
    transform(user: UserBaseInterface): string {
        return !user.firstName || !user.lastName ? user.email : (user.firstName + ' ' + (user.middleName ? user.middleName + ' ' : '') + user.lastName);
    }
}
