import {createFeatureSelector, createSelector} from '@ngrx/store';
import {StoreFeatureSelectorEnum} from '@store/store.enum';
import {SecurityState} from '@store/security/security.reducer';
import {UserAuthenticatedInterface} from '@shared/interfaces/user.interface';

export class SecuritySelectors {
    public static readonly selectSecurityState = createFeatureSelector<SecurityState>(
        StoreFeatureSelectorEnum.Security,
    );

    public static readonly selectCurrentUser = createSelector(
        SecuritySelectors.selectSecurityState,
        (state: SecurityState) => state.currentUser,
    );

    public static readonly selectExamCounts = createSelector(
        SecuritySelectors.selectCurrentUser,
        (currentUser?: UserAuthenticatedInterface) => currentUser?.examCounts,
    );
}

/**
 * @deprecated use SecuritySelectors.selectCurrentUser instead
 */
export const selectCurrentUser = SecuritySelectors.selectCurrentUser;
