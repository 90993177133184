import {Action, createReducer, on} from '@ngrx/store';
import {SelfExamineActions} from '@store/self-examine/self-examine.actions';
import {SelfExamineInterface} from '@shared/interfaces/self-examine.interface';

export interface SelfExamineState {
    selfExamine?: SelfExamineInterface;
}

const initialState: SelfExamineState = {};

const reducer = createReducer(
    initialState,
    on(SelfExamineActions.createSuccess, (state, selfExamine) => ({...state, selfExamine})),
);

export const SelfExamineReducer = (
    state: SelfExamineState | undefined,
    action: Action,
): SelfExamineState => {
    return reducer(state, action);
};
