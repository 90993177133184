import {ApiDocumentDataInterface} from '@shared/modules/content/interfaces/api_v1';
import * as _ from 'lodash';

export class AssignmentLegacyDocumentMap extends Map<string, ApiDocumentDataInterface> {
    public insert(key: string, value: ApiDocumentDataInterface): this {
        super.set(key, value);

        return _.clone(this);
    }
}
