import {createFeatureSelector, createSelector} from '@ngrx/store';
import {StoreFeatureSelectorEnum} from '@store/store.enum';
import {GroupState} from '@store/group/group.reducer';
import {GroupMap} from '@store/group/group.map';
import {GroupInterface} from '@shared/interfaces/group.interface';
import {GroupTypeHelper} from '@store/group/group.helpers';

export class GroupSelectors {
    public static readonly getGroupState = createFeatureSelector<GroupState>(StoreFeatureSelectorEnum.Group);

    public static readonly selectGroups = createSelector(GroupSelectors.getGroupState, (state: GroupState) => state.groups);

    public static readonly selectGroup = createSelector(GroupSelectors.getGroupState, (state: GroupState) => state.group);

    public static readonly selectById = (groupId: number) => createSelector(
        GroupSelectors.selectGroups,
        (groups: GroupMap) => groups.getGroupById(groupId),
    );

    public static selectSelfExamineByGroupIdAndUserId = (groupId: number, userId: number) => createSelector(
        GroupSelectors.selectById(groupId),
        (group?: GroupInterface) => GroupTypeHelper.isTeacherSelfExamineGroup(group)
            ? group.selfExamine.users.find(selfExamineUser => userId === selfExamineUser.user.id) : undefined,
    );

    public static readonly selectOrganizationGroups = createSelector(
        GroupSelectors.getGroupState,
        (state: GroupState) => state.organizationGroup,
    );

    public static readonly selectGroupsAsArray = createSelector(
        GroupSelectors.getGroupState,
        (state: GroupState) => state.groups.toArray(),
    );
}
