import {Store} from '@ngrx/store';
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {OrganizationActionsEnum} from '@store/organization/organization.enum';
import {OrganizationService} from '@core/services/organization/organization.service';
import {OrganizationActions} from '@store/organization/organization.actions';
import {LicenseActions} from '@store/license';
import {LicenseResultInterface} from '@core/services/license/license.service';
import {SecurityActions} from '@store/security';
import {UserAuthenticatedInterface} from '@shared/interfaces/user.interface';

@Injectable()
export class OrganizationEffects {
    #store: Store;

    #organizationService: OrganizationService;

    public constructor(private actions$: Actions, store: Store, organizationService: OrganizationService) {
        this.#store = store;
        this.#organizationService = organizationService;
    }

    updateLoadedCurrentUser$ = createEffect(() => this.actions$.pipe(
        ofType(SecurityActions.updateLoadedCurrentUser),
        switchMap((properties: { currentUser: UserAuthenticatedInterface }) => this.#organizationService.displayDialogByHasTeacherLicense(properties.currentUser.hasTeacherEan ?? false).pipe(
            map(result => OrganizationActions.displayDialogByHasTeacherLicenseSuccess({result})),
            catchError(error => of(OrganizationActions.displayDialogByHasTeacherLicenseFailed({error}))),
        )),
    ));

    processBookLicensesActionSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(LicenseActions.processLicensesSuccess),
        switchMap((properties: { licenseResult: LicenseResultInterface}) => this.#organizationService.displayDialogByHasTeacherLicense(properties.licenseResult.hasTeacherLicense).pipe(
            map(result => OrganizationActions.displayDialogByHasTeacherLicenseSuccess({result})),
            catchError(error => of(OrganizationActions.displayDialogByHasTeacherLicenseFailed({error}))),
        )),
    ));

    fetchOrganizations$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(OrganizationActionsEnum.fetchOrganizations),
            switchMap(() => this.#organizationService.retrieveOrganizations().pipe(
                map(organizations => OrganizationActions.fetchOrganizationsSuccess({organizations})),
                catchError(error => of(OrganizationActions.fetchOrganizationsFailed({error}))),
            )),
        )
    });

    requestOrganization$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(OrganizationActionsEnum.requestOrganization),
            switchMap((properties: {name: string}) => this.#organizationService.requestOrganization(properties.name).pipe(
                map(organization => OrganizationActions.requestOrganizationSuccess({organization})),
                catchError(error => of(OrganizationActions.requestOrganizationFailed({error}))),
            )),
        )
    });
}
