import {Pipe, PipeTransform} from '@angular/core';
import {prefixAssignmentTitle} from '../function/title.function';

@Pipe({
    name: 'prefixAssignmentTitle',
})
export class PrefixAssignmentTitlePipe implements PipeTransform {
    transform(value: string, prefix?: string): string {
        return prefixAssignmentTitle(value, prefix);
    }
}
