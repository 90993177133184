import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {AuthorizationService} from '@core/security/services/authorization.service';
import {RoleEnum} from '@core/security/enum/role.enum';
import {AbstractIsGrantedDirective} from '@core/security/directive/abstract-is-granted.directive';

@Directive({
    selector: '[appIsGranted]',
})
export class IsGrantedDirective<T = unknown> extends AbstractIsGrantedDirective {
    private roles: RoleEnum[] = [];

    @Input()
    public set appIsGranted(role: RoleEnum | RoleEnum[] | undefined) {
        if (undefined === role) {
            return;
        }

        this.roles = role instanceof Array ? role : [role];
    }

    @Input()
    public set appIsGrantedElse(templateRef: TemplateRef<T> | null) {
        this.isGrantedElse = templateRef;
    }

    public constructor(
        authorizationService: AuthorizationService,
        viewContainer: ViewContainerRef,
        templateRef: TemplateRef<T>,
    ) {
        super(authorizationService, viewContainer, templateRef);
    }

    protected getRoles(): RoleEnum[] {
        return this.roles;
    }
}
