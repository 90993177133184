import {NgModule} from '@angular/core';
import {PaginationMappedPageLabelPipe} from '@component/pagination/pagination.pipe';
import {PaginationComponent} from '@component/pagination/pagination.component';
import {NgxPaginationModule} from 'ngx-pagination';
import {CommonModule} from '@angular/common';
import {TemplateVarModule} from '@component/template-var/template-var.module';
import {IconModule} from '@shared/icon/icon.module';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        NgxPaginationModule,
        TemplateVarModule,
        IconModule,
    ],
    declarations: [
        PaginationMappedPageLabelPipe,
        PaginationComponent,
    ],
    exports: [
        NgxPaginationModule,
        PaginationComponent,
        TranslateModule,
    ],
})
export class PaginationModule {
}
