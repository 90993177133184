import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CodeBlockComponent} from '@component/code-language/code-block/code-block.component';
import {CodeInlineComponent} from '@component/code-language/code-inline/code-inline.component';

@NgModule({
    declarations: [
        CodeBlockComponent,
        CodeInlineComponent,
    ],
    exports: [
        CodeBlockComponent,
        CodeInlineComponent,
    ],
    imports: [
        CommonModule,
    ],
})
export class CodeLanguageModule {
}
