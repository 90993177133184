import {createFeatureSelector, createSelector} from '@ngrx/store';
import {StoreFeatureSelectorEnum} from '@store/store.enum';
import {LibraryState} from '@store/library';
import {DocumentDetailsInterface, DocumentInterface} from '@shared/interfaces/document.interface';
import {BookDetailInterface, BookInterface} from '@shared/interfaces/book.interface';
import {ChapterInterface, ChapterProgressInterface} from '@shared/interfaces/chapter.interface';
import {ExamInterface} from '@shared/modules/content/services/exam/exam.service';
import {ExamSelectors} from '@store/exam';
import {DocumentSourceDataInterface} from '@core/services/source/source.service';

export interface LibraryContextInterface {
    bookUuid?: string;
    chapterUuid?: string;
    documentDpsId?: string;
    examDpsId?: string;
    showToc?: boolean;
}

export class LibrarySelectors {
    public static readonly getState = createFeatureSelector<LibraryState>(
        StoreFeatureSelectorEnum.Library,
    );

    // Table of contents
    public static readonly selectShowToc = createSelector(
        LibrarySelectors.getState,
        (state: LibraryState) => state.showToc,
    );

    // Book selectors
    static readonly selectBooks = createSelector(
        LibrarySelectors.getState,
        (state: LibraryState) => state.bookList?.books,
    );

    static readonly selectTotalBooks = createSelector(
        LibrarySelectors.getState,
        (state: LibraryState) => state.bookList?.total,
    );

    static readonly selectBook = createSelector(
        LibrarySelectors.getState,
        (state: LibraryState) => state.book,
    );

    static readonly selectBookSelfExamine = createSelector(
        LibrarySelectors.getState,
        (state: LibraryState) => undefined !== state.book?.selfExamine && (null === state.book.selfExamine || state.book.selfExamine.selfExamine),
    );

    // Chapter selectors
    static readonly selectBookChapters = createSelector(
        LibrarySelectors.selectBook,
        (book?: BookDetailInterface) => book?.chapters,
    );

    static readonly selectChapters = createSelector(
        LibrarySelectors.getState,
        (state: LibraryState) => state.chapters,
    );

    static readonly selectChapter = createSelector(
        LibrarySelectors.getState,
        (state: LibraryState) => state.chapter,
    );

    static readonly selectChapterToc = createSelector(
        LibrarySelectors.selectBook,
        LibrarySelectors.selectChapter,
        (book?: BookDetailInterface, chapter?: ChapterInterface) => {
            if (undefined === book || undefined === chapter) {
                return undefined;
            }

            return book.tableOfContents.find(tableOfContents => tableOfContents.uuid === chapter.uuid);
        },
    );

    static readonly selectLastVisitedOrFirstChapter = createSelector(
        LibrarySelectors.selectChapters,
        (chapters?: ChapterProgressInterface[]): ChapterProgressInterface | undefined => {
            if (undefined === chapters) {
                return;
            }

            const chaptersWithLastVisited = chapters.filter(listItem => null !== listItem.values.lastVisited);

            return (0 !== chaptersWithLastVisited.length) ? chaptersWithLastVisited.reduce((current: ChapterProgressInterface, previous: ChapterProgressInterface) => {
                const currentLastVisited: string | undefined = current.values.lastVisited?.dateTime ?? undefined;
                const previousLastVisited: string | undefined = previous.values.lastVisited?.dateTime ?? undefined;

                if (undefined === currentLastVisited || undefined === previousLastVisited) {
                    return previous;
                }

                return new Date(currentLastVisited) > new Date(previousLastVisited) ? current : previous;
            }) : chapters[0];
        },
    )

    // Document selectors
    static readonly selectDocument = createSelector(
        LibrarySelectors.getState,
        (state: LibraryState) => state.document,
    );

    static readonly selectLegacyDocument = createSelector(
        LibrarySelectors.selectDocument,
        (document?: DocumentDetailsInterface) => document?.legacyDocument ?? undefined,
    );

    // Document source selectors
    static readonly selectDocumentSourceData = createSelector(
        LibrarySelectors.getState,
        (state: LibraryState) => state.documentSourceData,
    );

    static readonly selectDocumentSourceDataSources = createSelector(
        LibrarySelectors.selectDocumentSourceData,
        (documentSources: DocumentSourceDataInterface | undefined) => documentSources?.sources,
    )

    static readonly selectDocumentSourceDataTotalsByType = createSelector(
        LibrarySelectors.selectDocumentSourceData,
        (documentSources: DocumentSourceDataInterface | undefined) => documentSources?.totalsByType,
    )

    // Teacher Sources
    static readonly selectTeacherSourceData = createSelector(
        LibrarySelectors.getState,
        (state: LibraryState) => state.teacherSourceData,
    );

    // Context selector
    static readonly selectContext = createSelector(
        LibrarySelectors.selectBook,
        LibrarySelectors.selectChapter,
        LibrarySelectors.selectDocument,
        ExamSelectors.selectRemindoExam,
        LibrarySelectors.selectShowToc,
        (book?: BookInterface, chapter?: ChapterInterface, document?: DocumentInterface, exam?: ExamInterface, showToc?: boolean): LibraryContextInterface => {
            return {
                bookUuid: book?.uuid,
                chapterUuid: chapter?.uuid,
                documentDpsId: document?.dpsId || exam?.dpsId,
                showToc: showToc,
            }
        },
    );
}
