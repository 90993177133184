import {createAction, props} from '@ngrx/store';
import {GroupActionsEnum} from '@store/group/group.enum';
import {GroupFailedPropertiesInterface, GroupPropertiesInterface} from '@store/group/group.properties';
import {GroupInterface} from '@shared/interfaces/group.interface';
import {UserAuthenticatedInterface} from '@shared/interfaces/user.interface';
import {BookDetailInterface} from '@shared/interfaces/book.interface';

export class GroupActions {
    public static readonly clearAll = createAction(GroupActionsEnum.ClearAll);

    public static readonly setGroup = createAction(GroupActionsEnum.SetGroup, props<GroupInterface>());

    public static readonly clearGroup = createAction(GroupActionsEnum.ClearGroup);

    public static readonly fetchOrganizationGroups = createAction(GroupActionsEnum.FetchOrganizationGroups, props<{ bookUuid?: string }>());

    public static readonly fetchOrganizationGroupsSuccess = createAction(GroupActionsEnum.FetchOrganizationGroupsSuccess, props<{ organizationGroup: GroupInterface[] }>());

    public static readonly fetchGroups = createAction(GroupActionsEnum.FetchGroups, props<{ bookUuid?: string, active?: boolean }>());

    public static readonly fetchGroupsSuccess = createAction(GroupActionsEnum.FetchGroupsSuccess, props<{ groups: GroupInterface[] }>());

    public static readonly createGroup = createAction(GroupActionsEnum.CreateGroups, props<{ groupName: string, bookUuid?: string }>());

    public static readonly createGroupSuccess = createAction(GroupActionsEnum.CreateGroupsSuccess, props<GroupInterface>());

    public static readonly createGroupFailed = createAction(GroupActionsEnum.CreateGroupsFailed, props<GroupFailedPropertiesInterface>());

    public static readonly joinGroups = createAction(GroupActionsEnum.JoinGroups, props<{ codes: string[], book?: BookDetailInterface, user?: UserAuthenticatedInterface }>());

    public static readonly joinGroupsSuccess = createAction(GroupActionsEnum.JoinGroupsSuccess, props<{ groups: GroupInterface[], user: UserAuthenticatedInterface }>());

    public static readonly joinGroupsFailed = createAction(GroupActionsEnum.JoinGroupsFailed, props<GroupFailedPropertiesInterface>());

    public static readonly renewGroupCodeSuccess = createAction(GroupActionsEnum.RenewGroupCodeSuccess, props<GroupInterface>());

    public static readonly renewGroupCodeFailed = createAction(GroupActionsEnum.RenewGroupCodeFailed, props<GroupFailedPropertiesInterface>());

    public static readonly renewGroupCode = createAction(GroupActionsEnum.RenewGroupCode, props<GroupPropertiesInterface>());

    public static readonly updateGroupStatus = createAction(GroupActionsEnum.UpdateGroupStatus, props<{ groupId: number, active: boolean}>());

    public static readonly updateGroupStatusSuccess = createAction(GroupActionsEnum.UpdateGroupStatusSuccess, props<GroupInterface>());

    public static readonly updateGroupName = createAction(GroupActionsEnum.UpdateGroupName, props<{ groupId: number, groupName: string }>());

    public static readonly updateGroupNameSuccess = createAction(GroupActionsEnum.UpdateGroupNameSuccess, props<GroupInterface>());

    public static readonly updateGroupNameFailed = createAction(GroupActionsEnum.UpdateGroupNameFailed, props<GroupFailedPropertiesInterface>());

    public static readonly removeGroupUser = createAction(GroupActionsEnum.RemoveGroupUser, props<{ groupId?: number, userId: number }>());

    public static readonly removeGroupUserSuccess = createAction(GroupActionsEnum.RemoveGroupUserSuccess, props<{ groupId: number, userId: number }>());

    public static readonly removeGroupUserFailed = createAction(GroupActionsEnum.RemoveGroupUserFailed, props<GroupFailedPropertiesInterface>());
}
