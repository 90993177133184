import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {environment} from '@environment/environment';
import {AppModule} from '@/app.module';

console.debug = function(...args: any[]): void {
    if (!environment.debug) {
        return;
    }

    console.log(...args);
}

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.error(err));
