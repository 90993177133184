import {createAction, props} from '@ngrx/store';
import {SelectionActionsEnum} from '@store/selection/selection.enum';
import {SelectionPropertiesInterface} from '@store/selection/selection.properties';
import {ChapterBookDetailsInterface} from '@shared/interfaces/chapter.interface';
import {DocumentInterface} from '@shared/interfaces/document.interface';
import {UserInterface} from '@shared/interfaces/user.interface';
import {GroupInterface} from '@shared/interfaces/group.interface';

export class SelectionActions {
    public static readonly clearAll = createAction(SelectionActionsEnum.ClearAll);

    public static readonly setChapter = createAction(SelectionActionsEnum.SetChapter, props<SelectionPropertiesInterface<ChapterBookDetailsInterface>>());

    public static readonly clearChapter = createAction(SelectionActionsEnum.ClearChapter);

    public static readonly setDocument = createAction(SelectionActionsEnum.SetDocument, props<SelectionPropertiesInterface<DocumentInterface>>());

    public static readonly clearDocument = createAction(SelectionActionsEnum.ClearDocument);

    public static readonly setGroup = createAction(SelectionActionsEnum.SetGroup, props<SelectionPropertiesInterface<GroupInterface>>());

    public static readonly clearGroup = createAction(SelectionActionsEnum.ClearGroup);

    public static readonly setUser = createAction(SelectionActionsEnum.SetUser, props<SelectionPropertiesInterface<UserInterface>>());

    public static readonly clearUser = createAction(SelectionActionsEnum.ClearUser);
}
