import {createFeatureSelector, createSelector} from '@ngrx/store';
import {StoreFeatureSelectorEnum} from '@store/store.enum';
import {GeneralState} from '@store/general/general.reducer';
import {LibrarySelectors} from '@store/library';
import {PublisherInterface} from '@shared/interfaces/publisher.interface';
import {BookDetailInterface} from '@shared/interfaces/book.interface';
import {DocumentDetailsInterface} from '@shared/interfaces/document.interface';
import {TableOfContentsInterface} from '@shared/interfaces/table-of-contents.interface';
import {ExamSelectors} from '@store/exam';
import {ExamInterface} from '@shared/modules/content/services/exam/exam.service';
import {SecuritySelectors} from '@store/security';
import {UserAuthenticatedInterface, UserLocaleInterface, UserSettingInterface} from '@shared/interfaces/user.interface';
import {GeneralBookAndUserModuleVisitedInterface} from '@store/general/general.contract';
import {GeneralUserSettingType} from '@store/general/general.enum';

export class GeneralSelectors {
    public static readonly getGeneralState = createFeatureSelector<GeneralState>(
        StoreFeatureSelectorEnum.General,
    );

    public static readonly selectPublisher = createSelector(
        GeneralSelectors.getGeneralState,
        (state: GeneralState) => state?.publisher,
    );

    public static readonly selectNavigationSecondExpanded = createSelector(
        GeneralSelectors.getGeneralState,
        (state: GeneralState) => state.navigationSecondExpanded,
    );

    public static readonly selectPublisherAndBook = createSelector(
        GeneralSelectors.selectPublisher,
        LibrarySelectors.selectBook,
        (publisher?: PublisherInterface, book?: BookDetailInterface) => ({publisher, book}),
    );

    public static readonly selectCurrentUserLocaleSettings = createSelector(
        SecuritySelectors.selectCurrentUser,
        (user?: UserAuthenticatedInterface): UserSettingInterface<UserLocaleInterface> | undefined => {
            return user?.settings.find(
                (setting: UserSettingInterface) => setting.type === GeneralUserSettingType.Locale,
            ) as UserSettingInterface<UserLocaleInterface> | undefined;
        },
    );

    public static readonly selectBookAndCurrentUserModuleVisitedSetting = createSelector(
        LibrarySelectors.selectBook,
        SecuritySelectors.selectCurrentUser,
        (book?: BookDetailInterface, user?: UserAuthenticatedInterface): GeneralBookAndUserModuleVisitedInterface | undefined => {
            // Get the module visited setting if it exists
            const setting: UserSettingInterface<string[]> | undefined = user?.settings.find(
                setting => setting.type === GeneralUserSettingType.ModuleVisited,
            ) as UserSettingInterface<string[]>;

            return {book, setting};
        },
    );

    public static readonly selectNavigationSecondVisible = createSelector(
        LibrarySelectors.selectDocument,
        LibrarySelectors.selectChapterToc,
        ExamSelectors.selectRemindoExam,
        (document?: DocumentDetailsInterface, tableOfContents?: TableOfContentsInterface, exam?: ExamInterface) => (undefined !== document || undefined !== exam) && undefined !== tableOfContents,
    );
}
