import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class OauthService {
    public logout(): void {
        window.location.href = '/security/keycloak/logout';
    }
}
