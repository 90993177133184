import {createAction, props} from '@ngrx/store';
import {AssignmentActionsEnum} from '@store/assignment/assignment.enum';
import {
    AssignmentAnswerFailedPropertiesInterface,
    AssignmentAnswerPropertiesInterface,
    AssignmentFeedbackCreatePropertiesInterface,
    AssignmentFeedbackRespondPropertiesInterface,
    AssignmentPropertiesInterface,
    AssignmentReviewPropertiesInterface,
    AssignmentUserPropertiesInterface,
} from '@store/assignment/assignment.properties';
import {AssignmentAnswerInterface, AssignmentInterface} from '@shared/interfaces/assignment.interface';
import {AssignmentDocumentDetailsInterface} from '@core/services/assignment/assignment.service';
import {AssignmentContextInterface} from '@store/assignment/assignment.reducer';
import {PropertiesErrorResponseInterface} from '@store/interfaces/properties.interface';

export class AssignmentActions {
    static readonly remove = createAction(AssignmentActionsEnum.Remove, props<AssignmentInterface>());

    static readonly fetchByAssignmentContext = createAction(AssignmentActionsEnum.FetchByAssignmentContext, props<AssignmentContextInterface>());

    static readonly fetchByAssignmentContextSuccess = createAction(AssignmentActionsEnum.FetchByAssignmentContextSuccess, props<{ document: AssignmentDocumentDetailsInterface }>());

    static readonly fetchByAssignmentContextFailed = createAction(AssignmentActionsEnum.FetchByAssignmentContextFailed, props<PropertiesErrorResponseInterface<AssignmentContextInterface>>());

    static readonly saveAnswer = createAction(AssignmentActionsEnum.SaveAnswer, props<{ assignmentAnswer: AssignmentAnswerInterface }>());

    static readonly saveAnswerSuccess = createAction(AssignmentActionsEnum.SaveAnswerSuccess, props<AssignmentAnswerPropertiesInterface<any>>());

    static readonly saveAnswerFailed = createAction(AssignmentActionsEnum.SaveAnswerFailed, props<AssignmentAnswerFailedPropertiesInterface<any>>());

    static readonly removeAnswers = createAction(AssignmentActionsEnum.RemoveAnswers, props<AssignmentUserPropertiesInterface>());

    static readonly removeAnswersSuccess = createAction(AssignmentActionsEnum.RemoveAnswersSuccess, props<AssignmentInterface>());

    static readonly removeAnswersFailed = createAction(AssignmentActionsEnum.RemoveAnswersFailed, props<PropertiesErrorResponseInterface<AssignmentUserPropertiesInterface>>());

    static readonly handIn = createAction(AssignmentActionsEnum.HandIn, props<AssignmentPropertiesInterface>());

    static readonly handInSuccess = createAction(AssignmentActionsEnum.HandInSuccess, props<AssignmentInterface>());

    static readonly handInFailed = createAction(AssignmentActionsEnum.HandInFailed, props<PropertiesErrorResponseInterface<AssignmentPropertiesInterface>>());

    static readonly autoCheck = createAction(AssignmentActionsEnum.AutoCheck, props<AssignmentPropertiesInterface>());

    static readonly autoCheckSuccess = createAction(AssignmentActionsEnum.AutoCheckSuccess, props<AssignmentInterface>());

    static readonly autoCheckFailed = createAction(AssignmentActionsEnum.AutoCheckFailed, props<PropertiesErrorResponseInterface<AssignmentPropertiesInterface>>());

    static readonly saveReview = createAction(AssignmentActionsEnum.SaveReview, props<AssignmentReviewPropertiesInterface>());

    static readonly saveReviewSuccess = createAction(AssignmentActionsEnum.SaveReviewSuccess, props<AssignmentInterface>());

    static readonly saveReviewFailed = createAction(AssignmentActionsEnum.SaveReviewFailed, props<PropertiesErrorResponseInterface<AssignmentReviewPropertiesInterface>>());

    static readonly createFeedback = createAction(AssignmentActionsEnum.CreateFeedback, props<AssignmentFeedbackCreatePropertiesInterface>());

    static readonly createFeedbackSuccess = createAction(AssignmentActionsEnum.CreateFeedbackSuccess, props<{ document: AssignmentDocumentDetailsInterface }>());

    static readonly createFeedbackFailed = createAction(AssignmentActionsEnum.CreateFeedbackFailed, props<PropertiesErrorResponseInterface<AssignmentFeedbackCreatePropertiesInterface>>());

    static readonly respondFeedback = createAction(AssignmentActionsEnum.RespondFeedback, props<AssignmentFeedbackRespondPropertiesInterface>());

    static readonly respondFeedbackSuccess = createAction(AssignmentActionsEnum.RespondFeedbackSuccess, props<{ document: AssignmentDocumentDetailsInterface }>());

    static readonly respondFeedbackFailed = createAction(AssignmentActionsEnum.RespondFeedbackFailed, props<PropertiesErrorResponseInterface<AssignmentFeedbackRespondPropertiesInterface>>());
}
