import {trigger, state, style, animate, transition} from '@angular/animations';

export const Animations = {
    expandMenu: trigger('expandMenu', [
        state('expand', style({
            width: 384,
        })),
        state('collapse', style({
            width: 96,
        })),
        transition('collapse => expand', animate('200ms 0s ease-out')),
        transition('expand => collapse', animate('300ms 0s ease-in')),
    ]),
    fadeIn: trigger('fadeIn', [
        state('in', style({
            opacity: 1,
        })),
        transition('void => *', [
            style({
                opacity: 0,
            }),
            animate('2s 0s ease-in'),
        ]),
    ]),
    letterScroll: trigger('fadeInScrollUp', [
        state('in', style({
            opacity: 1,
            transform: 'translateY(0)',
        })),
        transition('void => *', [
            style({
                opacity: 0,
                transform: 'translateY(50%)',
            }),
            animate('3s 1s ease-in'),
        ]),
    ]),
};
