import {NgModule} from '@angular/core';
import {Ellipsis} from '@shared/pipe/ellipsis/ellipsis.pipe';
import {RemindoDurationTime} from '@shared/pipe/remindoDurationTime/remindoDurationTime.pipe';
import {TranslateForOrganizationPipe} from '@shared/pipe/translate-for-organization/translate-for-organization.pipe';

@NgModule({
    imports: [],
    declarations: [
        Ellipsis,
        RemindoDurationTime,
        TranslateForOrganizationPipe,
    ],
    exports: [
        Ellipsis,
        RemindoDurationTime,
        TranslateForOrganizationPipe,
    ],
})
export class PipeModule {
}
