import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PrefixAssignmentTitlePipe} from './pipe/title.pipe';

@NgModule({
    declarations: [
        PrefixAssignmentTitlePipe,
    ],
    imports: [
        CommonModule,
    ],
    exports: [
        PrefixAssignmentTitlePipe,
    ],
})
export class AssignmentModule { }
