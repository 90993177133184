import {Injectable} from '@angular/core';
import {PublisherInterface} from '@shared/interfaces/publisher.interface';

@Injectable({
    providedIn: 'root',
})
export class FaviconService {
    public load(publisher: PublisherInterface): void {
        const favicons = document.head.querySelectorAll('link[rel="icon"]');

        if (0 === favicons.length) {
            return;
        }

        favicons.forEach(favicon => {
            const href: string | null = favicon.getAttribute('href');

            if(null === href) {
                throw new Error('Unable to set publisher favicon, attribute "href" is missing');
            }

            favicon.setAttribute('href', href.replace('/default/', `/${publisher.uuid.toLowerCase()}/`));
        });
    }
}
