export enum RoleEnum {
    RoleAll = 'ROLE_ALL',

    // Feature flag roles, these roles are frontend only
    RoleDevelopment = 'ROLE_DEVELOPMENT',
    RoleTest = 'ROLE_TEST',
    RoleAcceptance = 'ROLE_ACCEPTANCE',
    RoleProduction = 'ROLE_PRODUCTION',

    // User roles
    RoleUser = 'ROLE_USER',
    RoleStudent = 'ROLE_STUDENT',
    RoleTeacher = 'ROLE_TEACHER',

    // Component roles
    RoleBuyLicence = 'ROLE_BUY_LICENCE',
    RoleExams = 'ROLE_EXAMS',

    // Publisher roles
    RoleOrganization = 'ROLE_ORGANIZATION',
    RoleOrganizationVo = 'ROLE_ORGANIZATION_VO',
    RoleOrganizationBo = 'ROLE_ORGANIZATION_BBO',
    RoleOrganizationHo = 'ROLE_ORGANIZATION_HO',
}
