import {NgModule} from '@angular/core';
import {ContainerHeaderComponent} from '@component/container-header/container-header.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {CommonModule} from '@angular/common';
import {LanguageDropdownModule} from '@component/language/dropdown/language-dropdown.module';

@NgModule({
    imports: [
        LanguageDropdownModule,
        FlexLayoutModule,
        CommonModule,
    ],
    declarations: [
        ContainerHeaderComponent,
    ],
    exports: [
        ContainerHeaderComponent,
    ],
})
export class ContainerHeaderModule {
}
