import {Pipe, PipeTransform} from '@angular/core';
import {PaginationComponent, PaginationPageButton} from '@component/pagination/pagination.component';

@Pipe({
    name: 'paginationMappedPageLabel',
})
export class PaginationMappedPageLabelPipe implements PipeTransform {
    readonly #paginationComponent: PaginationComponent;

    constructor(paginationComponent: PaginationComponent) {
        this.#paginationComponent = paginationComponent;
    }

    transform(pageIndex: string): PaginationPageButton | undefined {
        const number: number = Number(pageIndex);

        return !isNaN(number) ? this.#paginationComponent.findMappingByIndex(Number(pageIndex) - 1) : undefined;
    }
}
