import {Store} from '@ngrx/store';
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {SelfExamineActionsEnum} from '@store/self-examine/self-examine.enum';
import {SelfExamineCreatePropertiesInterface} from '@store/self-examine/self-examine.properties';
import {SelfExamineService} from '@core/services/self-examine/self-examine.service';
import {SelfExamineActions} from '@store/self-examine/self-examine.actions';

@Injectable()
export class SelfExamineEffects {
    public constructor(
        private store: Store,
        private actions$: Actions,
        private selfExamineService: SelfExamineService,
    ) {
    }

    public create$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(SelfExamineActionsEnum.Create),
            switchMap((props: SelfExamineCreatePropertiesInterface) => {
                return this.selfExamineService.create(props)
                    .pipe(
                        map(selfExamine => SelfExamineActions.createSuccess(selfExamine)),
                        catchError(error => of(SelfExamineActions.creatFailed({error, data: props}))),
                    );
            }),
        );
    });
}
