import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '@core/services/api/api.service';
import {ApiV2EndpointsEnum} from '@core/enums/api-v2-endpoints.enum';
import {map} from 'rxjs/operators';
import {LicenseInterface} from '@store/license';
import {HasTeacherLicenseInterface} from '@core/security/contract/user.contract';

export interface LicenseResultInterface extends HasTeacherLicenseInterface {
    licenses: LicenseInterface[];
}

@Injectable({
    providedIn: 'root',
})
export class LicenseService {
    constructor(private api: ApiService) {
    }

    activate(licenses: string[]): Observable<LicenseInterface[]> {
        return this.api.post<LicenseInterface[]>(ApiV2EndpointsEnum.LicenseActivate, {licenses}).pipe(map(licenses => licenses));
    }

    process(licenses: LicenseInterface[]): Observable<LicenseResultInterface> {
        return new Observable<LicenseResultInterface>(subscriber => {
            subscriber.next({hasTeacherLicense: this.#hasTeacherLicense(licenses), licenses});
        });
    }

    #hasTeacherLicense(licenses: LicenseInterface[]): boolean {
        return licenses.some(license => license.isTeacherEan);
    }
}
