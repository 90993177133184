import {SearchResultsInterface} from '@core/search/component/search/search.component';
import * as _ from 'lodash';

export class SearchResultsMap extends Map<string, SearchResultsInterface> {
    public remove(datasourceId: string): SearchResultsMap {
        if (!super.delete(datasourceId)) {
            throw new Error(`Failed to delete results for datasource "${datasourceId}" from state`);
        }

        return _.clone(this);
    }

    public insertOrUpdate(datasourceId: string, searchResults: SearchResultsInterface): this {
        super.set(datasourceId, searchResults);

        return _.clone(this);
    }
}
