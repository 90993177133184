import {Component, OnDestroy} from '@angular/core';
import {ButtonTypeEnum} from '@shared/button/button.directive';
import {PublisherInterface} from '@shared/interfaces/publisher.interface';
import {Store} from '@ngrx/store';
import {GeneralSelectors} from '@store/general';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-faq-button',
    templateUrl: './faq-button.component.html',
    styleUrls: ['./faq-button.component.scss'],
})
export class FaqButtonComponent implements OnDestroy {
    private readonly subscriptions: Subscription[] = [];

    buttonType = ButtonTypeEnum;

    publisher?: PublisherInterface;

    filledFaq = false;

    constructor(private store: Store) {
        this.subscriptions.push(
            store.select(GeneralSelectors.selectPublisherAndBook).subscribe(selection => this.handlePublisher(selection.publisher)),
        );
    }

    faqRedirect(): void {
        if(this.publisher !== undefined && this.publisher.links !==null && this.publisher.links.faq !== null) {
            window.open(this.publisher.links.faq, '_blank');
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    private handlePublisher(publisher?: PublisherInterface): void {
        this.filledFaq = publisher !== undefined && publisher.links !== null && publisher.links.faq !== null && publisher.links.faq !== '' ? false : true;
        this.publisher = publisher;
    }
}
