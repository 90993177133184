import {Injectable} from '@angular/core';
import {Observable, of, OperatorFunction} from 'rxjs';
import {ApiService} from '@core/services/api/api.service';
import {ApiV2EndpointsEnum} from '@core/enums/api-v2-endpoints.enum';
import {UserAuthenticatedInterface} from '@shared/interfaces/user.interface';
import {sprintf} from '@core/core.functions';
import {AuthorizationService} from '@core/security/services/authorization.service';
import {RoleEnum} from '@core/security/enum/role.enum';
import {Store} from '@ngrx/store';
import {SecuritySelectors} from '@store/security';
import {filter, map, mergeMap} from 'rxjs/operators';
import {HasOrganizationInterface} from '@core/security/contract/user.contract';

export interface UpgradeToTeacherResultInterface {
    patched: boolean;
    user: UserAuthenticatedInterface;
}

@Injectable({
    providedIn: 'root',
})
export class UserService {
    #store: Store;

    #apiService: ApiService;

    #authorizationService: AuthorizationService;

    constructor(store: Store, apiService: ApiService, authorizationService: AuthorizationService) {
        this.#store = store;
        this.#apiService = apiService;
        this.#authorizationService = authorizationService;
    }

    upgradeToTeacher(result: HasOrganizationInterface): Observable<UpgradeToTeacherResultInterface> {
        const isTeacher: boolean = this.#authorizationService.isGranted(RoleEnum.RoleTeacher);

        return this.#store.select(SecuritySelectors.selectCurrentUser).pipe(
            filter(currentUser => undefined !== currentUser) as OperatorFunction<UserAuthenticatedInterface | undefined, UserAuthenticatedInterface>,
            mergeMap(currentUser => result.hasOrganization && !isTeacher ? this.#apiService.patch<UserAuthenticatedInterface>(ApiV2EndpointsEnum.SecurityUpgradeToTeacher) : of(currentUser)),
            map(user => ({user, patched: !isTeacher && user.roles.includes(RoleEnum.RoleTeacher)})),
        );
    }

    getCurrentUser(): Observable<UserAuthenticatedInterface> {
        return this.#apiService.get<UserAuthenticatedInterface>(ApiV2EndpointsEnum.SecurityCurrentUser);
    }

    updateTerms(): Observable<UserAuthenticatedInterface> {
        return this.#apiService.get<UserAuthenticatedInterface>(ApiV2EndpointsEnum.SecurityCurrentUserAcceptTerms);
    }

    updateOrganization(organizationId: string): Observable<UserAuthenticatedInterface> {
        return this.#apiService.put<UserAuthenticatedInterface>(sprintf(ApiV2EndpointsEnum.OrganizationAddCurrentUser, organizationId));
    }
}
