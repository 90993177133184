export enum ExtensionEnum {
    Avi = 'avi',

    Ds = 'ds',

    Aac ='aac',

    Ai = 'ai',

    Bmp = 'bmp',

    Cad = 'cad',

    Cdr = 'cdr',

    Css = 'css',

    Dat = 'dat',

    Dll = 'dll',

    Dmg = 'dmg',

    Doc = 'doc',

    Docx = 'docx',

    Eps = 'eps',

    Fla = 'fla',

    File = 'file',

    Flv = 'flv',

    Gif = 'gif',

    Html = 'html',

    Indd = 'indd',

    Iso = 'iso',

    Jpg = 'jpg',

    Jpeg = 'jpeg',

    Js = 'js',

    Midi = 'midi',

    Mov = 'mov',

    Mps = 'mps',

    Mpg = 'mpg',

    Pdf = 'pdf',

    Php = 'php',

    Png = 'png',

    Ppt = 'ppt',

    Pptx = 'pptx',

    Ps = 'ps',

    Psd = 'psd',

    Raw = 'raw',

    Sql = 'sql',

    Svg = 'svg',

    Tif = 'tif',

    Txt = 'txt',

    Wmv = 'wmv',

    Xls = 'xls',

    Xlsx = 'xlsx',

    Xml = 'xml',

    Zip = 'zip',

    Link = 'link-anders',
}
