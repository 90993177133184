import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {GeneralActions} from '@store/general/general.actions';
import {GeneralService} from '@core/services/general/general.service';
import {updateLoadedCurrentUserAction} from '@store/security';
import {of} from 'rxjs';

@Injectable()
export class GeneralEffects {
    public constructor(
        private actions: Actions,
        private generalService: GeneralService,
    ) {
    }

    public fetchPublisher$ = createEffect(() => {
        return this.actions.pipe(
            ofType(GeneralActions.fetchPublisher),
            switchMap(() => this.generalService.retrievePublisher().pipe(
                map(publisher => GeneralActions.fetchPublisherSuccess(publisher)),
                catchError(error => of(GeneralActions.fetchPublisherFailed(error))),
            )),
        );
    });

    public $patchUserSettings = createEffect(() => {
        return this.actions.pipe(
            ofType(GeneralActions.patchUserSettings),
            switchMap(props => this.generalService.patchUserSetting(props.setting).pipe(
                map(currentUser => updateLoadedCurrentUserAction({currentUser})),
                catchError(error => of(GeneralActions.patchUserSettingsFailed(error))),
            )),
        );
    });
}
