import {Component, ElementRef, HostBinding, Input, Pipe, PipeTransform} from '@angular/core';
import {ButtonTypeEnum} from '@shared/button/button.directive';
import {MemoizedSelector, Store} from '@ngrx/store';
import {SelectionActions, SelectionSelectors} from '@store/selection';
import {DocumentInterface} from '@shared/interfaces/document.interface';
import {AbstractSelect} from '@shared/abstract/select/abstract.select';
import {ChapterBookDetailsInterface} from '@shared/interfaces/chapter.interface';
import {Subscription} from 'rxjs';

export enum ModuleSingleSelectPlaceHolder {
    All = 'moduleMenu.all',
    SelectOne = 'moduleMenu.selectOne',
}

@Component({
    selector: 'app-document-select',
    templateUrl: './document-select.component.html',
    styleUrls: ['./document-select.component.scss'],
})
export class DocumentSelectComponent extends AbstractSelect<DocumentInterface> {
    public readonly buttonType = ButtonTypeEnum;

    @HostBinding('class')
    public readonly classes: string[] = ['module-menu'];

    @Input()
    public disabled?: boolean = false;

    @Input()
    public ellipsisMax?: number;

    @Input()
    public placeHolder: ModuleSingleSelectPlaceHolder = ModuleSingleSelectPlaceHolder.All;

    public elementWidth!: string;

    public constructor(private elementRef: ElementRef, store: Store) {
        super(store);
    }

    public handleClick(document?: DocumentInterface): void {
        this.dispatch(SelectionActions.setDocument({item: document}));
    }

    public updateMenuWidth(): void {
        this.elementWidth = `${this.elementRef.nativeElement.offsetWidth / 10}rem`;
    }

    protected getItemSelector(): MemoizedSelector<object, DocumentInterface | undefined> {
        return SelectionSelectors.selectDocument;
    }

    protected getItemsSelector(): MemoizedSelector<object, DocumentInterface[] | undefined> {
        return SelectionSelectors.selectChapterDocuments;
    }

    protected filterItems(items: DocumentInterface[]): DocumentInterface[] {
        return items.filter(item => {
            return !(item.type === 'theory' && (item.parentType === 'practice-exam' || item.parentType === 'exam'));
        });
    }
}
