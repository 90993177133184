import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class StringService {
    public getMappedString(mappableString: string, propertiesMap?: Map<string, string>): string {
        if (propertiesMap instanceof Map) {
            propertiesMap.forEach((value: string, key: string) => {
                mappableString = mappableString.replace(`:${key}`, value);
            });
        }

        return mappableString;
    }
}
