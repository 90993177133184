import {inject, Injectable} from '@angular/core';
import {NavigationEnd, NavigationExtras, Router, RouterEvent} from '@angular/router';
import {BehaviorSubject, OperatorFunction, Subscription} from 'rxjs';
import {filter, take} from 'rxjs/operators';

export interface RoutingEvent {
    previous?: string[];
    current: string[];
}

@Injectable({
    providedIn: 'root',
})
export class RoutingService {
    readonly #router: Router = inject(Router);

    #currentUrl?: string;

    #routingSubject: BehaviorSubject<RoutingEvent | undefined> = new BehaviorSubject<RoutingEvent | undefined>(undefined);

    constructor() {
        this.#router.events.subscribe(event => this.#handleRouteEvent(event as RouterEvent));
    }

    subscribeToRoutingEvent(fn: (event: RoutingEvent) => void, once: boolean): Subscription {
        const observable: BehaviorSubject<RoutingEvent | undefined> = this.#routingSubject;
        const filterUndefined: OperatorFunction<RoutingEvent | undefined, RoutingEvent> = filter((event?: RoutingEvent): boolean => undefined !== event) as OperatorFunction<RoutingEvent | undefined, RoutingEvent>;

        if (once) {
            return observable.pipe(filterUndefined, take(1)).subscribe((event: RoutingEvent) => fn(event));
        }

        return observable.pipe(filterUndefined).subscribe((event: RoutingEvent) => fn(event));
    }

    navigate(commands: string[], extras?: NavigationExtras): void {
        this.#router.navigate(commands, extras);
    }

    #handleRouteEvent(event: RouterEvent): void {
        if (event instanceof NavigationEnd) {
            this.#handleNavigationEnd(event);
        }
    }

    #handleNavigationEnd(event: NavigationEnd): void {
        const currentUrl: string | undefined = this.#currentUrl;
        const newEvent: RoutingEvent = {current: this.#getUrlAsRouteParts(this.#currentUrl = event.url)};

        if (undefined !== currentUrl) {
            newEvent.previous = this.#getUrlAsRouteParts(currentUrl);
        }

        this.#routingSubject.next(newEvent);
    }

    #getUrlAsRouteParts(url: string): string[] {
        return url.split('/').filter((urlPart: string): boolean => '' !== urlPart);
    }
}
