import {Injectable} from '@angular/core';

@Injectable()
export class HTMLElementHelper {
    public getClosestAttributeValue(element: HTMLElement, attribute: string): string | undefined {
        const closestHtmlElement: HTMLElement | null = element.closest(`[${attribute}]`);

        if (null === closestHtmlElement) {
            throw new Error(`Unable to find closest element by "[${attribute}]" attribute selector`);
        }

        return closestHtmlElement.getAttribute(attribute) ?? undefined;
    }
}
