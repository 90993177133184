import {createFeatureSelector, createSelector} from '@ngrx/store';
import {StoreFeatureSelectorEnum} from '@store/store.enum';
import {AppExamState} from '@component/exam/redux/exam.reducer';

export class AppExamSelectors {
    static readonly getState = createFeatureSelector<AppExamState>(
        StoreFeatureSelectorEnum.ContentExam,
    );

    static readonly selectMetadata = createSelector(
        AppExamSelectors.getState,
        (state: AppExamState) => state.metadata,
    );

    static readonly selectStep = createSelector(
        AppExamSelectors.getState,
        (state: AppExamState) => state.step,
    );

    static readonly selectList = createSelector(
        AppExamSelectors.getState,
        (state: AppExamState) => state.list,
    );

    static readonly selectBookExams = createSelector(
        AppExamSelectors.getState,
        (state: AppExamState) => state.exams,
    );
}
