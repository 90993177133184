import {NgModule} from '@angular/core';
import {TemplateVarDirective} from '@component/template-var/template-var.directive';

@NgModule({
    imports: [],
    declarations: [
        TemplateVarDirective,
    ],
    exports: [
        TemplateVarDirective,
    ],
})
export class TemplateVarModule {
}
