import {NgModule} from '@angular/core';
import {DevEnvGuard} from '@/router/guard/dev-env.guard';
import {RouterModule, Routes} from '@angular/router';
import {NotFoundComponent} from '@core/not-found/not-found.component';
import {MessagesComponent} from '@/component/messages/messages.component';

export enum AppRouteEnum {
    Root = '',
    Books = 'books',
    Chapters = 'books/:bookUuid/chapters',
    Progress = 'progress',
    Messages = 'messages',
    Styleguide = 'styleguide',
    Document = 'document',
    Methods = 'methods',
    Classes = 'classes',
    Sources = 'sources',
    Settings = 'settings',
    Profile = 'profile',
    TeacherMaterial = 'teacher-material',
    Exam = 'exam'
}

const routes: Routes = [
    {
        path: AppRouteEnum.Root,
        redirectTo: AppRouteEnum.Books,
        pathMatch: 'full',
    },
    {
        path: AppRouteEnum.Root,
        children: [
            {
                path: AppRouteEnum.Messages,
                component: MessagesComponent,
            },
            {
                path: AppRouteEnum.Books,
                loadChildren: () => import('../features/books/books.module').then((m) => m.BooksModule),
            },
            {
                path: AppRouteEnum.Chapters,
                loadChildren: () => import('../features/chapters/chapters.module').then(m => m.ChaptersModule),
            },
            {
                path: AppRouteEnum.Progress,
                loadChildren: () => import('../features/progress/progress.module').then((m) => m.FeatureProgressModule),
            },
            {
                path: AppRouteEnum.Methods,
                loadChildren: () => import('../features/methods/methods.module').then((m) => m.MethodsModule),
            },
            {
                path: AppRouteEnum.Classes,
                loadChildren: () => import('../features/groups/groups.module').then((m) => m.GroupsModule),
            },
            {
                path: AppRouteEnum.Profile,
                loadChildren: () => import('../features/profile/profile.module').then((m) => m.ProfileModule),
            },
            {
                path: AppRouteEnum.Sources,
                loadChildren: () => import('../features/sources/sources.module').then((m) => m.SourcesModule),
            },
            {
                path: AppRouteEnum.TeacherMaterial,
                loadChildren: () => import('../features/teacher-material/teacher-material.module').then((m) => m.TeacherMaterialModule),
                // TODO BD-956 configuration Routeguard on correct userRoles
                // canActivate: [RouteGuard],
                // data: {roles: [RoleEnum.RoleOrganizationVo, RoleEnum.RoleTeacher]},
            },
            {
                path: AppRouteEnum.Exam,
                loadChildren: () => import('../component/exam/exam.module').then((m) => m.AppExamModule),
            },
        ],
    },
    {
        path: AppRouteEnum.Styleguide,
        loadChildren: () => import('../features/styleguide/styleguide.module').then(module => module.StyleguideModule),
        canLoad: [DevEnvGuard],
    },
    {
        path: '404',
        component: NotFoundComponent,
    },
    {
        path: '**',
        redirectTo: '/404',
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'}),
    ],
    providers: [
        DevEnvGuard,
    ],
})
export class AppRouterModule {
}
